@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(80px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(-80px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes titleflip {
	0%, 20% {
	transform: translate(0px, -12.50em);
	}
	20%,
	40% {
   transform: translate(0px, -9.375em);
	}
	40%,
	60% {
	  -webkit-transform: translate(0px, -6.44em);
	}
	60%,
	80% {
	transform: translate(0px, -3.44em);
	}
	80%,
	100% {
	transform: translate(0px, -0.315em);
	}
	100%,
	0% {
	  transform: translate(0px, -12.50em);
	}
  }

@import "../abstracts/variables";

.form-group {
  padding: 0.5em;
  &._text {
    input {
      padding: 0.5em;
      width: 100%;
    }
    position: relative;
    align-items: center;
  }
  &._select {
    select {
      padding: 0.5em;
      width: 100%;
    }
  }
  &._dropdown {
    .dropdown {
      .dropdown-toggle {
        background-color: transparent;
        color: black;
        box-shadow: none;
        border: 0.1em solid rgba(0, 0, 0, 0.675);
        padding: 0.5em;
        font-size: 1.6rem;
        width: 100%;
      }
      .dropdown-toggle::after {
        position: absolute;
        top: 1.2em;
        right: 1em;
      }
      .dropdown-menu {
        font-size: 1.6rem;
        width: 100%;
        text-align: center;
        li {
          padding: 0.5em;
        }
        li:hover {
          background-color: rgba(239, 239, 239, 0.998);
          padding: 0.5em;
        }
      }
    }
  }
  &._calendar {
    .p-calendar {
      width: 100%;
      input {
        font-size: 1.6rem !important;
      }
      button {
        width: 5em;
        .pi {
          font-size: 1.6rem !important;
        }
      }
    }
  }
}

.button-group {
  // font-size: 1.6rem !important;
  // padding: 0.5em !important;
  width: 100%;
  &.--sweetAlertHelper {
    border-radius: 0.5em;
  }
  &.--contact-agent{
    font-family:  "inter-regular" !important;
    text-transform: unset;
    letter-spacing: -0.0em;
    font-size: 1.6rem;
    //font-weight: 600;
    background: #FFD439 !important;
    color: #000000 !important;
    border-radius: 0.5em !important;
    box-shadow: none !important;
    svg{
      margin-right: 0.2em;
    }
    @media (min-width: 991px) {
      width: 12em;
      padding: 0.5em 0.5em;
      margin-bottom: 1.5em;
    }
  }
  &.--contact-agent-round{
    font-family:  "inter-regular" !important;
    text-transform: unset;
    letter-spacing: -0.0em;
    font-size: 1.6rem;
    //font-weight: 600;
    background: transparent !important;
    color: #000000 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    svg{
      margin-right: 0.2em;
    }
    .pi{
      color: #D1D5DB;
      font-size: 2rem !important;
    }
    @media (min-width: 991px) {
      width: 12em;
      padding: 0.5em 0.5em;
      margin-bottom: 1.5em;
    }
  }
  &.--login-button{
    cursor: pointer;
    font-family:  "inter-regular" !important;
    text-transform: unset;
    letter-spacing: -0.0em;
    font-size: 1.6rem;
    //font-weight: 600;
    background: #FFD439 !important;
    //color: #10141A !important;
    color: #000000 !important;
    border-radius: 0.5em !important;
    box-shadow: none !important;
    @media (min-width: 991px) {
      width: 12em;
      padding: 0.5em 0.5em;
      margin-bottom: 1.5em;
    }
  }
  &.--login-button:disabled{
    font-family:  "inter-regular" !important;
    text-transform: unset;
    letter-spacing: -0.0em;
    font-size: 1.6rem;
    //font-weight: 600;
    background: #CBCFD6 !important;
    color: #000000 !important;
    border-radius: 0.5em !important;
    box-shadow: none !important;
    @media (min-width: 991px) {
      width: 12em;
      padding: 0.5em 0.5em;
      margin-bottom: 1.5em;
    }
  }
  &.--send-code{
    white-space: nowrap;
    padding: 0.8em;
    margin-bottom: 1.5em;
    font-family:  "inter-regular" !important;
    text-transform: unset;
    letter-spacing: -0.0em;
    font-size: 1.6rem;
    //font-weight: 600;
    background: transparent !important;
    color: rgb(209, 213, 219) !important;
    border-radius: 0.5em !important;
    border: 0.15em solid rgb(209, 213, 219);
    box-shadow: none !important;
    @media (min-width: 991px) {
      width: 12em;
      padding: 0.5em 0.5em;
      margin-bottom: 1.5em;
    }
  }
  &.--send-code:hover, &.--send-code:focus{
    border: 0.15em solid rgb(209, 213, 219);
  }
  &.--whatsapp{
    font-family:  "inter-regular" !important;
    text-transform: unset;
    font-size: 1.6rem;
    border: 0.1em solid #FFD439 !important;
    background: transparent !important;
    color: #FFD439 !important;
    border-radius: 0.5em !important;
    box-shadow: none !important;
    @media (min-width: 991px) {
      width: 12em;
      padding: 0.45em 0.5em;
      margin-bottom: 1.5em;
    }
  }
  &.--view-all{
    width: auto;
    text-transform: capitalize;
    font-size: 1.2rem;
    //font-weight: bold;
    font-family: 'inter-bold';
    color: #D6A055 !important;
    border: 1px solid #D6A055 !important;
    border-radius: 18px;
    background-color: transparent !important;
    box-shadow: none !important;
    @media (min-width: 991px) {
      font-size: 1.6rem;
    }
    &.light{
      color: #AC4343 !important;
    border: 1px solid #AC4343 !important;
    }
  }
}

.p-component {
  .p-link {
    font-size: 1.6rem !important;
  }
  .p-datepicker-calendar-container {
    .p-datepicker-calendar {
      thead {
        tr {
          th {
            text-align: center;
          }
        }
      }
      font-size: small;
    }
  }
}

.radio-button {
  .form-check-input:disabled {
    background-color: #efe0b4;
    opacity: 1;
  }
  .form-check-input[type="radio"]:disabled:after {
    border-radius: 50%;
    width: 0.625rem;
    height: 0.625rem;
    border-color: #000000;
    background-color: #b4b2af;
    transition: border-color;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .form-check-input[type="radio"]:before {
    background: #9e9e9e;
  }
  .form-check-input:checked {
    background-color: #f0a000 !important;
    border-color: #000000;
    box-shadow: 0px 0px 5px rgba(255, 220, 0, 0.8);
  }
  .form-check-input[type="radio"]:checked:after {
    border-radius: 50%;
    width: 0.625rem;
    height: 0.625rem;
    border-color: #000000;
    background-color: #ffdc00;
    transition: border-color;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

@import "abstracts/variables";

@import "base/animations";
@import "base/font";
@import "base/base";

@import "component/element";
@import "component/notification";
//faf9f9

  .light-mode{
    background: #FFFFFF;
    .infinite-scroll-component {
      overflow: hidden !important;
    }
  
    .video-react {
      .video-react-big-play-button {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
      }
      .video-react-video {
        max-height: 38em;
        border-radius: 1em;
        display: flex;
        justify-content: center;
      }
      border-radius: 1em;
      overflow: hidden;
      background-color: #242424fc !important;
      position: unset;
      @media (max-width: 991px) {
        padding-top: 20em;
      }
    }
  
    .video-react-icon-fullscreen {
      display:none !important;
    }
  
    .common-main-container {
      //background: linear-gradient(180deg, #090909 0%, #1e1f21 30em);
      //background: #000000 !important;
      background: #FFFFFF;
      min-height: 100vh;
      position: relative;
      font-size: 1.6rem;
      padding: 0 0 5em 0;
  
      &.--grey{
        background-color: #FFFFFF; //light_mode_1
      }
    }
  
    .header-main-container {
      z-index: 8;
      font-size: 1.6rem;
      //height: 7em;
      .section-top {
        height: 5.55em;
        padding: 2em;
        padding-bottom: 1em;
        border-bottom: 0.1em solid #eb323b;
        // background-color: #14161E; //dark_mode
        background-color: #FFFFFF; //light_mode_2
  
        z-index: 8;
        position: relative;
        img {
          background-color: transparent;
        }
  
        .header-text {
          cursor: pointer;
          font-family: "homoarakhn";
          color: black;
        }
  
        @media (min-width: 991px) {
          height: 7em;
          .header-text {
            font-size: 2rem;
            // font-weight: bold;
          }
        }
      }
  
      .section-bottom {
        z-index: 1;
        position: relative;
        padding: 1.5em 0;
        ._label{
          &.--header_page{
            margin-top: 3px;
            font-size: 0.7rem;
          font-family: "homoarakhn";
          }
        }
      }
    }
  
    .header-secondary-container {
      padding-top: 1.5em !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #323247;
      background-color: transparent;
  
      .back-btn {
        cursor: pointer;
        font-family: "homoarakhn";
        font-size: 16px;
        //font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
  
      ._label {
        &.--modal-title {
          color: #323247;
          font-style: italic;
          font-size: 18px;
          font-family: "inter-semi-bold";
          // font-family: "homoarakhn";
          //font-weight: bold;
        }
  
        &.--number {
          color: #323247;
          font-style: italic;
          font-size: 18px;
          //font-weight: bold;
          font-family: "inter-bold";
          span {
            color: #EC3237;
            text-transform: uppercase;
          }
        }
      }
  
      @media (min-width: 991px) {
        padding-top: 9.5em !important;
      }
    }
  
    .main-title-row {
      padding-top: 3em;
      padding: 1em 0em;
  
      ._title-special-text {
        // color: #FFD439;
        color: #eb3238;
        font-family: "homoarakhn";
        font-size: 1.6rem;
        // font-weight: bold;
        z-index: 1;
  
        ._title {
          font-style: italic;
          font-family: "inter-bold";
          font-size: 1.2rem;
          color: #24262D;
          //font-style: italic;
          letter-spacing: 0.5rem;
        }
  
        ._titlebig {
          font-style: italic;
          font-family: "inter-bold";
          font-size: 1.2rem;
          color: #D1D5DB;
          //font-style: italic;
          letter-spacing: 0.5rem;
        }
  
        ._titlesec {
          font-style: italic;
          font-family: "inter-bold";
          font-size: 1.2rem;
          color: #24262D;
          letter-spacing: 0.5rem;
        }
  
        @media (min-width: 991px) {
          font-size: 2rem;
  
          ._title {
            color: #14161E;
            // color: #b9a2a2; //light_mode
            font-style: italic;
            font-family: "inter-bold";
            font-size: 2rem;
            //font-style: italic;
            letter-spacing: 0.5rem;
          }
  
          ._titlebig {
            font-style: italic;
            font-family: "inter-bold";
            font-size: 2rem;
            color: #ffffff;
            //font-style: italic;
            letter-spacing: 0.5rem;
          }
  
          ._titlesec {
            font-style: italic;
            font-family: "inter-bold";
            font-size: 2rem;
            color: #24262D;
            letter-spacing: 0.5rem;
            @media (min-width: 1740px) {
              color: #ffffff;
            }
          }
        }
      }
    }
  
    .calculator-loan-form{
      .form-outline{
        input{
          font-size: 16px;
          padding-top: 1.5em;
          padding-left: 0;
        }
        .form-outline{
          width: 100% !important;
          background-color: transparent;
          outline: none !important;
          box-shadow: none !important;
          input{
            font-size: 16px;
            padding-top: 1.5em;
            padding-left: 7px !important;
          }
        }
      }
      .agent-video-card{
        margin-bottom: 0.5em;
        .cover-div{
          cursor: pointer;
          border-radius: 1em;
          position: relative;
          .cover-image {
            border-radius: 1em;
          }
          .video-cover{
            &.mobile{
              video {
                object-fit: cover;
                max-height: 236px;
                border-radius: 1em;
              }
            }
            video {
              object-fit: cover;
              max-height: 284px;
              border-radius: 1em;
            }
          }
        }
        .cover-div:hover{
          .hover-div{
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 1em;
            background: rgba(30, 30, 30, 0.4);
            z-index: 9999;
          }
        }
        ._label{
          &.--text{
            font-size: 14px;
            color: #484848;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
          }
          &.--name{
            font-size: 12px;
            color: #7d7d7d;
          }
        }
        .agent-avatar{
          border-radius: 50%;
          img {
            border-radius: 50%;
          }
        }
      }
      .video_info-div{
        top: 0;
        left: 0;
      }
      ._label{
        &.--video-title{
          margin-bottom: 1em;
          font-size: 2rem;
          font-family: inter-medium;
        }
      }
    }
  
    @media (min-width: 991px) {
      .main-title-row {
        padding-top: 3em;
      }
    }
  
    .line-container {
      height: 0em;
      width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;
  
      &.--brand-info {
        height: 0.01em;
        margin-top: 0.2em;
        margin-bottom: 0.5em;
        background-color: #9091A0;
        position: absolute;
        top: 0.5em;
        width: 80%;
        @media (min-width: 991px) {
          width: 50%;
        }
      }
  
      &.--options-box {
        height: 0.01em;
        margin-top: 0.2em;
        margin-bottom: 0.5em;
        background-color: #eb3e3b;
      }
  
      &.--banner-line {
        height: 0.01em;
        margin-top: 0.5em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        left: 0;
        top: 2.5em;
        background-color: #323247;
        @media (min-width: 991px) {
          height: 0.1em;
          // margin-bottom: 2em;
          background-color: rgba(214, 160, 85, 0.3);
        }
      }
  
      &.--gold {
        margin-top: 2em;
        background-color: #ffd439;
      }
  
      &.--yellow {
        height: 3px;
        margin: 0;
        background-color: #ffd439;
        border: 0.5px solid #FBB24B;
        box-shadow: 0px 1px 3px rgba(255, 220, 0, 0.5) !important;
        border-radius: 10px;
      }
  
      &.--yellow-thin {
        position: absolute;
        padding: 0;
        height: 0.1em;
        margin: 0;
        left: 0;
        top: 2.5em;
        z-index: 2;
        background-color: rgba(214, 160, 85, 0.3);
      }
  
      &.--grey {
        border-radius: 1em;
        height: 0.35em;
        margin: 0;
        background-color: #b4b2af;
      }
  
      &.--grey-thin {
        border-radius: 1em;
        height: 0.01em;
        margin: 1em 0;
        background-color: #9091A0;
      }
  
      &.--short-line {
        background: #404857;
        width: 8em;
        height: 0.144em;
        border-radius: 1em;
        position: absolute;
        left: 50%;
        bottom: 0.3em;
        transform: translate(-50%, 0%);
        margin-top: 0.3em;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
      }
  
      &.--dot {
        background: #D9D9D9;
        width: 0.5em;
        height: 0.4em;
        border-radius: 0.375em;
        position: absolute;
        left: 50%;
        bottom: 0.35em;
        transform: translate(-50%, 0%);
        margin-top: 0.3em;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
      }
  
      &.--gold-small {
        margin-top: 1em;
        width: 98%;
        border: 1px solid rgba(255, 212, 57, 0.2);
      }
  
      &.--gold-long-compare {
        margin-top: 1em;
        width: 100%;
        border: 0.1rem solid #FFD439;
      }
  
      &.--gold-thin-compare {
        width: 100%;
        border: 0.1rem solid #FFD439;
      }
    }
  
    .calculator-header {
      margin: 5em 0 0 0;
      border-bottom: 1px solid rgba(255, 212, 57, 0.3);
      padding: 3em 1em 1em 1em;
      width: 70%;
      margin-bottom: 1em;
      color: #FBB24B;
      .content{
        //font-weight: 1000;
        font-size: x-large;
        .content-icon{
          height: 1em;
          margin-left: 2em;
          margin-right: 0.5em;
        }
        .right{
          text-align: right;
        }
      }
      @media (max-width: 991px) {
        margin-top: 0em;
        padding: 1.5em 1em 1em 1em;
        .content{
          .content-icon{
            margin-left: 0em;
          }
        }
      }
    }
  
    .calculator-icon {
      color: #D1D5DB;
      padding: 1em 0 0.5em 0;
      min-width: 15em;
      margin-left: auto !important;
      margin-right: auto !important;
      border-radius: 8px;
  
      .swiper{
        .swiper-wrapper{
          height: 8em !important;
        }
      }
      .calculator-icon-col {
        cursor: pointer;
        text-align: center;
        padding: 1em;
  
        &.--active-icon {
          background: transparent;
          // box-shadow: 0px 0px 2px 0px rgba(67, 94, 190, 0.25);
          border-radius: 8px;
          label{
            color: #ac4343 !important;
            font-family: "inter-medium";
          }
        }
  
        .calculator-icon-title {
          font-size: small;
          line-height: 1.3em;
          margin-bottom: 0.5em;
          //word-spacing: 500px;
          label {
            cursor: pointer;
            color: #616469;
            // font-family: "inter-medium";
          }
        }
      }
      @media (max-width: 991px) {
        padding: 1em 3em 0.5em 3em;
      }
      @media (max-width: 400px) {
        padding: 1em 0em 0.5em 0em;
      }
    }
  
    .loan-table-container{
      border: 1px solid white;
    }
    .table { 
      color: white;
        font-size: small;
        text-align: center;
      &.__loan{
        thead tr th{
          color: white;
          //font-weight: bolder;
          font-size: large;
          @media (max-width: 991px) {
            font-size: small;
          }
        }
  
        tbody tr td{
          color: white;
          font-size: medium;
        }
      }
    }
  
    .formik-form {
      margin: 1em auto 1em auto;
      padding: 0 10%;
      @media (min-width: 991px) {
        max-width: 55em;
        margin: 6em auto;
      }
  
      .roadtax-result{
        color: #313247;
        // font-weight: 600;
        font-family: "inter-semi-bold";
        margin: 1em 0;
        @media (min-width: 991px) {
          margin: 3em 0 1em 0;
        }
        &.year{
          max-width: 45em;
          margin: 0 !important;
          //padding-left:5em;
          text-align: center;
          font-size: large;
          @media (max-width: 991px) {
            font-size: small;
          }
        }
        &.amount{
          margin: 0 !important;
          text-align: center;
          font-size: x-large;
        }
      }
      .roadtax-remark{
        background: #FAFAFA;
        border-radius: 8px;
        padding: 0.5em;
        margin: 0.5em 0 1em 0;
  
        .roadtax-remark-content{
          font-size: small;
          font-family: "inter-regular";
  
          .white{
            // line-height: normal;
            color:#24262D;
            font-family: "inter-medium";
          }
          .font-white{
            color: #24262D;
          }
  
          .font-highlight{
            color:#AC4343; 
            font-family: "inter-medium";
            width: 8em;
  
            &.no-width{
              width: 100% !important;
            }
          }
        }
        
      }
      .custom-field {
        padding: 0.5em;
  
        select{
          outline: none !important;
          color: #9091A0 !important;
          // background: #F8F8F8;
          background: #FFFFFF;
          // border: 2px solid #D6A055;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          height: 2.8em;
          border: none;
          @media (min-width: 991px) {
            height: 3.5em;
          }
        }
  
        select:focus{
          outline: 1px solid #D6A055 !important;
        }
        select {
          -webkit-appearance: none;
          appearance: none;
        }
        select {
          background-image: url("../assets/images//icon/icon-arrow.webp");
          background-size: 15px;
          background-repeat: no-repeat;
          background-position: calc(100% - 20px) center;
        }
        .form-outline {
          font-family: "inter-regular";
          // background: #F8F8F8;
          background: #FFFFFF;
          // outline: none;
           box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
          // border: 2px solid #D6A055;
          border-radius: 5px;
          height: 2.8em;
          overflow: hidden;
          font-size: medium;
          @media (min-width: 991px) {
            height: 3.5em;
          }
          input {
            height: 100%;
            //padding: 1.5em 0 0.5em 0em;
            //font-size: small;
            border: none !important;
            background: transparent;
            width: 100%;
            outline: none !important;
          }
          label {
            color: #9091A0 !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 100%;
          }
          .form-notch{
            display: none !important;
          }
        }
      }
  
      .custom-button {
        cursor: pointer;
        button{
          width: 100%;
          text-transform: capitalize;
          color: white;
          //font-weight: 600;
          font-family: "inter-medium";
          // background: #FFD439;
          background: #ac4343;
          box-shadow: inset 0px 0px 20px rgba(175, 175, 175, 0.27);
          backdrop-filter: blur(5px);
          /* Note: backdrop-filter has minimal browser support */
          border-radius: 8px;
          height: 2.8em;
          font-size: medium;
        }
      } 
    }
  
    .home-banner {
      align-items: center;
      display: flex;
      justify-content: center;
      video {
        z-index: 0;
        position: absolute;
        // top: -95px;
        top: 2.3%;
        height: auto;
        //max-height: 30em;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
      .mp-4{
        top : -182px !important
      }
      img {
        z-index: 0;
        position: absolute;
        top: -95px;
        height: auto;
        //max-height: 30em;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
      @media (max-width: 350px) {
        img {
          top: -95px !important;
          height: auto !important;
        }
      }
      
      @media (max-width: 550px) {
        img {
          top: -112px !important;
          height: auto !important;
        }
      }
  
      @media (max-width: 991px) {
        img {
          top: -112px !important;
          height: auto !important;
        }
      }
  
      @media (min-width: 991px) {
        img {
          top: 112px !important;
          height: auto !important;
        }
      }
    }
  
    @media (min-width: 991px) {
      .content-container {
        &.home-page-container {
          // margin-top: 6.5em !important;
          margin-top: 10em !important;
          padding-bottom: 6em;
          z-index: 1;
  
          .box-container {
            z-index: 1;
            position: relative;
            // background: #0B0D13;
            // box-shadow: 0px 0px 6px rgba(67, 94, 190, 0.3);
            border-radius: 30px;
            // background: #FFFFFF;
            background: #FAFAFA;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 2px 3px 8px 0px rgba(0, 0, 0, 0.10), 7px 12px 14px 0px rgba(0, 0, 0, 0.09), 15px 27px 19px 0px rgba(0, 0, 0, 0.05), 27px 48px 22px 0px rgba(0, 0, 0, 0.01), 42px 76px 24px 0px rgba(0, 0, 0, 0.00);
            // box-shadow: 0px 0px 0px 0px rgba(67, 94, 190, 0.01), 5px 5px 15px 0px rgba(67, 94, 190, 0.01), 19px 20px 27px 0px rgba(67, 94, 190, 0.01), 43px 44px 37px 0px rgba(67, 94, 190, 0.01), 76px 79px 44px 0px rgba(67, 94, 190, 0.00), 118px 124px 48px 0px rgba(67, 94, 190, 0.00);
            border-radius: 3rem;
            padding: 1em;
          }
        }
  
        &.order-page-container {
          padding-top: 10em;
          max-width: 45em;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  
    .content-container {
      padding-bottom: 6em;
      z-index: 1;
  
      .container {
        padding: 0em 1em;
      }
    }
  
    .search-bar-container {
      padding: 0 2em;
      position: relative;
      text-align: center;
      &.--stock{
        padding: 0 0.5em;
        .search-bar-row {
          cursor: pointer;
          border-radius: 45px;
          margin: 0.5em;
          margin-left: auto;
          margin-right: auto;
          max-width: 29em;
          @media (max-width: 991px) {
            height: 2.5em;
            transition: height 0.5s ease;
          }
        }
      }
      .search-bar-row {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 1em;
        z-index: 2;
        overflow: hidden;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        color: #9091A0;
        border-radius: 45px;
        border: none;
        margin-left: auto;
        margin-right: auto;
        height: 3em;
        max-width: 30em;
        font-size: 1.6rem;
        transition: height 1s, border-radius 1s, border 1s ease;
        position: relative;
        //background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(36,38,45,1) 0%);
  
        .search-background{
          top: 0;
          left: 0;
          position: absolute;
          background: rgba(36,38,45,1);
          height: 2.5em;
          z-index: 1;
          &.--active{
            background-color: rgba(36,38,45,1);
          }
        }
        //background: linear-gradient(180deg, rgba(255,255,255,1) 100%, rgba(36,38,45,1) 0%);
        ._label{
          &.--search-item{
            cursor: pointer;
            font-size: 1.4rem;
            width: auto;
            white-space: nowrap;
            background-color: #fafafa;
            //background: #232832;
            color: #888DAA;
            padding: 0.5em 1.5em;
            border: 1px solid #C8C8C8;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            margin: 0.5em 0.5em 0.5em 0em;
            &.--active{
              font-size: 1.4rem;
              width: auto;
              background: #24262D;
              //background: #f9f6f2;
              color: #FAFAFA;
              padding: 0.5em 1.5em;
              border: 1px solid #3E3C3C;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
              border-radius: 8px;
              margin: 0.5em 0.5em 0.5em 0em;
            }
          }
          &.--search-item-title{
            font-size: 1.6rem;
            text-align: start;
            width: 100%;
            color: #0B0D13;;
          }
        }
        input{
          color: #0B0D13;;
          border: unset;
          outline: none;
          background: transparent;
          z-index: 2;
          width: 100%;
        }
        ::placeholder{
          color: #9091A0
        }
        svg{
          margin-right: 1em;
        }
        &.--active{
          transition: height 1s, border-radius 1s, border 1s ease;
          border-radius: 1em;
          border: 0.1em solid rgba(255, 212, 57, 1);
          //padding-top: 0.3em;
          //padding-left: 0.9em;
          //height: 20em;
          //border: 0.1em solid #FFD439;
          //background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(36,38,45,1) 0%) !important;
          svg{
            margin-right: 0.9em;
          }
          .line-container{
            top: 2.4em;
          }
        }
      }
    }
  
    @media (min-width: 991px) {
      .search-bar-container {
          height: 3.5em;
          &.--stock{
            .search-bar-row {
              cursor: pointer;
              align-items: start;
              padding-top: 0.4em;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              height: 2.5em;
              border-radius: 45px;
              margin-left: auto;
              margin-right: auto;
              min-width: 35.25em;
              font-size: 2rem;
            }
          }
          .search-bar-row {
            cursor: pointer;
            align-items: start;
            padding-top: 0.4em;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 2.5em;
            border-radius: 45px;
            margin-left: auto;
            margin-right: auto;
            min-width: 35.25em;
            font-size: 2rem;
            label{
              color: #9091A0;
            }
            .suggestion-container {
              margin-top: 0.8em;
              overflow: scroll;
              max-height: 17em;
            }
            .suggestion-container::-webkit-scrollbar {
              display: none !important;
            }
            .recent-search-container {
              margin-top: 0.8em;
              overflow: scroll;
              max-height: 17em;
              padding-bottom: 7em;
              .car-item-box {
  
                &.--search{
                  cursor: pointer;
                  border-bottom: 0.1em solid #ababab;
                  width: 99%;
                  padding-bottom: 1em;
                  padding-top: 0.9em;
                }
            
                ._label {
                  color: #9091A0;
                  cursor: pointer;
                  &.--title {
                    //font-weight: bold;
                    width: 100%;
                    font-size: 1.6rem;
                    color: #0b0d13;
                    text-align: left;
                  }
              
                  &.--detail {
                    width: 100%;
                    font-size: 1.4rem;
                    //margin-top: 0.5em;
                    color: #3E3C3C;
                    text-align: left;
                  }
              
                  &.--tag {
                    padding: 0.1em 0.8em;
                    margin-top: 1em;
                    margin-right: 0.5em;
                    font-size: 1.4rem;
                    border: 1.5px solid #3E3C3C;
                    border-radius: 0.8em;
                    color: #3E3C3C;
                    text-align: left;
                    //box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
              
                    @media (min-width: 991px) {
                      padding: 0.3em 0.8em;
                    }
                  }
                }
              }
            }
            .recent-search-container::-webkit-scrollbar {
              display: none !important;
            }
          }
      }
    }
  
    .home-page-container{
      position: relative;
      z-index: 2;
      margin-top: 1em;
      .what-we-do-container {
        .mySwiper {
          border-radius: 1em;
          .swiper-wrapper {
            border-radius: 1em;
            // overflow: hidden;
            .swiper-slide {
              transform: none;
              .video-container {
                img {
                  height: 100%;
                  border-radius: 1em;
                }
                .video-react{
                  @media (max-width: 991px) {
                    padding-top: 20em !important;
                    position: unset !important;
                  }
                }
                .react-player{
                  background-color: #242424fc;
                  width: 100% !important;
                  height: 13em !important;
                  @media (min-width: 991px) {
                    height: 23em !important;
                  }
                }
              }
              .image-play-container {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                justify-content: center;
                display: grid;
                align-items: center;
              }
              .play-button-round{
                background: #4e4d4d97;
                border-radius: 50%;
                // padding: 1em;
                width: 4em;
                height: 4em;
                position: absolute;
                z-index: 50;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                .play-button{
                  border: 0;
                  background: transparent;
                  box-sizing: border-box;
                  position: absolute;
                  z-index: 50;
                  left: 56%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 0;
                  height: 28px;
                  border-color: transparent transparent transparent #aaaaaa;
                  transition: 100ms all ease;
                  cursor: pointer;
                  border-style: solid;
                  border-width: 14px 0px 14px 25px;
                }
                &.play-button:hover {
                  border-color: transparent transparent transparent #404040;
                }
              }
            }
          }
        }
  
        @media (min-width: 991px) {
          .mySwiper {
            margin-top: 2em;
            width: 47em;
            height: 23.5em;
  
            .swiper-wrapper {
              .swiper-slide {
                width: 47em !important;
  
                .video-container {
                  //    height: 20em;
                  .react-player{
                    border-radius: 1em;
                    overflow: hidden;
                    margin-right: auto;
                    margin-left: auto;
                    video{
                      // border-radius: 1em;
  
                    }
  
                  }
                }
              }
            }
          }
        }
  
        .tab-row {
          padding: 1em 0;
          color: #D1D5DB;
          font-size: 1.2rem;
  
          .tab-label {
            font-family:"inter-medium";
            cursor: pointer;
            width: auto;
            font-size: 1.2rem;
            color: #24262D;
          }
        }
  
        @media (min-width: 991px) {
          .tab-row {
            position: relative;
            z-index: 1;
            margin: 0;
            width: 65em;
            color: #D1D5DB;
            // color: black; //light_mode
            margin-left: auto;
            margin-right: auto;
  
            .tab-label {
              cursor: 'pointer';
              font-size: 1.8rem;
              width: auto;
              // font-family: "homoarakhn";
            }
          }
        }
      }
  
      .direct-import-container {
        .action-row {
          font-size: 1.2rem;
          line-height: 1.4em;
          padding: 2em 0em 0em 0em;
          overflow: scroll;
          flex-wrap: nowrap;
        }
        .swiper-slide{
          .text-center{
            place-items: center;
          }
        }
        ._label {
          color: #616469; //dark_mode
          // color: black; //light_mode
          padding-top: 0.5em;
          font-size: 1.2rem;
  
          &.--bold {
            //font-weight: bold;
          }
        }
  
        @media (min-width: 991px) {
          .action-row {
            flex-wrap: wrap;
            overflow: hidden;
            img {
              margin-left: auto;
              margin-right: auto;
            }
  
          }
          ._label {
            text-align: center;
            font-size: 1.6rem;
          }
        }
        .action-row::-webkit-scrollbar {
          display: none !important;
        }
      }
  
      .last-history-container {
        overflow: hidden;
  
        .car-carousel-detail {
          cursor: pointer;
          .car-card-container {
            padding: 0em;
  
            ._car-name {
              cursor: pointer;
              color: #616469;
              font-size: 1.4rem;
              width: 100%;
            }
  
            ._car-series {
              cursor: pointer;
              width: 100%;
              height: 3em;
              color: #24262D;
              // color: black; //light_mode
              font-size: 1.4rem;
            }
  
            ._car-image{
              cursor: pointer;
              display: grid;
              align-items: center;
              justify-content: center;
              width: 100% !important;
              margin-top: 1.5em;
            }
          }
  
          @media (min-width: 991px) {
            .car-card-container {
  
              ._car-name {
                font-size: 16px !important;
              }
      
              ._car-series {
                height: 4em;
                font-size: 16px;
                padding-top: 1em;
              }
      
              ._car-image{
                display: grid;
                align-items: center;
                justify-content: center;
                // width: 15em !important;
                width: 100% !important;
                height: 10em;
                margin-top: 0em;
              }
            } 
          }
        }
  
        .car-carousel-detail::-webkit-scrollbar {
          display: none !important;
        }
      }
  
      .how-it-work-container {
        overflow: hidden;
  
        ._label {
          &.--title {
            font-family: "inter-semi-bold";
            padding-top: 0.5em;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #24262D;
            font-size: 1.4rem;
          }
  
          &.--details {
            padding-top: 0.5em;
            line-height: 1.2em;
            color: #616469;
            font-size: 10px;
          }
        }
  
        .card-carousel-detail {
          display: flex;
          width: 100%;
          overflow: auto;
          .swiper{
            .swiper-wrapper{
              padding: 0.5em;
              .swiper-slide{
                .col{
                  display: grid;
                  place-items: center;
                }
                @media (max-width: 991px) {
                  width: "auto" !important;
                  margin-right: 0.5em ;
                }
              }
            }
          }
          .card-card-container {
            max-width: 12em;
            padding: 1em;
            width: 8em;
            height: 11em;
            //background: #24262D;
            // background: #0B0D13;
            // box-shadow: 0px 0px 6px rgba(67, 94, 190, 0.3);
            // background: #0B0D13; //dark_mode
            border-radius: 15px;
            background: #F8F8F8;
            // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            // background: #FFFFFF;0
              // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            // margin: 0.5em;
          }
        }
        
        .card-carousel-detail::-webkit-scrollbar {
          display: none !important;
        }
  
        @media (min-width: 991px) {
          ._label {
            &.--title {
              padding-top: 1.5em;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #24262D; //dark_mode
              // color: black; //light_mode
              font-size: 1.5rem;
            }
  
            &.--details {
              padding-top: 1em;
              line-height: 1.2em;
              color: #616469;
              font-size: 1.2rem;
            }
          }
  
          .card-carousel-detail {
            padding: .5em;
            // gap: 1em;
            .col-2:first-child{
            }
            .col-2{
              padding: 0.5em;
              padding-left: 0;
            }
            .card-card-container {
              padding: 1.5em 0.5em;
              width: 100%;
              height: 15em;
              img{
                // height: 5em;
              }
            }
          }
        }
      }
  
      .ready-stock-container {
        .ready-stock-card-container {
          background: #FAFAFA;
          // box-shadow: 0px 0px 0px 0px rgba(67, 94, 190, 0.06), 5px 5px 15px 0px rgba(67, 94, 190, 0.06), 19px 20px 27px 0px rgba(67, 94, 190, 0.05), 43px 44px 37px 0px rgba(67, 94, 190, 0.03), 76px 79px 44px 0px rgba(67, 94, 190, 0.01), 118px 124px 48px 0px rgba(67, 94, 190, 0.00);
          border-radius: 24px;
          padding: 15px 0px 0px 0px;
          margin: 1em 0;
  
          ._sub-title {
            color: #616469; //dark_mode
            // color: black; //light_mode
  
            //font-weight: bold;
            font-family: "inter-medium";
            text-shadow: 0px 2px 5px rgba(223, 223, 223, 0.25);
          }
  
          @media (min-width: 991px) {
            ._sub-title {
              font-size: 2rem;
            }
          }
  
          .ready-stock-card-carousel {
  
            .card-container {
              font-size: 12px;
  
              .card {
                cursor: pointer;
                //width: 15em;
                border-radius: 1.2rem;
                overflow: hidden;
                height: 18em;
                background-color: #F6F6F6;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 1px 1px 4px 0px rgba(0, 0, 0, 0.10), 6px 5px 8px 0px rgba(0, 0, 0, 0.09), 13px 11px 10px 0px rgba(0, 0, 0, 0.05), 23px 20px 12px 0px rgba(0, 0, 0, 0.01), 37px 32px 14px 0px rgba(0, 0, 0, 0.00);
                .section-top {
                  position: relative;
  
                  label {
                    cursor: pointer;
                    //font-weight: bold;
                    padding: 0.5em 1em;
                    width: 100%;
                    color: #f9f6f2;
                    position: absolute;
                    bottom: 0;
                    background: linear-gradient(93.2deg,
                        rgba(0, 0, 0, 0.27) 23.4%,
                        rgba(255, 255, 255, 0.4) 115.01%);
                    backdrop-filter: blur(3px);
                  }
  
                  .card-img-top {
                    height: 100px;
                    object-fit: cover;
                  }
                }
  
                .card-body {
                  background-color: #dbd9d9;
                  padding: 0.5em 1em;
  
                  ._card-title {
                    cursor: pointer;
                    padding: 0.2em 0;
                    color: #24262d;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  ._card-detail{
                    cursor: pointer;
                    @media (min-width: 991px) {
                      font-size: 1.4rem;
                    }
                  }
                  ._card-price {
                    cursor: pointer;
                    font-size: 0.9rem;
                    white-space: nowrap;
  
                    label { //b
                      font-size: 1.2rem;
                    }
                  }
                }
              }
            }
  
            @media (min-width: 991px) {
              .card-container:first-child {
                //padding-left: 0;
              }
  
              .card-container {
                //width: 30em !important;
                //padding-left: 0;
  
                .card {
                  border-radius: 1.4rem;
                  overflow: hidden;
                  background: #FAFAFA;
                  height: 32em;
  
                  .section-top {
                    position: relative;
  
                    label {
                      font-size: 1.8rem;
                      padding: 0.5em 1em;
                      width: 100%;
                      color: #f9f6f2;
                      position: absolute;
                      bottom: 0;
                      background: linear-gradient(93.2deg,
                          rgba(0, 0, 0, 0.27) 23.4%,
                          rgba(255, 255, 255, 0.4) 115.01%);
                      backdrop-filter: blur(3px);
                    }
  
                    .card-img-top {
                      height: 220px;
                      object-fit: cover;
                    }
                  }
  
                  .card-body {
                    background-color: #dbd9d9;
                    padding: 0.5em 1em;
  
                    ._card-title {
                      color: #10141a;
                      font-size: 1.8rem;
                      line-height: 1.2em;
                      padding: 0.2em 0;
                      color: #24262d;
                      height: 2.8em;
                    }
  
                    ._card-price {
                      font-size: 1.4em;
                      //   font-weight: bold;
                      color: #10141a;
                      white-space: nowrap;
  
                      span {
                        font-size: 2rem;
                        margin-left: 0.2em;
                      }
  
                      label{ //
                        margin-left: 0.2em;
                        font-size: 2rem;
                        letter-spacing: 0.1rem;
                      }
  
                      b {
                        margin-left: 0.2em;
                        font-size: 2.2rem;
                        letter-spacing: 0.1rem;
                      }
                    }
                  }
                }
              }
            }
          }
  
          .ready-stock-card-carousel::-webkit-scrollbar {
            display: none !important;
          }
          .ready-stock-card-carousel2 {
            // margin: 0em -0.5em;
            .card-container {
              font-size: 12px;
              // padding: 0px 0px 0px;
              padding-bottom: 20px;
              .card {
                cursor: pointer;
                // margin-bottom: 0.6em;
                //width: 15em;
                border-radius: 1.2rem;
                overflow: hidden;
                height: 193px;
                background-color: #F6F6F6;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 1px 1px 4px 0px rgba(0, 0, 0, 0.10), 6px 5px 8px 0px rgba(0, 0, 0, 0.09), 13px 11px 10px 0px rgba(0, 0, 0, 0.05), 23px 20px 12px 0px rgba(0, 0, 0, 0.01), 37px 32px 14px 0px rgba(0, 0, 0, 0.00);
                .section-top {
                  position: relative;
                  height: 100px;
                  width: 100%;
                  label {
                    cursor: pointer;
                    //font-weight: bold;
                    padding: 0.5em 1em;
                    width: 100%;
                    color: #f9f6f2;
                    position: absolute;
                    bottom: 0;
                    background: linear-gradient(93.2deg,
                        rgba(0, 0, 0, 0.27) 23.4%,
                        rgba(255, 255, 255, 0.4) 115.01%);
                    backdrop-filter: blur(3px);
                  }
  
                  .card-img-top {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                  }
                }
  
                .card-body-mobile {
                  background-color: #FFFFFF;
                  padding: 0.5em 0.7em;
  
                  ._card-title {
                    cursor: pointer;
                    padding: 0.2em 0;
                    color: #24262d;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  ._card-detail{
                    cursor: pointer;
                    @media (min-width: 991px) {
                      font-size: 1.4rem;
                    }
                  }
                  ._card-price {
                    cursor: pointer;
                    font-size: 0.9rem;
                    color: #616469;
                    white-space: nowrap;
                    label { //b
                      font-size: 1.2rem;
                      font-weight: 600;
                    }
                  }
                  .carListing-border {
                    height: 17px;
                    border-left: 3px solid #FFD439;
                    margin-right: 4px;
                  }
                  .carListing-border2 {
                    height: 17px;
                    border-left: 17px solid #FFD439;
                    border-top: 17px solid transparent;
                  }
                  ._label{
                    &.--car-price2{
                      padding-left: 4px !important;
                      color: #24262D;
                      font-size: 1rem;
                      font-family: "inter-medium";
                      padding: 0.1em 0em 0em 0.2em;
                      z-index: 99;
                      background: #FFD439;
                      min-width:  9em;
                      span{
                      font-size: 1rem;
                      }
                    }
                  }
                }
              }
              .border-row{
                background: #FAFAFA;
                border-radius: 6px;
                border: 0.5px solid #C8C9CB;
                padding: 0;
                width: 100%;
                margin: auto;
                .border-col{
                  align-items: center;
                  display: flex;
                  padding: 1px 2px;
                }
              }
              ._label{
                &.--text{
                  color: #24262D;
                  font-size: 0.9rem;
                margin-top: -0.2em;
                margin-bottom: 0.2em;
                  
                }
                &.--card-price{
                  color: #24262D;
                  font-size: 1.2rem;
                  // font-weight: 600;
                  font-family: "inter-medium",
                }
                &.--car-info{
                  font-size: 1rem;
                  color: #616469;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  align-items: center;
                  display: flex;
                }
                &.--car-modal-name{
                  margin-top: 0.2em;
                  font-size: 1.2rem;
                  color: #24262D ;
                  // word-break: break-all;
                  overflow: hidden;
                  text-transform: uppercase;
                  overflow: hidden;
                    -webkit-line-clamp: 2;
                   -webkit-box-orient: vertical; 
                   display: -webkit-box!important;
                }
              }
            }
  
            @media (min-width: 991px) {
              .card-container {
                //width: 30em !important;
                //padding-left: 0;
                // padding-left: 1em;
                // padding-bottom: 1em;
                padding: 1em 0em 1.5em 1em;
                .card {
                  border-radius: 1.4rem;
                  overflow: hidden;
                  background: #F6F6F6;
                  height: 30em;
                  max-height: 30em;
                  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 1px 1px 4px 0px rgba(0, 0, 0, 0.10), 6px 5px 8px 0px rgba(0, 0, 0, 0.09), 13px 11px 10px 0px rgba(0, 0, 0, 0.05), 23px 20px 12px 0px rgba(0, 0, 0, 0.01), 37px 32px 14px 0px rgba(0, 0, 0, 0.00);
                  margin-bottom: 0em;
                  // width: 287px;
               
                  .section-top {
                    position: relative;
                    height: 220px;
  
                    label {
                      font-size: 1.8rem;
                      padding: 0.5em 1em;
                      width: 100%;
                      color: #f9f6f2;
                      position: absolute;
                      bottom: 0;
                      background: linear-gradient(93.2deg,
                          rgba(0, 0, 0, 0.27) 23.4%,
                          rgba(255, 255, 255, 0.4) 115.01%);
                      backdrop-filter: blur(3px);
                    }
  
                    .card-img-top {
                      height: 100%;
                      object-fit: cover;
                    }
                  }
  
                  .card-body-desktop {
                    background-color: #FFFFFF;
                    padding: 0.5em 1em 1em 1em;
                    cursor: pointer;
                    ._card-title {
                      color: #10141a;
                      font-size: 1.8rem;
                      line-height: 1.2em;
                      padding: 0.2em 0;
                      color: #24262d;
                      height: 2.8em;
                    }
  
                    ._card-price {
                      font-size: 1.4em;
                      //   font-weight: bold;
                      color: #10141a;
                      white-space: nowrap;
  
                      span {
                        font-size: 2rem;
                        margin-left: 0.2em;
                      }
  
                      label{ //
                        margin-left: 0.2em;
                        font-size: 2rem;
                        letter-spacing: 0.1rem;
                      }
  
                      b {
                        margin-left: 0.2em;
                        font-size: 2.2rem;
                        letter-spacing: 0.1rem;
                      }
                    }
  
                    .carListing-border {
                      height: 17px;
                      border-left: 3px solid #FFD439;
                      margin-right: 4px;
                    }
                    .carListing-border2 {
                      height: 17px;
                      border-left: 17px solid #FFD439;
                      border-top: 17px solid transparent;
                    }
  
                    ._label{
                      &.--car-price2{
                        color: #24262D;
                        font-size: 1,6rem !important;
                        font-family: "inter-medium";
                        padding: 0em 0em 0em 0.4em;
                        z-index: 99;
                        background: #FFD439;
                        min-width: 170px;
                        span{
                        font-size: 1.2rem !important;
                        }
                      }
                    }
                  }
                }
                .border-row{
                  background: #FAFAFA;
                  border-radius: 6px;
                  border: 0.5px solid #616469;
                  padding: 0;
                  .border-col{
                    align-items: center;
                    display: flex;
                    padding: 3px;
                  }
                }
                ._label{
                  &.--text{
                    color: #616469;
                    font-size: 1.5rem;
                  }
                  &.--card-price{
                    color: #616469;
                    font-size: 1.8rem;
                    font-weight: 600;
                  }
                  &.--car-info{
                    font-size: 1.6rem;
                    color: #616469;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                  &.--car-modal-name{
                    font-size: 1.6rem;
                    color: #616469;
                    // word-break: break-all;
                    overflow: hidden;
                  //   -webkit-line-clamp: 2;
                  //  -webkit-box-orient: vertical; 
                  //  display: -webkit-box!important;
                  }
                  &.--car-price2{
                    color: #24262D;
                    font-size: 1,6rem !important;
                    font-family: "inter-medium";
                    padding: 0.1em 0em 0em 0.2em;
                    z-index: 99;
                    background: #FFD439;
                    span{
                    font-size: 1.2rem !important;
                    }
                  }
                }
  
              }
            }
          }
          .ready-stock-card-carousel2::-webkit-scrollbar {
            display: none !important;
          }
        }
      }
  
      .swiper-button-prev{
        color: white !important;
      }
      .swiper-button-prev::after{
        font-size: 2rem !important;
      }
      .swiper-button-next{
        color: white !important;
      }
      .swiper-button-next::after{
        font-size: 2rem !important;
      }
  
      .swiper-pagination-horizontal{
        color: #FBB24B;
        font-family: "inter-medium";
      }
  
      .swiper-pagination-bullet-active{
        background: #FBB24B;
      }
  
      .last-seen-container {
        .history-tab-container {
          padding-bottom: 1.5em;
        }
  
        .history-box-container {
          color: #D1D5DB; //dark_mode
          // color: black; //light_mode
          font-style: italic;
          font-size: 18px;
          margin-bottom: 1em;
  
          .history-box {
            cursor: pointer;
            font-style: normal;
            font-size: 16px;
            font-family: "inter-regular";
            background: #0B0D13; //dark_mode
            // background: #c9cace; //light_mode_3
  
            // box-shadow: 0px 0px 0px 0px rgba(67, 94, 190, 0.06), 5px 5px 15px 0px rgba(67, 94, 190, 0.06), 19px 20px 27px 0px rgba(67, 94, 190, 0.05), 43px 44px 37px 0px rgba(67, 94, 190, 0.03), 76px 79px 44px 0px rgba(67, 94, 190, 0.01), 118px 124px 48px 0px rgba(67, 94, 190, 0.00);
            border-radius: 0.5em;
            padding: 0.5em;
          }
  
          .history-row {
            padding: 0.5em 0.5em;
          }
  
          @media (min-width: 991px) {
            .history-row {
              font-size: 1.8rem;
            }
          }
        }
  
        .character-container {
          position: fixed;
          top: 20%;
          right: 0% !important;
          transform: translateY(-20%);
          overflow: hidden;
  
          .char-label {
            line-height: 1em;
            margin: 0em auto 0em auto;
            text-align: center;
            font-family: "homoarakhn";
            font-size: 1.2rem;
            background-color: #0B0D13; //dark_mode
            // background-color: #c9cace; //light_mode
            border-radius: 50%;
            width: 1.8em; 
            height: 1.8em; 
            color: #fbb24b;
            display: flex;
            align-items: center;
            justify-content: center;
  
            &.--active{
              background-color: #ac4343;
            }
  
            .char-link,
            .char-link:hover {
              color: #fbb24b;
              padding-right: 0.15em;
              padding-top: 0.2em;
            }
          }
  
          .char-label:hover {
            background-color: #ac4343;
          }
  
        }
  
        .brand-col{
          .carBrand-container{
            cursor: pointer;
          margin-top: 1em;
          border: 2px solid #D1D5DB;
          padding:0.2em 0.7em;
          border-radius: 1em;
          transition: transform 0.2s;
            .carBrand-div{
              margin: auto;
              width: 64px;
              height: 64px;
              .carBrand-image{
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            ._label{
            cursor: pointer;
              padding: 0;
              font-size: 1.2rem;
              font-family: "inter-medium";
              color: #24262D;
            }
          }
          .carBrand-container:hover{
            border: 2px solid #FBB24B;
            transform: scale(1.05);
            label {
              color: #FBB24B;
            }
          }
        }
  
        @media (min-width: 991px) {
          .character-container {
            position: fixed;
            top: 33% !important;
            overflow: hidden;
            right: 28%;
            transform: translateY(-20%);
            // .character-box{
            //     background: #10141A;
            //     width: 3em;
            //     border-radius: 1em;
            //     margin-left: auto;
            //     margin-right: auto;
            .char-label {
              font-size: 1.3rem;
              //font-size: 2rem;
              // background-color: #AC4343;
            }
  
            // }
          }
        }
      }
      .car-brand-container{
        background: #FAFAFA; 
        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 2px 3px 8px 0px rgba(0, 0, 0, 0.10), 7px 12px 14px 0px rgba(0, 0, 0, 0.09), 15px 27px 19px 0px rgba(0, 0, 0, 0.05), 27px 48px 22px 0px rgba(0, 0, 0, 0.01), 42px 76px 24px 0px rgba(0, 0, 0, 0.00);
        // box-shadow: 0px 0px 0px 0px rgba(67, 94, 190, 0.06), 5px 5px 15px 0px rgba(67, 94, 190, 0.06), 19px 20px 27px 0px rgba(67, 94, 190, 0.05), 43px 44px 37px 0px rgba(67, 94, 190, 0.03), 76px 79px 44px 0px rgba(67, 94, 190, 0.01), 118px 124px 48px 0px rgba(67, 94, 190, 0.00);
        border-radius: 24px;
        padding: 1.5em 1em;
        margin: 1em 0;
      }
  .customer-container{
          // background: #0B0D13; //dark_mode
          // box-shadow: 0px 0px 0px 0px rgba(67, 94, 190, 0.06), 5px 5px 15px 0px rgba(67, 94, 190, 0.06), 19px 20px 27px 0px rgba(67, 94, 190, 0.05), 43px 44px 37px 0px rgba(67, 94, 190, 0.03), 76px 79px 44px 0px rgba(67, 94, 190, 0.01), 118px 124px 48px 0px rgba(67, 94, 190, 0.00);
          // border-radius: 24px;
          // padding: 1.5em 1em;
          // margin: 1em 0;
    .customer-row{
      align-items: center;
      .customer-image{
        height: 41px;
        width: 41px;
        padding: 0;
        .avatar{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      ._label{
        &.--name{
          font-family: "inter-medium";
          font-size: 1.5rem;
          color: #D1D5DB;
        }
        &.--post{
          font-family: "inter-medium";
          font-size: 1.2rem;
          color: #616469;
          line-height: 1em;
        }
        &.--google{
          font-family: "inter-medium";
          font-size: 1.2rem;
          color: #1661d9;
          line-height: 1em;
        }
      }
      .p-rating-item-active{
        .p-rating-icon{
          color: #FBB24B !important;
        }
      }
    }
    .comment-div{
      padding: 1em;
      height: 130px;
      max-height: 80px;
      overflow: scroll;
      p{
        font-size: 1.3rem;
        color: #D1D5DB;
        font-family: "inter-medium";
      }
    }
    .comment-div::-webkit-scrollbar {
      display: none !important;   
  }
    
  }
  
    //new customer feeback
    .new_customer_container{
      padding: 55px 35px;
      height: 504px;
      max-height: 504px;
      // max-width: 327px;
      &.mobile{
      max-height: 404px;
      padding: 45px 25px;
  
      }
    }
    .new_costomer_row{
      ._label{
        &.--title{
          color: #24262D;
          font-size: 5.5rem;
          // font-style: italic;
          font-family: "inter-medium";
        }
        &.--title_mobile{
          color: #24262D;
          font-size: 3rem;
          // font-style: italic;
          font-family: "inter-medium";
        }
        .colored-text{
          -webkit-text-fill-color : transparent;
          -webkit-background-clip : text ; 
          background-image : linear-gradient(107deg, #db1218, #dea759)
        }
      }
      .background-image{
        background-image: url("https://assets.website-files.com/6481a6c4b3cce6fad9f8027f/648981f32eeef57689ca9ad9_line.svg");
        background-position: 100% 15%;
        background-repeat: no-repeat;
        background-size: auto;
        padding-right: 2rem;
        &.mobile{
          background-size: 19px;
        }
      }
    }
    .customer-info{
      align-items: center;
      .new_avatar{
        height: 80px;
        width: 80px;
        &.mobile{
          height: 65px;
          width: 65px;
        }
        image{
          object-fit: cover;
        }
      }
      .p-rating-item-active{
        .p-rating-icon{
          font-size: 1.6rem;
          color: #FBB24B !important;
        }
      }
      ._label{
        &.--name{
          color: #24262D;
          font-size: 2rem;
          // font-style: italic;
          font-family: "inter-medium";
        }
        &.--mobile_name{
          color: #24262D;
          font-size: 1,6rem;
          // font-style: italic;
          font-family: "inter-medium";
        }
      }
    }
    .content-row{
      padding: 1em 1em 0em 1em;
      min-height: 200px;
      max-height: 200px;
      overflow: scroll;
      &.mobile{
        min-height: 170px;
        max-height: 170px;
        p{
        font-size: 1.6rem;
        }
      }
      p{
        color: #24252D;
        font-size: 2rem;
        // font-family: "inter-medium";
      }
    }
    .content-row::-webkit-scrollbar {
      display: none !important;   
  }
    .icon-row{
      margin-top: 5em;
      &.mobile{
        margin-top: 3em;
        margin-bottom: 1em;
      }
    }
    }
  
    //discover page 
    .discover-page-container{
      position: relative;
      margin-top: 2em;
      ._label{
        &.--discover-title{
          font-size: 2.4rem;
          color: #252525;
          font-family: "inter-medium";
        }
        &.--discover-text{
          font-size: 1.5rem;
          color: #252525;
        }
      }
      .card-col{
        display: flex;
        justify-content: center;
      }
      .video-card{
        margin: 0.5em 0em;
        // max-height: 463.63px;
        max-height: 590.11px;
        max-width: 255px;
        width: 255px;
        // background: #141611;
        // background: #292929;
        // background: #FAFAFA;
        border-radius: 0.3em;
        // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        &.mobile{
          max-height: 325px;
          // max-width: 177px;
          // width: 177px;
          max-width: 177px;
          width: 177px;
        }
        .video-div{
          position: relative;
          max-height: 448.63px;
          max-width: 255px;
          margin: auto;
          overflow: hidden;
          margin-left: auto;
          margin-right: auto;
          border-radius: 0.3em 0.3em 0.3em 0.3em;
          &.mobile{
            max-height: 236px;
            // height: 236px;
            height: auto;
            // max-width: 236px;
            max-width: 472px;
            video {
              max-height: 236px;
              object-fit: cover;
            }
          }
          .block-div{
            background: rgba(255, 255, 255, 0);
            height: 100%;
            width: 100%;
            cursor: pointer;
            position: absolute;
            z-index: 7;
          }
          .block-div:hover{
            background: rgba(30, 30, 30, 0.4);
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 7;
            cursor: pointer;
          }
          .video-player{
            max-height: 463.63px;
            max-width: 255px;
              .react-player__preview{
              position: relative !important;
              .react-player__shadow{
                top: 5px;
                right: 5px;
                width: 35px !important;
                height: 35px !important;
                .react-player__play-icon{
                  border-width: 6px 0px 6px 12px !important;
                }
              } 
            }
          }
          .discover-cover{
            &.desktop{
              video {
                max-height: 448.63px !important;
                object-fit: cover;
                border-radius: 0.3em;
              }
            }
            video {
              // max-height: 463.63px !important;
              object-fit: cover;
              border-radius: 0.3em;
            }
          }
        }
        .label-container{
          // max-height: 97.97px;
          padding:0em 0.2em;
          .label-row{
            max-height: 55.9px;
            overflow: hidden;
          }
          .agent-div{
            height: 25px;
            width: 25px;
          }
        }
        ._label{
          &.--title{
            margin-top: 0.7em;
            font-size: 1.4rem;
            // color: #ffffff;
            font-family: "inter-regular";
            transition: height 1s;
            -webkit-font-smoothing: antialiased;
            &.hide{
              overflow: hidden;
              transition: height 1s;
              -webkit-line-clamp: 2;
           -webkit-box-orient: vertical;
           display: -webkit-box !important;
            }
            &.mobile{
                 font-size: 1.4rem; 
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                 -webkit-box-orient: vertical;
                 display: -webkit-box !important;
            }
          }
          &.--username{
            font-size: 1.3rem;
            // color: #ffffff;
            font-family: "inter-medium";
            overflow: hidden;
            -webkit-line-clamp: 2;
             -webkit-box-orient: vertical;
             display: -webkit-box !important;
          }
          &.--see-more{
            cursor: pointer;
            font-size: 1.4rem;
            font-family: "inter-regular";
            -webkit-font-smoothing: antialiased;
          }
          &.--see-more:hover{
            color: black;
           text-decoration: underline;
          }
        }
      }
      .btn-div{
        margin-top: 20px;
        ._btn{
          padding: 8px 0px;
          border-radius: 24px;
          border-color: rgb(214, 160, 85);
          color: rgb(214, 160, 85);
          font-family: inherit;
          font-weight: bold;
          font-size: 15px;
          background-color: rgba(0, 0, 0, 0);
          border-width: 2px;
          border-style: solid;
          span{
            font-size: 15px;
            padding: 8px 58px;
            font-family: "inter-medium";
           background-color: rgba(0, 0, 0, 0);
          }
        }
        ._btn:hover{
          background: rgba(214, 160, 85, 0.1);
        }
      }
    }
    .video_info-div{
      position: fixed;
      // background: rgba(226, 218, 218, 0.62);
     background: #fbfbfbf5;
      height: 100%;
      width: 100%;
      z-index: 9999;
      &.no-background{
        background: none;
      }
      .video_info-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 1em;
        // background: #121212;
        background: #FFFFFF;
        z-index: 2000;
        box-shadow:  0 8px 64px 0 rgba(0,0,0,0.04),0 1px 4px 0 rgba(0,0,0,0.02);
        .info-left{
          // background: #121212;
          height: 100%;
        }
        .info-rigth{
          // background: #121212;
          .avatar-div{
            height: 40px;
            width: 40px;
            margin: auto;
          }
          .row-description::-webkit-scrollbar{
          display: none !important;
          }
          ._label{
            &.--upload_by{
              font-size: 1.4rem;
              color: #393939 ;
            }
          }
          ._p{
            &.--description{
              font-size: 1.6rem;
              color: #393939;
              overflow-wrap: break-word;
              white-space: pre-wrap;
            }
          }
          .span-tag{
            color: #32527f;
            cursor: pointer;
          }
        }
      }
      .react-player__preview{
        border-radius: 16px 0px 0px 16px;
      }
      .back_btn{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #FFFFFF;
        // background: red;
        position: fixed;
        top: 14%;
        left: 6%;
        transform: translate(-10%,-10%);
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.04),0 1px 2px 0 rgba(0,0,0,0.02);;
      }
      .back_btn:hover{
        background: #12121243;
      }
      .desktop-video{
        video {
          border-radius: 16px 0px 0px 16px;
        }
      }
    }
  
    .video_info-mobile{
      // background: #121212;
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 99999;
      .back_btn{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #121212;
        // background: red;
        position: fixed;
        top: 3%;
        left: 6%;
        transform: translate(-10%,-10%);
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .back_btn:hover{
        background: #121212af;
      }
      .info-div{
        position: absolute;
        height: 100%;
        width: 100%;
        // background: #121212;
        // background: #252525;
        background: #ffffff;
  
        ._p{
          &.--description{
            font-size: 1.6rem;
            color: #393939;
            overflow-wrap: break-word;
            white-space: pre-wrap;
          }
        }
        ._label{
          &.--upload_by{
            font-size: 1.3rem;
            color: #393939;
            line-height: 1.2em;
          }
          &.--title{
            font-size: 1.7rem;
            color: #393939;
            font-family: "inter-medium";
          }
        }
        .span-tag{
          color: #32527f;
         cursor: pointer;
        }
        .avatar-div{
          margin: auto;
          height: 30px;
          width: 30px;
        }
      }
    }
  
    .order-page-container {
      min-height: 100vh;
      ._label {
        font-family: 'inter-regular';
        &.--white-text-huge {
          cursor: pointer;
          width: 100%;
          color: #D1D5DB;
          font-size: 1.5rem;
        }
  
        &.--white-text-big {
          cursor: pointer;
          width: 100%;
          color: #D1D5DB;
          font-size: 1.4rem;
        }
  
        &.--white-text-medium {
          cursor: pointer;
          width: 100%;
          color: #D1D5DB;
          font-size: 1.2rem;
        }
  
        &.--white-text-small {
          width: 100%;
          color: #D1D5DB;
          font-size: 1.0rem;
        }
  
        &.--gold-text {
          color: #FBB24B;
          font-size: 1.4rem;
        }
  
        &.--grey-text-medium {
          cursor: pointer;
          color: #9091ad;
          font-size: 1.2rem;
        }
  
        &.--grey-text-small {
          color: #9091ad;
          font-size: 1.0rem;
        }
      }
  
      .order-history-content {
        .order-card-container:first-child {
          cursor: pointer;
          border-radius: 0.8em;
          margin: 0em 0 1em 0;
        }
  
        .order-card-container {
          cursor: pointer;
          position: relative;
          background: #0B0D13;
          border-radius: 0.8em;
          margin: 1em 0 1em 0;
          padding: 0.5em 1em;
          overflow: hidden;
          // height: 4.5em;
          transition: height 1s;
  
          &.--complete {
            height: auto;
            text-transform: uppercase;
          }
  
          &.--active {
            height: 15em;
            background: #0B0D13;
            transition: height 1s;
  
          }
  
          &.--tall {
            height: calc(100% + 2em);
            transition: height 1s;
          }
          
          .dot_column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 18px;
            position: relative;
          }
  
          .date_column {
            text-align: right;
          }
  
          .padding_column {
            padding-top:20px;
          }
          .padding_column_small{
            padding-bottom: 1.4em;
            color: #9091ad;
            font-size: 1.2rem;
            .line-container{
              &.--dot{
                color: #202020;
                font-size: 1.2rem;
              }
            }
            span {
              color: rgba(87, 170, 93, 1);
            }
            svg {
              color: #9091A0
            }
          }
          .padding_column_small:last-child{
            padding-bottom: 1.4em;
            color: #D1D5DB;
            font-size: 1.2rem;
            .line-container{
              &.--dot{
                color: #D1D5DB;
                font-size: 1.2rem;
              }
            }
            span {
              color: rgba(87, 170, 93, 1);
            }
            svg {
              color: #D1D5DB
            }
          }
        }
      }
      .recommend-container{
        ._label{
          font-family: 'inter-regular';
          &.--title{
            width: 100%;
            font-size: 1.8rem;
            color: #24262D;
            @media (max-width: 991px) {
              font-size: 1.5rem;
            }
          }
          &.--grey-label{
            width: 100%;
            font-size: 1.6rem;
            color: #9091A0;
            @media (max-width: 991px) {
              font-size: 1.4rem;
            }
          }
          &.--gold-label{
            width: 100%;
            font-size: 1.6rem;
            color: #AC3434;
            @media (max-width: 991px) {
              font-size: 1.4rem;
            }
          }
          &.--remark{
            width: 100%;
            font-size: 1.2rem;
            color: #9091A0;
            @media (max-width: 991px) {
              font-size: 1rem;
            }
          }
        }
        .recommend-content{
          border-radius: 0.8em;
          margin: 0em 0 1em 0;
          .recommend-box{
            position: relative;
            background: #FFFFFF;
            border-radius: 0.8em;
            margin: 0em 0 1em 0;
            padding: 0.5em 1em;
            overflow: hidden;
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 1px 4px 0px rgba(0, 0, 0, 0.1), 6px 5px 8px 0px rgba(0, 0, 0, 0.09), 13px 11px 10px 0px rgba(0, 0, 0, 0.05), 23px 20px 12px 0px rgba(0, 0, 0, 0.01), 37px 32px 14px 0px rgba(0, 0, 0, 0);
            .recommend-car{
              align-items: center;
              margin: 0;
              height: 6em;
              .car-columns{
                max-width: 12em;
              }
            }
          }
          .announcement-box{
            position: relative;
            background: #0B0D13;
            border-radius: 0.8em;
            margin: 0em 0 1em 0;
            padding: 0;
            overflow: hidden;
            padding: 1em 0.5em;
            transition: height 1s;
            &.colums-desktop-version{
            }
            .event-detail-section-one{
              padding-top: .2em;
              padding-bottom: 1em;
              line-height: 1em;
            }
            .event-detail-section-two{
              line-height: 1em;
            }
            .sub-row{
              line-height: 0.8em;
            }
            .event-schedule{
              margin-top: 1em;
              font-size: 1.2rem;
              color: #9091A0;
            }
            .event-list{
              font-size: 1.1rem;
              color: #9091A0;
              margin: 0;
              ul{
                padding-top: .5em;
                padding-left: 1.5em;
              }
            }
            .title-row{
              display: flex;
              justify-content: space-between;
              img{
                width: 2em;
                height: 2em;
              }
            }
            ._label{
              &.--sub-title{
                width: 100%;
                display: flex;
                font-size: 1.3rem;
                color: #9091A0;
              }
              &.--event-title{
                font-size: 2rem;
                color: #D1D5DB;
              }
              &.--grey-label{
                font-size: 1.2rem;
                // color: #9091A0;
                color: #FFD439;
                &.--value{
                  span{
                    // color: #9091A0;
                    color: #FFD439;
                    padding-right: 0.5em;
                  }
                  color: #FFD439;
                }
              }
              &.--desc{
                font-size: 1rem;
                color: #9091a075;
              }
            }
          }
        }
        .recommend-price-section{
          padding: 0.5em;
          margin-top: 1.5em;
          margin-bottom: 1em;
          // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
          background: #FFFFFF;
          background: radial-gradient(50.09% 4027.9% at 50.09% 49.31%, rgba(114, 64, 64, 0.28) 0%, rgba(255, 249, 249, 1) 0.01%, rgba(16, 20, 25, 0) 100%);
          align-items: center;
          .price-details{
            text-align: center;
            line-height: 1.5em;
            @media (max-width: 991px) {
              line-height: 1.3em;
            }
          }
          .tag-details{
            text-align: left;
            justify-content: center;
            display: grid;
            line-height: 1.4em;
          }
        }
        .recommend-contact-input{
          margin: 0;
          display: flex;
          align-items: center;
          .left-input-container{
            border-radius: 6px 0px 0px 6px;
            background: #FAFAFA;
            padding: 0.6em 0.5em 0.5em 0.5em;
            @media (max-width: 991px) {
              font-size: 0.8em;
            }
            cursor: text;
            input{
              width: 100%;
              outline: none;
              background: transparent;
              border: none;
            }
            input::placeholder{
              color: #bababa;
            }
          }
          .right-button-container{
            @media (max-width: 991px) {
              font-size: 0.8em;
            }
            max-width: 9em;
            border-radius: 0px 6px 6px 0px;
            background: #dc6969;
            text-align: end;
            padding: 0.55em;
            label{
              white-space: nowrap;
              background: transparent;
              border: none;
              // color: #0B0D13;
              color: white;
              // font-family: 'inter-regular';
              display: flex;
              justify-content: center;
            }
            button:disabled{
              cursor: no-drop;
            }
          }
        }
        .remark-container{
          margin-top: 0.5em;
        }
      }
    }
  
    .login-modal-container{
      .modal-body{
        height: 40em !important;
        background: #FFFFFF !important;
        padding: 2em !important;
        .form-group{
          margin: 0;
          &._text{
          padding-bottom: 2em;
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
            .phone-input{
              // width: 25em;
              max-width: fit-content;
              outline: none;
              background: transparent;
              border-bottom: 0.15em solid #667080;
              border-top: none;
              border-left: none;
              border-right: none;
              border-radius: 0em;
              padding: 0.6em;
              color: #667080;
              box-shadow: none;
              font-style: italic;
              font-size: 1.7rem;
            }
            .code-container{
              text-align: center;
              .code-input{
                width: 2.3em;
                text-align: center;
                margin-right: 0.52em;
                outline: none;
                background: transparent;
                border-bottom: 0.15em solid #667080;
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0em;
                padding: 0.6em;
                color: #667080;
                box-shadow: none;
                font-style: none !important;
                @media (min-width: 450px) {
                  width: 3em;
                }
              }
            }
            input{
              outline: none;
              background: #D1D5DB;
              border: 0.15em solid #667080;
              border-radius: 0.5em;
              padding: 0.8em;
              color: #24262D;
              box-shadow: 0px 0px 6px #000000;
              //font-style: italic;
              left: none !important;
            }
            input:disabled{
              outline: none;
              background: #ffffff83;
              border: 0.15em solid #667080;
              border-radius: 0.5em;
              padding: 0.8em;
              color: #24262D;
              box-shadow: 0px 0px 6px #000000;
              font-style: italic;
            }
            input::placeholder{
              color: #9091A0;
            }
          }
          &.--errors{
            .code-container{
              text-align: center;
              .code-input{
                width: 2.3em;
                text-align: center;
                margin-right: 0.52em;
                outline: none;
                background: transparent;
                border-bottom: 0.1em solid rgba(236, 50, 55, 0.6);
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0em;
                padding: 0.6em;
                color: #D1D5DB;
                font-style: none !important;
                @media (min-width: 450px) {
                  width: 3em;
                }
              }
            }
            .phone-input{
              border: 0.1em solid rgba(236, 50, 55, 0.6);
              border-top: none;
              border-left: none;
              border-right: none;
              box-shadow: 0px 0px 0px #AC4343;
            }
            input{
              border: 0.1em solid rgba(236, 50, 55, 0.6);
              box-shadow: 0px 0px 8px #AC4343;
            }
            input::placeholder{
              color: #EC3237;
            }
          }
          &.--password{
            input{
              font-family: 'password';
            }
            input::placeholder{
              font-family: 'inter-regular';
            }
          } 
        }
        .form-input{
          position: relative;
          .error-message{
            position: relative;
            bottom: 2em;
            padding: 0;
            font-size: 1.2rem;
            color: #EC3237;
          }
          .form-label{
            color: #616469 !important;
          }
        }
  
        // .phone-input{
        //   position: relative;
        //   .error-message{
        //     position: absolute;
        //     bottom: 0.8em;
        //     padding: 0;
        //     font-size: 1.2rem;
        //     color: #EC3237;
        //   }
        // }
        ._label{
          &.--modal-title{
            padding: 1em;
            font-size: 2.0rem;
            color: #24262D;
            text-align: center;
            font-family: "inter-medium";
            //font-weight: 700;
            letter-spacing: 0.1em;
          }
          &.--modal-tc{
            color: #24262D;
            font-size: 1.2rem;
          }
          &.--white-text{
            color: #FFFFFF;
            background-color: #24262D;
            z-index: 1; position: relative;
            width: auto;
          }
          &.--verification-login{
            margin-bottom: 1em;
            width: auto;
            color: #D1D5DB;
            font-size: 1.6rem;
            text-decoration-line: underline;
          }
        } 
        .button-group{
          width: 100%;
          //font-weight: 600;
          &.--login-button{
            cursor: pointer;
            font-family: "inter-regular" !important;
            white-space: nowrap;
            padding: 0.8em;
            margin-bottom: 1.5em;
            letter-spacing: 0.1em;
            color: #F8F8F8 !important;
            background: #AC4343 !important;
          }
          &.--login-button:disabled{
            background: #24252D !important;
          }
          &.--send-code{
            font-size: 1.1rem;
            white-space: nowrap;
            // padding: 0.8em;
            margin-bottom: 1.5em;
            // letter-spacing: 0.1em;
            color: #242525 !important;
            font-family: "inter-medium" !important;
            border: 1px solid #242525 !important;
          }
          &.--whatsapp{
            white-space: nowrap;
            padding: 0.8em;
          }
        }
        .header-login{
          position: relative;
          .button-close{
            svg{
              color: #D6D6D6;
              position: absolute;
              top: 50%;
              transform: translate(-50%,-50%);
              right: 0;
              width: 2em;
              height: 2em;
            }
          }
          .button-back{
            svg{
              color: #D6D6D6;
              position: absolute;
              top: 50%;
              transform: translate(-50%,-50%);
              left: 1em;
              width: 2em;
              height: 2em;
              @media (min-width: 991px) {
                left: 2em;
              }
            }
          }
        }
        .line-conatiner-row{
          position: relative;
          justify-content: center;
          margin-bottom: 1em;
        }
      }
    }
  
    .share-modal-container{
      .modal-body{
        height: 35em !important;
        background: #ffffff !important;
        padding: 2em !important;
        .card{
          background-color: transparent;
          // color: #D1D5DB;
          box-shadow: none;
          ._action-card{
            border: 0.1em solid #4d4e4f;
            border-radius: 0.5em;
            padding: 1em;
            text-align: center;
            cursor: pointer;
            svg{
              margin-right: 1em;
              cursor: pointer;
            }
            button,label{
              cursor: pointer;
            }
          }
          ._action-card:hover{
            border: 0.1em solid #D1D5DB;
            background-color: #4d4e4f;
          }
        }
      }
    }
  
    .japan-link-modal-container{
      .modal-dialog{
        z-index: 9999 !important;
      }
      .modal-body{
        height: auto !important;
        background: #FFFFFF !important; //dark_mode
        padding: 3em 2em !important;
        .card{
          background-color: transparent;
          color: #D1D5DB; //dark_mode
          // color: black; //light_mode
          box-shadow: none;
          .header-login{
            position: relative;
            .button-close{
              cursor: pointer;
              svg{
                color: black; //light_mode
                position: absolute;
                top: 50%;
                transform: translate(-50%,-50%);
                right: 0;
                width: 2em;
                height: 2em;
              }
            }
          }
          ._action-card{
            width: 22em;
            display: grid;
            grid-template-columns: 30% 70%;
            border: 0.1em solid #24262D;
            border-radius: 0.5em;
            padding: 1em;
            text-align: left;
            cursor: pointer;
            font-size: 1.5rem;
            @media (min-width: 450px) {
              grid-template-columns: 20% 80%;
            }
            ._action-img{
              width: 3em;
            }
            img{
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
            button, label{
              cursor: pointer;
              color: #24262D;
              font-family: "inter-semi-bold";
            }
          }
          ._action-card:hover{
            border: 0.1em solid #D1D5DB;
            background-color: #FFD439;
            // background-color: #4d4e4f;
          }
        }
      }
    }
  
    .full-modal-box-container {
      border: none;
  
      .modal-dialog {
        top: 50%;
        transform: translate(-0%, -50%) !important;
      }
  
      .modal-content {
        background-color: unset !important;
        // border-radius: 2em;
        overflow: hidden;
  
        .modal-body {
          padding: 1em;
          // height: 30em;
          background-color: #10141a;
  
          svg {
            width: auto;
          }
  
          ._title {
            color: #f9f6f2;
            font-size: 1.8rem;
          }
  
          .search-bar-row {
            cursor: pointer;
            font-family: "cabin-regular";
            background: #f9f6f2;
            color: #9091a0;
            border-radius: 0.5em;
            padding: 0.2em 0.5em 0.2em 1em;
            margin: 1em 2em;
            max-width: 30em;
            font-size: 1.6rem;
  
            input {
              background-color: transparent;
              border: none;
              outline: none;
              &::placeholder{
                color: rgb(144, 145, 160);
              }
            }
          }
  
          .search-tab-box {
            font-size: 1.4rem;
            background: #232832;
            border: 1px solid #3e3c3c;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            width: auto;
            text-align: center;
            white-space: nowrap;
            padding: 0.5em 1em;
            margin: 0.5em;
  
            &.--active {
              background: #f9f6f2;
              border: 1px solid #3e3c3c;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
              border-radius: 8px;
            }
          }
  
  
        }
      }
    }
  
    .search-half-modal {
      // background-color: #0b0d13;
      [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;
        background-color: #0b0d13;
      }
      [data-rsbs-scroll] {
        background-color: #1c1e24;
      }
      [data-rsbs-header] {
        background-color: #1c1e24;
      }
  
      .modal-dialog {
        top: 0 !important;
        transform: none !important;
        max-width: 100% !important;
        padding: 0em 2em;
      }
      .modal-dialog::-webkit-scrollbar{
        display: none !important;
      }
  
      .modal-content {
        background-color: unset !important;
        box-shadow: none !important;
        overflow: hidden;
  
        .modal-body {
          // padding: 1em;
          // height: 30em;
          // background-color: #0b0d13;
  
          svg {
            width: auto;
          }
  
          ._title {
            color: #f9f6f2;
            font-size: 1.6rem;
          }
  
          .search-bar-row {
            cursor: pointer;
            font-family: "cabin-regular";
            background: #f9f6f2;
            color: #9091a0;
            border-radius: 0.5em;
            padding: 0.2em 0.5em 0.2em 1em;
            margin: 1em 0em;
            width: 99%;
            font-size: 1.6rem;
  
            input {
              background-color: transparent;
              border: none;
              outline: none;
            }
            ::placeholder {
              color: rgb(144, 145, 160);
            }
          }
  
          .search-tab-box {
            font-size: 1.4rem;
            background: #232832;
            border: 1px solid #9091A0;
            color: #9091A0;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            width: auto;
            text-align: center;
            white-space: nowrap;
            padding: 0.5em 1em;
            margin: 0.5em;
  
            &.--active {
              background: #f9f6f2;
              color: #000;
              border: 1px solid #3e3c3c;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
              border-radius: 8px;
            }
          }
  
  
        }
      }
  
      .car-item-box {
        padding: 1em;
  
        &.--search{
          border-bottom: 0.1em solid #ababab;
          padding-bottom: 2em;
          padding-top: 2em;
        }
  
        ._label {
          &.--title {
            //font-weight: bold;
            width: 100%;
            font-size: 1.6rem;
            margin-top: 0.5em;
            color: #D1D5DB;
          }
      
          &.--detail {
            width: 100%;
            font-size: 1.4rem;
            margin-top: 0.5em;
            color: #9091A0;
          }
      
          &.--tag {
            padding: 0.3em 0.7em;
            margin-top: 1em;
            margin-right: 0.5em;
            font-size: 1.2rem;
            border: 1px solid #9091A0;
            border-radius: 0.8em;
            color: #9091A0;
      
            @media (min-width: 991px) {
              padding: 0.3em 0.8em;
            }
          }
        }
      }
    }
  
    .row {
      margin: 0;
    }
  
    .footer-container {
      z-index: 1;
      position: relative;
      ._label {
        font-size: 1.4rem;
        color: #9e9e9e; //dark_mode
        // color: black; //light_mode
  
  
        &.--desc-text {
          &.--special {
            padding-top: 1em;
            font-size: 1.2rem;
          }
  
          @media (min-width: 991px) {
            width: auto;
  
            &.--special {
              padding: 0 0 0 1em;
              width: auto;
            }
          }
        }
  
        &.--item-title {
          cursor: pointer;
          color: #616469; //dark_mode
          // color: black; //light_mode
          // font-weight: bold; //light_mode
        }
  
        &.--item-text {
          cursor: pointer;
          &.active {
            color: #eb3238;
          }
        }
  
        &.--item-text:hover {
          color: #eb3238;
        }
  
        &.--highlight-text {
          // color: #577DE5;
          color: #eb3238;
        }
      }
  
      &.footer-one {
        // position: absolute;
        // width: 100%;
        // bottom: 0;
        padding: 2em 1em 5em 1em;
        // background: #F8F8F8;
        background: #F0F2F5;
        // background: #10141A; //dark_mode
        // background: #e9e9f1; //light_mode_2
        // transform: translate(0em, 30em);
  
        .listing-item-container {
          transition: height 0.5s;
          overflow: hidden;
  
          &.active {
            transition: height 0.5s;
          }
        }
      }
  
      &.footer-two {
        width: 100%;
        height: fit-content;
        padding: 1rem 2rem;
        bottom: 0;
        position: absolute;
        // border-top: 1px solid  $color-grey;
        max-width: none !important;
        display: none;
      }
  
      @media (min-width : 991px) {
        &.footer-two {
          //display: block;
          bottom: 0;
          position: absolute;
        }
      }
  
    }
  
    .car-post-bottom{
      background: #14161E;
      padding: 1em 1em;
      padding-bottom: 2em;
      font-size: 1.6rem;
      .contact-section{
        justify-content: center;
        .button-group{
          padding: 0.6em 1em;
          width: auto;
          &.--whatsapp{
            margin-left: 1em;
          }
        }
      }
    }
    .carpost-page-container {
      min-height: 54em;
  
      ._label {
        &.--order-type {
          font-size: 1.2rem;
          color: #FBB24B;
          width: auto;
          margin-right: 0.5em;
          font-family: "inter-semi-bold";
        }
        &.--order-type_desktop{
          font-size: 1.6rem !important;
          color: #FBB24B;
          width: auto;
          margin-right: 0.5em;
          font-family: "inter-semi-bold";
          }
  
        &.--tag-order-type {
          width: auto;
          margin: 0em 0.5em;
          text-transform: uppercase;
          font-size: 1rem;
          padding: 4px 8px;
          font-family: "inter-bold" ;
          border-radius: 0.5em;
          color: #24262D;
          border: 0.1em solid #FFD439;
          box-shadow: inset 0px 0px 20px rgba(175, 175, 175, 0.27);
          background: linear-gradient(261.62deg, rgba(255, 149, 0, 0.47) 53.05%, rgba(135, 135, 135, 0.1363) 171.68%), #FFD439;
        }
  
        &.--brand {
          width: auto;
          font-size: 1.4rem;
          color: #D1D5DB;
        font-family: "inter-medium";
        }
  
        &.--type {
          width: 100%;
          font-size: 2rem;
          //font-weight: bold;
          color: #D1D5DB;
        }
  
        &.--model {
          width: 100%;
          font-size: 1.4rem;
          color: #D1D5DB;
        }
  
        &.--grey-label {
          width: 100%;
          font-size: 1.2rem;
          color: #616469;
        font-family: "inter-medium";
        }
  
        &.--pricing {
          width: 100%;
          //font-weight: bold;
          font-family: "inter-semi-bold";
          font-size: 1.8rem;
          color: #24262D;
        }
  
        &.--note-text {
          width: 100%;
          font-size: 0.9rem;
          color: #24262D;
          line-height: 1em;
        }
  
        &.--tool-text {
          cursor: pointer;
          width: 100%;
          font-size: 1rem;
          line-height: 2em;
          color: #24262D;
        }
  
        &.--tab-active-label {
          cursor: pointer;
          font-size: 1.5rem;
          color: #24262D;
          width: auto;
          padding: 0;
          border-bottom: 0.2em solid  #FFD439;
          font-family: "inter-semi-bold";
        }
  
        &.--tab-label {
          cursor: pointer;
          font-size: 1.5rem;
          color: #616469;
          width: auto;
          padding: 0;
          font-family: "inter-semi-bold";
  
        }
  
        &.--options {
          text-transform: capitalize;
          width: 100%;
          font-size: 1.2rem;
          // color: #616469;
          color: #a6a9ac;
        }
  
        &.--options-label {
          font-size: 1.4rem;
          width: 100%;
          color: #24262D;
        }
  
        &.--options-title {
          font-size: 1.8rem;
          width: 100%;
          //font-weight: bold;
          font-family: "inter-semi-bold";
          color: #24262D;
        }
  
        &.--options-detail {
          font-size: 1.8rem;
          width: 100%;
          font-family: "inter-medium";
          //font-weight: bold;
          color: #24262D;
        }
  
        &.--main-title {
          font-size: 1.6rem;
          //font-weight: 700;
          font-family: "inter-bold";
          color: #24262D;
          line-height: 1.2em;
        }
  
        &.--sub-title {
          font-family: "inter-medium";
          font-size: 1.4rem;
          color: #24262D;
        }
  
        &.--name {
          width: 100%;
          font-size: 1.3rem;
          font-family: "inter-medium";
          color: #24262D;
        }
  
        &.--agent-type {
          font-size: 1rem;
          color: #616469;
        }
  
        &.--tag {
          width: auto;
          font-size: 1rem;
          color: #9E9E9E;
          padding: 0px 6.2px;
          svg {
            margin-right: 0.5em;
          }
        }
      }
  
      .image-header-container {
        padding: 0;
        position: relative;
        height: 18em;
        @media (min-width: 911px) {
          height: 23em;
        }
        .car-post-background {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
        }
  
        .car-item {
          position: absolute;
          width: 100%;
          left: 50%;
          bottom: 1em;
          transform: translateX(-50%);
  
          @media (min-width: 768px) {
            width: 30em;
            bottom: 0em;
          }
  
          @media (min-width: 911px) {
            width: 18.75em;
            bottom: 0em;
          }
  
          @media (min-width: 991px) {
            bottom: 0em;
          }
        }
  
        .post-tag-container {
          z-index: 1;
          position: relative;
          padding: 1em 0.5em;
          margin-left: 0;
          margin-right: 0;
        }
  
        .post-brand-container {
          z-index: 1;
          margin: 0;
          padding: 0em 0.5em;
          position: relative;
  
          .post-brand {
            display: grid;
            place-items: center;
            width: auto;
            line-height: 1.3em;
          }
        }
      }
  
      .toolbar-box {
        padding: 0.7em 0.6em;
        background: #F8F8F8;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 2px 3px 8px 0px rgba(0, 0, 0, 0.1), 7px 12px 14px 0px rgba(0, 0, 0, 0.09), 15px 27px 19px 0px rgba(0, 0, 0, 0.05), 27px 48px 22px 0px rgba(0, 0, 0, 0.01), 42px 76px 24px 0px rgba(0, 0, 0, 0);
        // box-shadow: 0px 0px 2px 0px rgba(67, 94, 190, 0.25);
        border-radius: 0.7em;
        .sc-hLQRIN {
          gap: 8px;
          display: grid;
          .sc-eDLJxc{
            border: none;
            gap: 8px;
            .sc-gLLvby{
              border-radius: 0.2em;
            }
          }
        }
      }
  
      .toolbar-container {
        top: -1em;
        position: relative;
        z-index: 1;
  
        .toolbar-box {
          .toolbar-box-columns {
            line-height: 1.3em;
            display: grid;
            place-items: center;
          }
        }
  
        .toolbar-right-section {
          cursor: pointer;
          width: auto;
          display: grid;
          place-items: center;
        }
      }
  
      .tabbar-container {
        padding: 0;
  
        .tabbar-section-row {
          position: relative;
          overflow: scroll;
          display: flex;
          gap: 1em;
          padding: 0 1em;
          // height: 2.5em;
  
          label {
            white-space: nowrap;
            line-height: 2em;
          }
  
          .scrollbar {
            height: 0.1em;
            background: #FFD439;
            z-index: 1;
            position: absolute;
            width: 5em;
            border-radius: 0.5rem;
            top: 1.5em;
            left: 0.9em;
          }
  
          @media (min-width: 494px) {
            justify-content: center;
          }
        }
  
        .tabbar-section-row::-webkit-scrollbar {
          display: none !important;
        }
      }
  
      .car-panel-container {
        .toolbar-box {
          padding: 1em 0.6em;
  
          .text-div {
            margin-left: 0.5em;
            line-height: 1em;
          }
        }
  
        @media (min-width: 991px) {
          padding-right: 0;
        }
      }
  
      .options-box-container {
        margin-top: 1em;
  
        .toolbar-box {
          .listing-text-container {
            display: flex;
            align-items: start;
            padding: 0;
            gap: 0.5em;
            margin-top: 0.5em;
  
            .listing-option {
              line-height: 1.3em;
            }
          }
  
          .listing-box-container {
            border: 1px solid #dddddd;
            border-radius: 9px;
            margin-top: 0.7em;
            padding: 0.5em 0.5em 0 0.5em;
  
            ul {
              padding-left: 3rem;
            }
  
            ._label {
              &.--options {
                //font-weight: 600;
                font-size: 1.4rem;
                color: #24262D;
                font-family: "inter-medium";
              }
  
              &.--options-label {
                text-transform: capitalize;
                width: 100%;
                font-size: 1.2rem;
                color: #616469;
              }
  
              &.--options-detail {
                font-weight: normal;
                font-size: 1.4rem;
                color: #24262D;
              }
            }
          }
        }
      }
  
      .detail-card-container {
        &.desktop{
          .toolbar-box{
            ._label{
              &.--main-title{
                font-size: 2rem;
              }
              &.--name{
                font-size: 1.8rem;
              }
              &.--agent-type{
                font-size: 1.4rem;
              }
              &.--tag{
                font-size: 1.2rem;
              }
            }
            .name-section{
              text-align: start;
              line-height: 1.3em;
            }
          }
          .post-image-div{
            width: 40px;
            height: 40px;
            img{
              height: 100%;
              width: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .button-group{
            &.--contact-agent{
              label{
                font-family: "inter-semi-bold" !important;
                font-size: 1.2rem !important;
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content:center ;
              }
            }
          }
        }
        .toolbar-box {
          // background-color: #F8F8F8;
          background: #FFFFFF;
          // box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
          // box-shadow: none;
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 2px 3px 8px 0px rgba(0, 0, 0, 0.1), 7px 12px 14px 0px rgba(0, 0, 0, 0.09), 15px 27px 19px 0px rgba(0, 0, 0, 0.05), 27px 48px 22px 0px rgba(0, 0, 0, 0.01), 42px 76px 24px 0px rgba(0, 0, 0, 0);
  
          .car-info-container {
            padding: 0.5em;
            background: #F8F8F8;
            border: 1px solid #dddddd;
            border-radius: 9px;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0.5em;
            margin-bottom: 1em;
          }
  
          .button-group {
            height: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: unset;
            font-size: 13px !important;
            // padding: 0.2em 1em !important;
          }
  
          .name-section {
            line-height: 1.2em;
          text-align: center;
          }
        }
        .post-image-div{
          width: 35px;
          height: 35px;
          img{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .button-group{
          &.--contact-agent{
            label{
              font-family: "inter-semi-bold" !important;
              font-size: 1rem !important;
              padding: 8px;
              display: flex;
              align-items: center;
              justify-content:center ;
            }
            svg{
              margin-right: 0.4em;
            }
          }
        }
      }
  
      @media (min-width: 768px) {
        .toolbar-container {
          padding: 0;
          top: -1em;
          position: relative;
          z-index: 1;
  
          .toolbar-box {
            margin: 0;
            ._label{
              &.--grey-label{
                font-size: 1.4rem !important;
              }
              &.--pricing{
                font-size: 2rem !important;
              }
            }
          }
        }
      }
  
      @media (min-width: 991px) {
        // height: 29em;
      }
  
      .sc-beySbM {
        padding: 0;
        gap: 1em;
        display: grid;
        border-radius: 0.5rem;
        overflow: hidden;
        .sc-guDLRT {
          left: 0 !important;
          border: none;
          gap: 1em;
          .sc-hLQRIN{
            border-radius: 0.5rem;
          }
        }
      }
    }
  
    .sc-guDLRT {
      left: 0 !important;
    }
  
    .brand-page-container {
      // background-color: #14161E;
  
      ._label {
        cursor: pointer;
        &.--banner-title {
          color: #D1D5DB;
          font-size: 2rem;
          //font-weight: bold;
          text-align: center;
          margin-bottom: 2em;
        }
  
        &.--brand-title {
          width: auto;
          // background: #14161E;
          background: #ffffff;
          z-index: 1;
          text-transform: uppercase;
          color: #9091A0;
          text-align: center;
        }
  
        &.--title {
          //font-weight: bold;
          width: 100%;
          font-size: 1.6rem;
          color: #24262D;
          font-family: "inter-semi-bold";
        }
  
        &.--detail {
          width: 100%;
          font-size: 1.4rem;
          color: #9091A0;
          font-family: "inter-semi-bold";
        }
  
        &.--tag {
          padding: 0.1em 0.8em;
          margin-top: 0.5em;
          margin-right: 0.5em;
          font-size: 1.2rem;
          border: 1px solid #9091A0;
          border-radius: 0.8em;
          color: #9091A0;
  
          @media (min-width: 991px) {
            padding: 0.3em 0.8em;
          }
        }
      }
  
      .car-item-box {
        padding: 1em;
      }
  
      .video-banner-container {
        margin-top: 1em;
        padding: 0;
        position: relative;
        height: 220px;
  
        @media (min-width: 700px) {
          height: 300px;
        }
        @media (min-width: 991px) {
          height: 360px;
        }
  
        .video-responsive {
          overflow: hidden;
          padding-bottom: 56.25%;
          position: relative;
          height: 0;
        }
        
        .video-responsive iframe {
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
        }
  
        .video-react {
          border-radius: 0;
          padding-top: 47.25% !important;
          margin-bottom: 7em;
  
          .video-react-video{
            max-height: 36.5em;
            border-radius: 0em;
            display: flex;
            justify-content: center;
          }
        }
      }
      .car-listing-container{
        //padding-top: 2em;
        @media (min-width: 407px) {
          padding-top: 3em;
        }
        @media (min-width: 470px) {
          padding-top: 4em!important;
        }
        @media (min-width: 510px) {
          padding-top: 5em!important;
        }
        @media (min-width: 540px) {
          padding-top: 6em!important;
        }
        @media (min-width: 991px) {
          padding-top: 7em!important;
        }
        .icon-container{
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 1.5em;
  
          @media (min-width: 991px) {
            padding-bottom: 2em !important;
          }
  
          label{
            margin-left: 0.5em;
            font-size: 20px;
            font-family: "inter-semi-bold";
            color: #24262D;
          }
        }
      }
  
      @media (min-width: 991px) {
        // margin-top: 10em !important;
        .video-banner-container {
          margin-top: 1em;
          width: 37.125em;
          height: 16em;
  
          .video-responsive {
            overflow: hidden;
            padding-bottom: 56.25%;
            position: relative;
            height: 0;
            border-radius: 0.5em;
          }
          
          .video-responsive iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
          }
  
          .video-react{
            margin-top: 3em;
            border-radius: 1em;
            padding-top: 38.25% !important;
            margin-bottom: 3em;
          }
        }
        .car-listing-container{
          padding-top: 6em;
        }
      }
    }
  
    :root {
      --rsbs-backdrop-bg: rgba(0, 0, 0, 0.504);
      --rsbs-bg: #24262D;
      --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
      --rsbs-max-w: auto;
      --rsbs-ml: env(safe-area-inset-left);
      --rsbs-mr: env(safe-area-inset-right);
      --rsbs-overlay-rounded: 16px;
    }
  
    .view-360-container{
      .header-main-container{
        .section-top{
          position: relative;
          z-index: 1;
        }
        .section-bottom{
          display: none;
        }
      }
      .header-360-container{
        position: relative;
        z-index: 1;
        padding-top: 1.5em !important;
        padding-left: 2em;
        padding-right: 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FBB24B;
        background-color: transparent;
      
        .back-btn {
          overflow: unset;
          cursor: pointer;
          border: 0px;
          display: inline-block;
          position: relative;
          z-index: 1;
          border: 0px solid #000000;
          background: #FFD439;
          font-family: homoarakhn, sans-serif;
          color: #10141A;
          padding: 1em 1em 0.6em 0.6em !important;
          box-shadow: none !important;
          // font-style: italic;
          font-size: 1.5rem;
          // transform: skew(-10deg);
          @media (max-width: 991px) {
            padding: 0.8em 0.9em 0.5em 0.5em;
            font-size: 1.5rem;
          }
        }
        .back-btn:hover {
          // content: "'";
          // color: transparent;
          // position: absolute;
          // width: 100%;
          // height: 100%;
          // left: 0px;
          // top: 0px;
          // z-index: -1;
          // border-radius: 3px;
          background: #FBB24B;
          // transform: skew(-20deg);
        }
        // .back-btn::after {
        //   content: "'";
        //   color: transparent;
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        //   left: 0px;
        //   top: 0px;
        //   z-index: -1;
        //   border-radius: 3px;
        //   background: #FFD439;
        //   transform: skew(-20deg);
        //   // -webkit-transform: skew(-30deg);
        //   // -o-transform: skew(-30deg);
        //   // -moz-transform: skew(-30deg);
        //   // -ms-transform: skew(-30deg);
        // }
      
        ._label {
          &.--modal-title {
            color: #FBB24B;
            font-style: italic;
            font-size: 2.4rem;
            //font-weight: bold;
          }
      
          &.--number {
            color: #FBB24B;
            font-style: italic;
            font-size: 2.4rem;
            //font-weight: bold;
      
            span {
              color: #EC3237;
              text-transform: uppercase;
            }
          }
        }
      
        @media (min-width: 991px) {
          padding-top: 10em !important;
        }
      }
      .panoroma-container{
        z-index: 0;
        position: relative;
        top: -10em;
        width: 100%;
        .panoroma-button-row{
          position: absolute;
          bottom: 4em;
          width: 100%;
          button{
            overflow: unset !important;
            cursor: pointer;
            border: 0px;
            display: inline-block;
            position: relative;
            z-index: 1;
            border: 0px solid #000000;
            background: #FFD439;
            // background: transparent;
            font-family: homoarakhn, sans-serif;
            color: #10141A;
            padding: 1em 1em 0.8em 0.6em;
            font-style: italic;
            font-size: 1.6rem;
            // transform: skew(-10deg);
            @media (max-width: 991px) {
              padding: 0.8em 0.9em 0.5em 0.5em;
              font-size: 1.5rem;
            }
            box-shadow: none !important;
            &.--active{
              background: #FBB24B;
            }
          }
          button:hover {
            background: #FBB24B;
          }
        }
      }
    }
  
    .view-video-container{
      .modal-content{
        background-color: #242424fc !important;
        .modal-header{
          border-bottom: 2px solid #302e2eaa;
          padding: 0.5em 1em;
          svg{
            width: 3em;
            height: 3em;
          }
        }
        .modal-body{
          background-color: transparent;
          height: 44em;
          padding: 1em;
          .video-react{
            position: relative;
            overflow: hidden;
            border-radius: 1em;
            background-color: #242424fc !important;
            .video-react-video{
              max-height: unset;
  
            }
          }
        }
      }
    }
  
    .carInfo-details-information{
      ._label{
        &.--sub-title-specification{
          color: #24262D;
          font-size: 1.4rem;
          font-family: "inter-semi-bold";
        }
        &.--sub-title{
          font-family: "inter-semi-bold";
          color: #24262D;
          font-size: 1.6rem;
        }
        &.--gray-text{
          width: 100%;
          font-size: 1.2rem;
          color: #616469;
        }
        &.--white-text{
          width: 100%;
          font-size: 1.4rem;
          color: #24262D;
        }
        &.--see-more{
          cursor: pointer;
          font-size: 1.2rem;
          text-align: right;
          color: #ac4343;
          font-weight: 600;
        }
        &.--padding{
          padding: 8px 15px;
          @media (max-width: 991px) {
            padding: 8px 0px 8px 5px;
          }
        }
      }
      .carInfo-table-container {
        padding: 0.8em;
      }
      .carInfo-table-container-calculation {
        border: 0.1px solid #dddddd;
        // border-left: 0.1px solid #4A4C64;
        // border-right: 0.1px solid #4A4C64;
        // border-bottom: 0.1px solid #4A4C64;
       
        &.boder-top-left{
          border-radius: 9px 0px 0px 0px;
        }
        &.boder-top-right{
          border-radius: 0px 9px 0px 0px;
        }
        &.boder-btm-right{
          border-radius: 0px 0px 9px 0px;
        } 
        &.boder-btm-left{
          border-radius: 0px 0px 0px 9px;
        }
      }
      ._style{
        &.--wrap-1{
          @media (max-width: 991px) {
            text-wrap: nowrap;
          }
        }
      }
      .carInfo-details-information-title {
        color: #D1D5DB;
        margin-top: 1em;
        margin-bottom: 0.5em;
        padding: 0 1rem;
        font-size: 1.5rem;
      }
    }
  
    .carInfo-page {
      background-color: $background-color--dark;
      box-sizing: border-box;
      // overflow-x: hidden;
    }
  
    .carInfo-image-div {
      position: relative;
    }
  
  
    .carInfo-display{
      height: 25em;
      .carInfo-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        @media (min-width: 991px) {
          height: 35em;
        }
      }
    }
  
    .carInfo-image-container {
      max-width: 100%;
      max-height: fit-content;
      margin-top: 2rem;
    }
  
    .carInfo-image-container img {
      width: 100%;
      max-height: 100%;
      border-radius: 3px;
    }
  
    .carInfo-table {
      // display: grid;
      grid-template-columns: 33.33% 33.33% 33.33%;
      text-align: center;
      background: #F8F8F8;
      // background-color: $modalBox-color-dark;
      // border: 1px solid $border-color-grey;
      border: 1px solid #dddddd;
      border-radius: 9px;
    }
  
  
    .carInfo-table-content {
      color: #D1D5DB;
    }
  
    .carInfo-details-information-specs {
      padding: 0 0 2em 1.5em;
      margin: 0;
      color: #24262D;
      // overflow: scroll;
  
      transition: height 1s;
      li{
        font-size: 1.2rem;
        margin-bottom: 1em;
      }
    }
  
    .carInfo-details-information-specs::-webkit-scrollbar {
      display: none !important;
    }
  
    .carInfo-details-information-specs-hide{
      padding: 0 0 2em 1.5em;
      margin: 0;
      color: #24262D;
      overflow: hidden;
      transition: height 1s;
      z-index: 1;
      li{
        z-index: 0;
        font-size: 1.2rem;
        margin-bottom: 1em;
      }
    }
  
    .carInfo-carVideo{
      .carInfo-carVideo-listContainer{
        padding: 0;    
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
        .carInfo-carVideo-container{
          width: 100%;
        }
      }
    } 
  
    .carInfo-spec-container ul {
      list-style-position: inside;
    }
  
    .pageCount {
      position: absolute;
      padding: 1rem;
      font-size: 1.5rem;
      //font-weight: 500;
      color: white;
      top: 0;
      right: 0;
      z-index: 99;
  
    }
  
    .carBasic-info2 {
      // display: grid;
      // grid-template-columns: 50% 50%;
      padding: 1.5em 1.5em 0.5em 1.5em;
      border-radius: 12px 12px 0 0;
      border-top: #eb323b solid 2px;
      background-color: #fff;
      max-width: none !important;
      ._label{
        &.--agent_name{
          font-size: 1.6rem;
          color: #24262D;
          @media (min-width: 991px) {
            margin-right: -3em;
            }
        }
        &.--car-price{
          font-size: 2rem;
          color: #24262D;
          font-family:  "inter-medium";
        }
      }
      .avatar-div{
        width: 28px;
        height: 28px;
        margin: auto;
        @media (min-width: 991px) {
          margin-right: -3em;
          }
        .image-agent{
          height: 100%;
          width: 100%;
          border-radius: 50px;
          object-fit: cover;
        }
      }
      .contact-row{
        text-align: center;
        margin-top: 1em;
        .contact-col{
          display:flex ;
          justify-content: end;
          .contact_btn{
            margin-left: 0.5em;
            align-items: center;
            display: flex;
            cursor: pointer;
            padding: 0 8px;
            border-radius: 4px;
            text-align: center;
            background: none;
            backdrop-filter: blur(5px);
            border: 1px solid #4A4C64;
            // box-shadow: 0px 0px 20px 0px rgba(175, 175, 175, 0.27) inset;
            max-width: 10em;
            max-height: 3em;
            @media (min-width: 991px) {
            max-height: 3.5em;
            height: 3.5em;
            width: 10em;
            display: flex;
            align-items: center;
            justify-content: center;
            }
            &.agent{
              color: #24262D;
              background: #FFD439;
              border: none;
            }
            ._label{
              font-size: 1.2rem;
              color: #24262D;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family:  "inter-semi-bold";
              &.agent{
                color: #24262D
              }
            }
          }
        }
      
      }
      
      
    }
  
  
    .carBasic-info {
      // display: grid;
      // grid-template-columns: 50% 50%;
      padding: 1.5em 1.5em 0.5em 1.5em;
      border-radius: 12px 12px 0 0;
      border-top: #FBB24B solid 2px;
      background-color: #14161E;
      max-width: none !important; 
    }
  
    .carBasic-priceInfo {
      line-height: normal;
      display: grid;
      grid-template-rows: 30% 40% 30%;
      padding-left: 1rem;
    }
  
    .carPrice {
      color: #D1D5DB;
      font-family: "inter-regular";
      font-size: 2.4rem;
      //font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  
    .carBasic-agentInfo {
      display: grid;
      grid-template-rows: 50% 50%;
    }
  
    .agentInfo {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  
    .agentName {
      font-size: 1.6rem;
      color: #D1D5DB;
      padding: 0px 0.1rem;
      line-height: normal;
      width: fit-content;
    }
  
    .agentAvatar {
      margin: 0px;
      border-radius: 100%;
    }
  
    .agentAvatar img {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
    }
  
    .agentContact {
      display: flex;
      margin: 0 !important;
      justify-content: end;
      padding: 1em 0em;
      .button-group{
        .pi{
          font-size: 1.4rem;
          margin-right: 0.3em;
        }
        border-radius: 8px !important;
        font-size: 1.2rem;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0.2em 1.5em;
        height: 2.5em;
        width: auto;
        margin-left: 0.5em;
      }
      @media (min-width: 991px) {
        .button-group{
          .pi{
            font-size: 1.6rem;
            margin-right: 0.3em;
          }
          margin-left: 1em;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
    }
  
    .carInfo-details-container {
      @media (min-width: 991px) {
        margin-top: 1.5rem;
        padding: 0 0.8em !important;
      }
      padding: 0 !important;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  
    .carInfo-details-carName {
      color: #24262D;
      //font-weight: 700;
      font-size: 20px;
      font-family: "inter-bold";
    }
  
    .carInfo-details-location {
      color: #D1D5DB;
      padding: 1rem;
      display: flex;
  
      border-bottom: 0.5px solid $color-grey;
    }
  
  
    .secondary-header {
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      color: #313247;
      background-color: transparent;
      border-bottom: #313247 solid 2px;
    }
  
    .back-btn {
      cursor: pointer;
      font-family: "homoarakhn";
      font-size: 18px;
      //font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
  
    }
  
    .text {
      font-family: "inter-bold";
  
      color: #EC3237;
      //font-weight: 1000;
      font-style: italic;
      font-size: 18px;
    }
  
    .secondaryText {
      color: #313247 !important;
  
    }
  
    .overlay-img{
      cursor: pointer;
      background: transparent;
      height: 400px;
      width: 100%;
      position: relative;
      z-index: 100;
      &.--post{
        height: 350px;
      }
    }
  
    .overlay-img-div{
      cursor: pointer;
      width: 20em;
      position: absolute;
      left: 50%;
      top: 35%;
      z-index: 99;
      transform: translate(-50%);
        &.--agent{
          top: 55%;
        }
        &.--post{
          width: 13em;
        }
    }
  
    .carInfo-title {
      font-family: "inter-semi-bold";
      color: #24262D;
      font-size: 18px;
      //font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      margin: 1rem 0;
      padding: 1rem;
      // border-bottom: $color-gold 1px solid;
      border-bottom: #eb323b 1px solid; 
    }
  
    .carInfo-360-image {
      position: relative;
      .overlay-360{
        cursor: pointer;
        background: #0000007a;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
      }
      svg{
        cursor: pointer;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
  
      img {
        width: 100%;
        height: 100%;
      }
  
      // .zoomable-image {
      //   transition: transform 0.3s;
      //   cursor: pointer;
      // }
      
      // .zoomable-image.zoomed {
      //   transform: scale(2);
      //   z-index: 99 !important;
      //   position: relative;
      // }
    }
  
    .pnlm-container{
      background: transparent !important;
    }
  
  
    .carInfo-look-image {
      img{
        cursor: pointer;
        object-fit: cover;
        border-radius: 0.2em;
      }
      .last-image-column{
        cursor: pointer;
        .image{
          position: relative;
          .overlay{
            z-index: 7;
            top: 0;
            width:100%;
            height:100%;
            position:absolute;
            background-color:#000000a1;
            justify-content: center;
            display: flex;
            align-items: center;
          border-radius: 0.5em;
            label{
              color: #FFFFFF;
              font-size: 22px;
              font-weight: 600;
            }
          }
        }
      }
      display: grid;
      grid-template-rows: auto auto auto;
      gap: 10px;
      padding: 0 !important;
      .sc-hLQRIN, .sc-beySbM {
        gap: 8px;
        display: grid;
        .sc-eDLJxc, .sc-guDLRT {
          border: none;
          gap: 8px;
          .sc-gLLvby, .sc-hLQRIN{
            border-radius: 0.2em;
          }
        }
      }
      // .sc-beySbM {
      //   padding: 0;
      //   gap: 1em;
      //   display: grid;
      //   .sc-guDLRT, .sc-dmyDGi {
      //     border-radius: 0.5rem;
      //     overflow: hidden;
      //     border: none;
      //     gap: 1em;
      //   }
      // }
    }
  
    .last-image-column{
      cursor: pointer;
      .image{
        position: relative;
        .overlay{
          z-index: 7;
          top: 0;
          width:100%;
          height:100%;
          position:absolute;
          background-color:#000000a1;
          justify-content: center;
          display: flex;
          align-items: center;
          border-radius: 0.2em;
          label{
            color: #FFFFFF;
            font-size: 22px;
            font-weight: 600;
          }
        }
      }
    }
  
    .carInfo-carImage {
      display: grid;
      grid-template-columns: 50% 50%;
      height: fit-content;
  
    }
  
    .newCarPrice {
      color: #24262D;
      width: 100%;
      font-size: 0.9rem;
      line-height: 2em;
      @media (min-width: 991px) {
        font-size: 1.4rem;
      }
    }
  
    .askPrice {
      color: #616469;
      line-height: 1.5em;
      font-size: 1.2rem;
      line-height: 2em;
      @media (min-width: 991px) {
        font-size: 1.8rem;
      }
    }
  
    .carLocationIcon {
      display: inline;
      position: relative;
    }
  
    .carInfo-carVideo-container-text {
      color: white;
    }
  
    .samePriceRange-title {
      padding: 0.8em 0em 0.2em;
      text-align: left;
      align-items: center;
      display: flex;
      min-height: 67.17px;
      p{
        margin-bottom: 0;
        color: #24262D;
        font-family: "inter-medium";
        font-size: 1.6rem;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
      }
    }
  
    .samePriceRange-content {
      color: #616469;
      font-family: "inter-regular";
      font-size: 14px;
      letter-spacing: 0em;
      text-align: left;
  
    }
  
    .carInfo-samePriceRange-title {
      font-family: "inter-semi-bold";
      color: #24262D;
      font-size: 20px;
      //font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      margin: 1rem 0;
      padding: 1rem;
      border-bottom: #9091A0 1px solid;
    }
  
    .carImageModalBox-container {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1055;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      outline: 0;
      background-color: #090909d2;
      color: white;
      text-align: center;
  
    }
  
    .carImageModalBox-container-grid {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: 20% 60% 20%;
    }
  
    .carInfo-swiper-image-container {
      height: 100%;
      background-color: green;
    }
  
    .carImageModalBox-header {
      display: grid;
      padding: 1rem;
      grid-template-columns: 20% auto 20%;
    }
  
    .carImageModalBox-header h2 {
      text-align: center;
      line-height: 2rem;
  
    }
  
    .carImageModalBox-header-backbtn {
      font-size: 2rem;
      margin-right: auto;
  
    }
  
    .carImageSwiper {
      display: flex;
      align-items: center;
      width: 100vw;
    }
  
    .carImageSwiper img {
      max-width: 500px;
      display: block;
      margin: auto;
    }
  
    .carImageSwiper-count {
      padding: 2rem;
    }
  
    .carImage-mask {
      position: absolute;
      width: calc(100% - var(--mdb-gutter-x) * 0.5);
      margin-right: calc(var(--mdb-gutter-x) * 0.5);
      height: 100%;
      background-color: #090909ef;
      text-align: center;
      font-size: 3rem;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
    }
  
    .stock-body {
      min-height: 100vh;
      //background-color: #1E1F21;
      background-color: unset;
      // height: fit-content;
      padding-bottom: 5em;
      .option-div{
        padding: 0em;
        background-color: unset;
        transition: padding 0.5s ease-out;
      }
      .option-div.fix{
        padding-bottom: 1em;
        position: fixed;
        height: auto;
        width: 100%;
        top: 0;
        padding-top: 0.5em;
        background-color: #ffffff;
        z-index: 1001 !important;
        border-radius: 0px 0px 0px 0px;
        border-bottom: 0.05em solid #FFD439;
        
        //box-shadow: 0px 3px 4px rgba(202, 202, 202, 0.2);
        transition: padding 0.5s ease-in;
  
        @media (min-width: 991px) {
          top: 0;
          left: 0;
          z-index: 1999 !important;
          height: fit-content;
        }
      }
    }
    .car-scroll-top {
      .car-container{
        position: relative;
        .car-focus{
          position: absolute;
          top: 0;
          z-index: 9999;
          display: none;
        }
      }
    }
    .car-scroll-top:hover {
      .car-container{
        cursor: pointer;
        position: relative;
        .car-focus{
          position: absolute;
          top: 0;
          z-index: 9999;
          display: block;
        }
      }
    }
  
    .carList-container {
      position: relative;
      font-size: 1rem;
      @media (min-width: 1440px) {
        max-width: none !important;
        padding: 0% 5rem !important;
      }
    }
  
    .carList-title {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 1rem;
      display: flex;
      flex-direction: row;
    }
  
    .title-text {
      background-color: #ffffff;
      z-index: 99;
      text-align: center;
      margin: auto;
      width: auto !important;
      color: #9091A0;
      padding: 0 5px;
      white-space: nowrap;
      font-size: 1.6rem;
    }
  
    .decorativeLine {
      width: 100%;
      height: 1px;
      background-color: #9091A0;
      position: relative;
      top: 50%;
      //z-index: 1;
    }
  
    .carListing-container {
      margin: 1rem 0;
      // padding: 1em 0;
      .carListing{
        cursor: pointer;
        margin: 1em 0 0 0;
        justify-content: center;
      }
    }
    .carListing-img-container{
      max-width: 9em !important;
      padding: 0em !important;
    }
    .carListing-details-container{
      background-color: transparent;
      display: flex !important;
      align-items: center !important;
      padding: 0% !important;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    .carListing-details{
      width: 100%;
      padding: 0.5em 0.5em !important;
      height: 100%;
    }
    .carListing-name{
      cursor: pointer;
      padding-top: 0em;
      height: 2.5em;
      font-size: 12px !important;
      //padding-top: 1em;
    }
    .carListing-desc {
      cursor: pointer;
      font-size: 12px !important;
    }
    .carListing-border {
      height: 20px;
      border-left: 3px solid #FFD439;
      margin-right: 4px;
      gap: 10px;
      // &.--last {
      //   margin-right: 3.5px;
      // }
    }
    .carListing-price {
      position: relative;
      display: inline-block;
      padding: 10px 0;
      background-color: transparent;
      color: #000000;
      font-size: 12px !important;
      border: none;
      cursor: pointer;
      overflow: hidden;
      width: 180px;
      height: 20px;
      text-align: center;
      transition: width 0.5s;
      label {
        white-space: nowrap;
        z-index: 99;
        color: #000;
        position: relative;
        padding: 0.5em 4em 0em 0.3em;
      }
    }
  
    .carListing-price::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0; /* Updated from "left: 0" */
      width: 100%;
      height: 100%;
      background: #FFD439;
      transform-origin: bottom left; /* Updated from "bottom right" */
      transform: skewX(30deg); /* Updated from "-20deg" */
      transition: transform 0.5s;
    }
  
  
    @media (min-width : 991px) {
      .carListing-container {
        //min-height: 200px;
        flex-direction: column;
      }
      .carListing-img-container{
        // height: 150px !important;
        // width: 220.5px !important;
        //padding: 1rem !important;
        padding: 0em !important;
      }
      .carListing-img{
        height: 140px !important;
        width: 262px !important;
        // height: 140px !important;
        // width: 205.8px !important;
      }
      .carListing-img img{
        //width: 100%;
        //height: 100%;
      }
      .carListing{
        //height: 100%;
        //background: #0B0D13;
        //border-radius: 6px;
      }
      .carListing-details-container{
        background-color: transparent;
        display: flex !important;
        align-items: start !important;
        padding: 0% !important;
        width: 262px !important;
      }
      .carListing-details{
        width: 100%;
        padding: 0.8em 0.4em !important;
        //margin: auto;
        //display: grid;
        //gap: 2rem;
        //height: 80%;
      }
      .carListing-details div{
        //margin: auto 0;
      }
      .carListing-name{
        padding-top: 0em;
        height: 2.5em;
        font-size: 1.6rem !important;
        //padding-top: 1em;
      }
      .carListing-desc{
        font-size: 1.4rem !important;
        padding: 0.8em 0em 0em 0em;
        &.--second-row{
          padding-top: 0.3em;
        }
      }
      // .carListing-price {
      //   content: "";
      //   position: relative;
      //   bottom: -10px;
      //   width: 10em;
      //   height: 3em;
      //   background-color: #0056b3;
      //   transform: skewY(3deg);
      //   z-index: 0;
      //   transform-origin: top right;
      // }
      // .carListing-price:before {
      //   left: 0;
      //   transform-origin: top left;
      // }
      
      // .carListing-price:after {
      //   right: 0;
      //   transform-origin: top right;
      // }
      .carListing-border {
        height: 24px;
        border-left: 3px solid #FFD439;
        margin-right: 4px;
        gap: 10px;
        // &.--last {
        //   margin-right: 3.5px;
        // }
      }
      .carListing-price {
        position: relative;
        display: inline-block;
        padding: 10px 0;
        background-color: transparent;
        color: #000;
        font-size: 16px !important;
        border: none;
        cursor: pointer;
        overflow: hidden;
        width: 180px;
        height: 24px;
        text-align: center;
        transition: width 0.5s;
        label {
          z-index: 99;
          color: #000;
          position: relative;
          padding: 0.5em 0em 0.3em 0.3em;
        }
      }
      
      .carListing-price::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0; /* Updated from "left: 0" */
        width: 100%;
        height: 100%;
        background: #FFD439;
        transform-origin: bottom left; /* Updated from "bottom right" */
        transform: skewX(30deg); /* Updated from "-20deg" */
        transition: transform 0.5s;
      }
    }
    // @media (min-width : 1440px) {
    //   .carListing-img{
    //     width: 80% !important;
    //     height: 40em !important;
  
    //     &.--dstock{
    //       width: 100% !important;
    //       height: 100% !important;
    //     }
    //   }
    // }
    .rhombus {
      display: none;
      color: #9091A0;
      font-size: 8px;
    }
  
    .rhombusOne {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-43%) translateX(-50%);
    }
  
    .rhombusTwo {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-43%) translateX(50%);
  
    }
  
    .gezeWJ {
      border-radius: 0px !important;
    }
    .stockListing-container {
      margin-top: 3em;
      // padding: 0 5rem;
      //padding-bottom: 10em;
    }
  
    .carList-title {
      width: 70% !important;
      @media (max-width: 599px) {
        width: 95% !important;
      }
    }
  
    .carList-container-grid2{
      margin: 0;
      .carListing-img{
        width: 147px;
        height: 100px;
        flex-shrink: 0;
        .stock-image{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .carListing-condition2{
        display: flex;
        padding: 6px 10px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        position: absolute;
        bottom: 0;
        width: 100%;
        backdrop-filter: blur(3px);
        background: linear-gradient(138deg, #0B0D13 17.48%, rgba(255, 255, 255, 0.00) 100%);
        label{
          font-size: 1.2rem;
          font-family: "inter-medium";
          color: #F8F8F8;
          text-transform: uppercase;
        }
        &.active{
      background: linear-gradient(138deg, #EB5F5D 17.48%, rgba(255, 255, 255, 0.00) 100%);
      label {
        // font-size: 1.5rem;
        font-family: "inter-semi-bold";
      }
        }
      }
      ._label{
        &.--modal-name{
          align-self: stretch;
          color: #24262D;
          font-size: 1.4rem;
          line-height: 1.2em;
          overflow: hidden;
          margin: auto;
          -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
         display: -webkit-box!important;
        }
        &.--car-price{
          color: #24262D;
          font-size: 1.2rem;
          font-family: "inter-medium";
          padding: 0.1em 0em 0em 0.2em;
          z-index: 99;
          background: #FFD439;
          height: 17px;
          min-width: 120px;
          span{
          font-size: 1rem;
          }
        }
      }
      .modal-row{
        max-height: 34px;
        min-height: 34px;
      }
  
      .carListing-border {
        height: 17px;
        border-left: 2px solid #FFD439;
        margin-right: 4px;
      }
      .carListing-border2 {
        height: 17px;
        border-left: 17px solid #FFD439;
        border-top: 17px solid transparent;
      }
  
      .car-info-row{
        padding: 1px 0px 0px 5px;
        margin: 6px 0em 0em 0em;
        border-radius: 8px;
        background: #FAFAFA;
        border: 0.5px solid #4a4c4f;
        .info-col{
          padding: 0;
         display: flex;
         align-items: center;
         line-height: 1.1em;
        }
        .icon{
          color: #616469;
          width: 10px;
          height: 10px;
        }
        ._label{
          &.--info{
            color: #616469;
            font-size: 1rem;
            overflow: hidden;
            -webkit-line-clamp: 2;
             -webkit-box-orient: vertical;
             display: -webkit-box !important;
          }
          &.--info2{
            color: #616469;
            font-size: 1rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        }
      }
  
    }
  
    @media (min-width: 991px) {
      .carList-container-grid2 {
        margin: 0em !important;
        display: flex;
        grid-template-columns: 33.33% 33.33% 33.33%;
      }
      .carListing-img{
        width: 358px !important;
        height: 214px !important;
        border-radius: 6px;
        .stock-image{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .card-stock{
        // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 1px 1px 4px 0px rgba(0, 0, 0, 0.1), 6px 5px 8px 0px rgba(0, 0, 0, 0.09), 13px 11px 10px 0px rgba(0, 0, 0, 0.05), 23px 20px 12px 0px rgba(0, 0, 0, 0.01), 37px 32px 14px 0px rgba(0, 0, 0, 0);
        padding: 0 !important;
        height: 370px;
        background: #FFFFFF;
        border-radius: 14px;
        max-width: 358px !important;
        max-height: 370px !important;
        margin: 1.3em 0em;
        .swiper-button-prev{
          color: white !important;
        }
        .swiper-button-prev::after{
          font-size: 2rem !important;
        }
        .swiper-button-next{
          color: white !important;
        }
        .swiper-button-next::after{
          font-size: 2rem !important;
        }
  
        .swiper-pagination-horizontal{
          color: #FBB24B;
          font-family: "inter-medium";
        }
        .swiper-pagination-bullet-active{
          background: #FBB24B;
        }
      }
      .carListing-border {
        height: 25px !important;
        border-left: 5px solid #FFD439 !important;
        margin-right: 3px;
      }
      .carListing-border2 {
        height: 25px !important;
        border-left: 25px solid #FFD439 !important;
        border-top: 25px solid transparent !important;
      }
      ._label{
        &.--modal-name-desktop{
          // padding: 0;
          align-self: stretch;
          font-family: "inter-medium";
          color: #24262D;
          font-size: 1.8rem;
          line-height: 1.2em;
          // word-break: break-all;
          overflow: hidden;
          -webkit-line-clamp: 2;
         -webkit-box-orient: vertical; 
         display: -webkit-box!important;
        }
        &.--car-price2{
          color: #24262D;
          font-size: 1.6rem;
          font-family: "inter-medium";
          padding: 0.1em 0em 0em 0.4em;
          z-index: 2;
          background: #FFD439;
          height: 25px;
          min-width: 220px;
          span{
          font-size: 1.2rem;
          }
        }
        &.--info-desktop{
          font-size: 1.6rem;
          color: #616469;
          font-family: "inter-medium";
        }
      }
  
      .car-info-row-desktop{
        padding: 1px 0px 0px 3px;
        margin: 0.8em 0em 0em 0em !important;
        border-radius: 8px;
        // background: #FAFAFA;
        // border: 0.5px solid #C8C9CB;
        background: #24262D;
        border: 0.5px solid #4a4c4f;
        .info-col{
          padding: 0;
         display: flex;
         align-items: center;
         line-height: 1.1em;
        }
        .icon-desktop{
          color: #C8C9CB;
          width: 15px;
          height: 15px;
        }
        ._label{
          &.--info-desktop{
            color: #C8C9CB;
            font-size: 1.1rem;
          }
          &.--info2-desktop{
            color: #C8C9CB;
            font-size: 1.1rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        }
      }
      .modal-row-desktop{
        // margin: 0.4em 0em 0em 0em !important;
      }
  
    }
  
    @media (min-width: 991px) {
      .carList-container-grid {
        margin: 0em !important;
        display: flex;
        grid-template-columns: 33.33% 33.33% 33.33%;
      }
  
      .rhombus {
        display: flex;
        align-items: center;
      }
  
      .stockHeader {
        width: 100%;
        display: flex;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        // padding: 0% 20rem;
        max-width: 1255px;
        margin-top: 11rem;
  
      }
  
      .searchButton {
        border-radius: 0px 5px 5px 0px;
      }
  
      .gezeWJ {
        border-radius: 5px 0px 0px 5px !important;
  
      }
  
    }
    
  
    .carListing {
      // display: grid;
      // grid-template-columns: auto 50%;
      @media(min-width: 991px) {
        grid-template-columns: none;
      }
      .carListing-img {
        border-radius: 0em;
        overflow: hidden;
        position: relative;
        width: 147px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          object-fit: cover;
        }
        .booking-icon{
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          // top: 50%;
          // right: 50%;
          // -webkit-transform: translate(-10%,-10%);
          // transform: translate(-10%,-10%);
          p{
            color: #f6f6f6;
            background: #131111;
            width: auto;
            text-decoration: underline;
            font-size: 4rem;
            padding: 0em 0.4em;
            transform: rotate(350deg);
          }
          ._p{
            font-size: 2.2rem;
  
          }
        }
      }
      .carListing-condition {
        padding: 0.5rem 1rem;
        background-color: transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 1.4rem;
        color: #D1D5DB;;
        text-transform: uppercase;
        background: linear-gradient(92.86deg, #0B0D13 20.87%, rgba(255, 255, 255, 0) 100.62%);
        backdrop-filter: blur(3px);
      }
      .carListing-details {
        // display: grid;
        grid-template-rows: auto auto auto;
        padding: 0em 0em;
        .carListing-name {
          //padding-top: 0.5em;
          height: 2.5em;
          font-size: 1.5rem;
          line-height: 1.2em;
          color: #D1D5DB;
        }
        .carListing-desc{
          //padding-top: 0.5em 0em;
          font-size: 1.2rem;
          color: #9091A0;
          &.--second-row{
            padding-bottom: 0.5em;
          }
        }
        .carListing-price{
          padding-top: 0.5em;
          font-size: 1.2rem;
          color: #9091A0;
        }
      }
      // .carListing-details div {
      //   width: 100%;
      // }
    }
  
    .searchButton {
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: grey;
      max-height: 100%;
      background-color: #fff;
    }
  
    .jifbFL {
      height: 40px !important;
    }
  
    .searchBar {
      display: grid;
      grid-template-columns: 90% 10%;
  
    }
  
    .brandlist-modal-container {
      color: rgba(209, 213, 219, 1);
      padding: 0% !important;
  
      // position: absolute;
      // top: 100%;
      // left: 0;
  
      .modal-dialog{
        justify-content: center;
      }
  
      .modal-backdrop {
        opacity: .6 !important;
        // -webkit-backdrop-filter: blur(2px) !important;
        // backdrop-filter: blur(2px) !important;
      }
  
      // .modal-dialog{
      //   position: absolute;
      //   top: 30%;
      //   left: 32%;
      // }
      .modal-content{
        min-height: 60;
        height: auto;
        width: 30em;
        background-color: unset !important;
        z-index: 3 !important;
        //min-width: 450px;
  
        @media (max-width: 991px) {
          min-height: 30em;
          // width: 35em;
          width: 100vw;
        }
      }
  
      &.--choose-brand {
        .modal-content{
          height: auto;
          background-color: unset !important;
          z-index: 3 !important;
          min-width: 450px;
        } 
      }
    }
    
    .modal-backdrop {
      z-index: 2002 !important
    }
  
    .modal {
      z-index: 9999 !important;
    }
  
    .brandlist-modal-header {
      background-color: #14161E;
      border: none !important;
      padding: 2rem !important;
      border-top-left-radius: 1.4rem !important;
      border-top-right-radius: 1.4em !important;
      h2{
        font-size: 20px;
      }
  
      &.--filter{
        display: block;
        padding: 2rem !important;
  
        ._label {
          &.--clear {
            font-family: "inter-medium";
            text-decoration: underline;
            color: #fbb24b;
            font-size: 14px;
          }
          &.--filter {
            font-size: 16px;
          }
        }
      }
    }
  
    .brandlist-ul{
      padding: 0 !important;
      border-bottom: 1px solid rgba(255, 212, 57, 0.2);
    }
  
    .brandlist-ul:last-child{
      padding: 0 !important;
      border-bottom: none;
    }
  
    .brandlist-div-container {
      position: absolute;
      // top: 0;
      // left: 0;
      width: 20em !important;
      //background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
      // display: flex;
      // align-items: center;
      // justify-content: center;
      z-index: 2100 !important;
      padding: 0 !important;
  
      &.--fixed {
        position: fixed;
        // top: 52%;
        // transform: translate(0px, -50%);
      }
    }
  
    .brandlist-div-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      backdrop-filter: blur(2px) !important;
      background: #00000042 !important;
      z-index: 2001 !important;
      //transition ?
    }
  
    .brandlist-div-body {
      position: relative;
      color: #d1d5db;
      background-color: #FFFFFF;
      padding: 1rem !important;
      height: 30em;
      width: 22em;
      border-radius: 1.4rem;
  
      @media(max-width: 991px) {
        width: 12em;
        height: 22em;
      }
  
      &.--slider{
        height: 15em;
      }
  
      &.--sort {
        height: 24em;
      }
  
      h2{
        font-style: italic;
        font-size: 14px;
      }
    }
  
    .brandlist-div-footer {
      position: absolute;
      width: 100%;
      display: flex;
      bottom: 0;
      left: 0;
      color: #24252D;
      padding: 1.3em 2em;
      justify-content: space-between !important;
      border-top: none !important;
      background-color: #FFFFFF;
      border-bottom-left-radius: 1.4rem;
      border-bottom-right-radius: 1.4rem;
    }
  
    .brandlist-div-content {
      overflow-y: auto;
      height: 100%;
      padding-bottom: 5em;
    }
  
    .brandlist-div-content::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #c2c2c2;
    }
  
    .brandlist-div-content::-webkit-scrollbar
    {
      width: 5px;
      border-radius: 10px;
      background-color: #F5F5F5;
    }
  
    .brandlist-div-content::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
  
  
    .brandlist-modal-body {
      background-color: #14161E;
      padding: 0rem 2rem !important;
      height: auto;
      overflow-y: auto;
      border-bottom-left-radius: 1.4rem !important;
      border-bottom-right-radius: 1.4rem !important;
  
      &.--footer{
        padding: 0rem !important;
        border-radius: 0 !important;
        // border-top-left-radius: 1.4rem !important;
        // border-top-right-radius: 1.4rem !important;
        .two-column-grid{
          cursor: pointer;
          display: grid;
          height: 100%;
          grid-template-columns: 30% 70%;
          padding: 0em 1em 0.5em 0em;
        } 
  
        .filter-menu{
          border-right: 1px solid rgba(255, 212, 57, 0.2);
        }
  
        .filter-block{
            // background: rgb(255, 242, 235);
            display: flex;
            align-items: center;  
            padding: 0em 2em;
            height: 70px;
            margin: 0px;
            font-size: 12px;
  
            &.--active {
              backdrop-filter: blur(4px);
              background: rgba(251, 235, 110, 0.5) !important;
              color: #fafafa;
              border-left: 3px solid #fbb24b;;
            }
        }
  
        .filter-content{
          padding: 0em 1em;
        }
      }
    
      .year-picker-container {
        margin: 3em;
        display: flex;
        justify-content: center;
        .react-datepicker {
          width: 20em;
          border: none;
          font-size: 16px;
          border-radius: 1.5rem !important;
          background: #14161E;
          color: #d1d5db;
  
          @media(max-width: 400px) {
            width: 16em;
          }
          .react-datepicker__navigation {
            top: 9px;
          }
          .react-datepicker__year--container {
            border: none !important;
            font-size: 16px;
            .react-datepicker__header  {
              background: transparent;
              //#485063;
              /* height: 4em; */
              border-top-left-radius: 0.8em;
              border-top-right-radius: 0.8em;
              border-bottom: none;
              font-size: 16px;
              color: #D1D5DB;
              font-weight: unset;
              font-family: 'inter-regular';
            }
            .react-datepicker__year  {
              background: transparent;
              .react-datepicker__year-wrapper {
                justify-content: center;
                display: flex;
                width: auto;
                margin: 1.5em;
                max-width: 260px;
                .react-datepicker__year-text {
                  display: inline-block;
                  width: 3em;
                  margin: 1em;
                  font-weight: unset;
                  font-family: 'inter-regular';
                }
                .react-datepicker__year-text:hover {
                  background-color: rgb(51, 51, 51);
                }
                .react-datepicker__year-text--selected {
                  background-color: #dbdbdb;
                  color: #000;
                  width: 3em;
                }
              }
            }
          }
        }
      }
  
      h2{
        font-size: 14px;
      }
  
      @media (min-width : 520px ) {
        min-height: auto;
      }
    }
  
    .brandlist-modal-body::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #c2c2c2;
    }
  
    .brandlist-modal-body::-webkit-scrollbar
    {
      width: 5px;
      border-radius: 10px;
      background-color: #F5F5F5;
    }
  
    .brandlist-modal-body::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
  
    .brandlist-modal-footer {
      justify-content: space-between !important;
      border-top: none !important;
      background-color: #14161E !important;
      border-bottom-left-radius: 1.4rem !important;
      border-bottom-right-radius: 1.4em !important;
  
      font-size: 12px;
      @media (max-width : 600px) {
        padding: 1.5em !important;
      }
    }
  
    .brandlist-header-closeBtn {
      cursor: pointer;
      //margin-left: auto;
      font-size: 1.8rem;
      padding: 0 1rem;
  
      &.--filter {
        padding: 0rem !important;
      }
    }
  
    .brandlist-listing-grid {
      display: grid;
      grid-template-columns: 20% 80%;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 1rem;
    }
  
  
    .no-available-listing{
      font-size: 18px;
      color: #7a7e86;
      font-style: italic;
      text-transform: none;
      text-align: center;
  
      @media (max-width : 991px ) {
        font-size: 14px;
      }
  
    }
  
    .brandlist-listing {
      display: flex;
      justify-content: start;
      align-items: center;
      position: relative;
      border-radius: 0rem;
  
        &.--grid{
          cursor: pointer;
          display: grid;
          height: fit-content;
          grid-template-columns: 20% 70% 10%;
          border-bottom: none;
          padding: 0em 1em 0.5em 1em;
  
          @media(max-width: 991px) {
            grid-template-columns: 20% 70% 10%;
            height: fit-content;
            padding: 0em 0.2em;
  
            p {
              font-size: 12px !important;
            }
          }
  
          @media(max-width: 600px) {
            grid-template-columns: 20% 70% 10%;
            height: 5em;
  
            p {
              font-size: 12px !important;
            }
          }
        }
  
        &.--brand-grid{
          cursor: pointer;
          display: grid;
          height: fit-content;
          grid-template-columns: 80% 20%;
          border-bottom: 1px solid rgba(255, 212, 57, 0.2);
          padding: 0.5em;
          
          @media(max-width: 991px) {
            grid-template-columns: 10% 90%;
            height: fit-content;
            padding: 0.5em;
            border-bottom: 1px solid rgba(255, 212, 57, 0.2);
  
            p {
              font-size: 12px !important;
            }
          }
          
          // @media(max-width: 600px) {
          //   grid-template-columns: 30% 70%;
          //   height: 5em;
      
          //   p {
          //     font-size: 12px !important;
          //   }
          // }
        }
  
        &.--sort-grid{
          display: grid;
          height: fit-content;
          grid-template-columns: 40% 60%;
          border-bottom: 1px solid rgba(255, 212, 57, 0.2);
          padding: 0.5em;
          
          @media(max-width: 991px) {
            grid-template-columns: 50% 50%;
            height: fit;
            padding: 0em 0.2em;
            font-size: 14px !important;
  
            p {
              font-size: 14px !important;
            }
          }
        }
  
        &.--sort-grid:last-child{
          border-bottom: none !important;
        }
        
        &.--choose-brand-grid{
          cursor: pointer;
          display: grid;
          grid-template-columns: 20% 80%;
          padding: 0em 1em;
        }
  
      .mobile-icon-div{
        width: auto;
      }
      // background-color: #577DE5;
  
      &.--search{
        border-radius: 0rem;
        height: 3em;
        width: 99%;
        border-bottom: 0.1em solid #B8BBC4;
  
        .icon-div{
          width: 3.5em;
          height: 3.5em;
          //background-color: #81818191;
          //border-radius: 100%;
          //padding-bottom: 1em; 
          margin-top: -0.3em;
          margin-left: -1em;
        }
  
        .icon-div img{
          width: 100%;
          height: 100%;
        }
      }
    }
  
    .brandlist-listing .brandlist-img-div{
      width: 100%;
      height: 100%;
      // background-color: white;
    }
    // .search-div {
    //   margin-left: 0.1em;
    //   border-top: 0.1em solid #bdbdbd;
    //   width: 98%;
    // }
    .brandlist-listing p {
      display: flex;
      align-items: center;
      padding: 0.5rem 2rem 0rem 2rem;
      height: 3em;
      width: 100%;
      font-family: "inter-regular";
      color: #24252D;
      font-size: 1.6rem;
      //font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      text-overflow: ellipsis;
      margin-bottom: 0em !important;
    }
    // .brandlist-listing p:hover {
    //   display: flex;
    //   align-items: center;
    //   padding: 0.5rem 2rem 0rem 2rem;
    //   height: 4em;
    //   font-family: "inter-regular";
    //   font-size: 1.6rem;
    //   //font-weight: 500;
    //   line-height: 17px;
    //   letter-spacing: 0em;
    //   text-align: left;
    //   margin-bottom: 0em !important;
    // }
    // .brandlist-listing:hover{
    //   // background-color: #20242e;
    //   // transition: 0.5s ease-in;
    //   // border-radius: 1rem;
    //   //   &.--search{
    //   //     width: 99%;
    //   //     background-color: rgba(185, 184, 171, 0.25);
    //   //   }
    // }
    .brandlist-listing img {
      padding: 0rem;
    }
  
    .brandlist-modal-body h2 {
      font-style: italic;
    }
    .brandlist-modal-body ul{
      padding: 0%;
    }
    .selectedIcon{
      position: absolute;
      top: 0;
      right: 0;
      font-size: 2rem;
      padding: 1.5rem 1rem;
      color: #d1d5db;
  
      @media(max-width: 991px) {
        padding: 1rem;
      }
    }
    .model-row { 
      display: grid;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      min-width: 300px;
      grid-template-columns: 1fr 1fr 1fr;
  
      @media(max-width: 991px) {
        padding: 1em 0em; 
        min-width: 320px;
      }
  
      @media(max-width: 500px) {
        padding: 1em 0em; 
        min-width: 0px;
        grid-template-columns: 1fr 1fr;
      }
    }
    .model-div {
      width: fit-content;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      backdrop-filter: blur(4px);
      // background: rgba(224, 224, 224, 0.1);
      background: #F8F8F8;
      color: #24252D;
      border-radius: 8px;
      font-size: 12px;
      padding: 1em;
      max-width: 7.5em;
      min-width: 7.5em;
      text-align: center;
      cursor: pointer;
  
      &.--active {
        font-family: "inter-medium";
        backdrop-filter: blur(4px);
        // background: hsl(46.97deg, 100%, 94.33%)!important;
        background: #dc6969;
        color: white;
      }
  
      @media(max-width: 991px) {
        max-width: 9.2em;
        min-width: 9.2em;
      }
    }
    .model-div:hover {
      // background: rgba(224, 224, 224, 0.3) ;
      @media(max-width: 991px) {
          // background:rgba(224, 224, 224, 0.1);
      }
      
    }
    .tag-row {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;
      min-width: 275px;
      height: 4em;
      align-items: center;
      padding: 0.98em 0.3em;
      height: fit-content !important;
  
      label {
        color: #9091a0;
        text-decoration: underline;
        font-size: 14px;
        font-family: "inter-medium";
      }
    }
    .tag-row-scroll {
      width: 87%;
      overflow-x: scroll;
  
      @media(max-width: 991px) {
        width: 96%;
      }
    }
    // .tag-row-scroll::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   border-radius: 10px;
    //   height: 0.5em !important;
    //   background-color: #c2c2c2;
    // }
    
    // .tag-row-scroll::-webkit-scrollbar {
    //   width: 2px; /* Adjust the width for the scrollbar track */
    //   border-radius: 10px;
    //   height: 0.5em !important;
    //   background-color: #f5f5f5;
    // }
    
    // .tag-row-scroll::-webkit-scrollbar-thumb {
    //   width: 2px; /* Adjust the width for the scrollbar thumb */
    //   border-radius: 10px;
    //   height: 0.5em !important;
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   background-color: #555;
    // }
  
    .tag-row-scroll::-webkit-scrollbar {
      display: none !important;
    }
  
    .tag-div {
      display: flex;
      align-items: center;
      width: fit-content;
      height: 38px;
      white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      backdrop-filter: blur(4px);
      // background: rgba(97,97,97,.3)!important;
      background: #dc6969;
      color: white; //#ffd439
      border-radius: 8px;
      font-size: 14px;
      padding: 0.5em 1em;
  
      @media (max-width: 991px ) {
        font-size: 12px;
      }
    }
    .carInfo-mainContainer {
      background-color: unset;
      // padding-bottom: 30em;
      min-height: 100vh;
      position: relative;
  
      .carInfo-swiper-container img{
        object-fit: cover;    
      }
  
      .carInfo-swiper-container div{
        display: flex;
        justify-content: center;
        
      }
      
      @media (min-width : 768px ) {
        .carInfo-swiper-container{
          .carInfo-display{
            height: 400px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      @media (min-width : 992px ) {
        .carInfo-swiper-container{
          .carInfo-display{
            height: 450px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      @media (min-width : 1200px ) {
        .carInfo-swiper-container{
        .carInfo-display{
          height: 500px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        }
          
      }
    }
  
    .carInfo-header-container {
      display: none;
    }
  
    .carInfo-details-col {
      background-color: #ffffff;
      padding: 1em 1.5em 1.5em 1.5em;
      border-radius: 0;
      @media (min-width :991px) {
        border-radius: 1.6rem;
      }
      .vicit-location-div{
        display: flex;
        height: 18px;
        margin-top: 1em;
        padding-left: 1em;
        align-items: center;
        ._label{
          font-size: 1.4rem;
          align-items: center;
          display:flex ;
          color: #24262D;
          .stock{
            color: #24262D;
            padding: 0px 8px;
            border-radius: 3px;
            background : #FFD439
          }
        }
      }
    }
  
    .carInfo-details-tag {
      width: auto;
      margin-right: 1.5em;
      text-transform: uppercase;
      font-size: 11px;
      padding: 0.3em 0.5em;
      //font-weight: bold;
      color: #0B0D13;;
      border: 1px solid #FFD439;
      box-shadow: inset 0px 0px 20px rgba(175, 175, 175, 0.27);
      //backdrop-filter: blur(5px);
      /* Note: backdrop-filter has minimal browser support */
  
      border-radius: 6px;
      background: linear-gradient(261.62deg, rgba(255, 149, 0, 0.47) 53.05%, rgba(135, 135, 135, 0.1363) 171.68%), #FFD439;
    }
    
    .darkTheme-btn{
      background-color: #24262D !important;   
      //padding: 0em 0.8em !important;
      margin: 0.5em;
      color: #D1D5DB;
      text-transform: capitalize !important;
      border-radius: 0.5em !important;
      box-shadow:  0px 2px 5px rgba(0, 0, 0, 0.5) !important;
  
      @media (min-width :600px) {
        margin: 1em;
        //padding: 0.8em 1.5em !important;
      }
      
  
      // &.--filter{
      //   margin: 1em;
      // }
      &.--clear{
        padding: 0.7em 1.5em !important;
      }
      &.--fix{
        height: 3.5em;
        padding: 0.9em 1.5em 1em 1.5em !important;
        margin: 0em 0.5em !important;
      }
    }
  
    .btnList-div{
      max-width: 1000px !important;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 0.5em 5.35em !important;
      @media (min-width :991px) {
        //margin: 0em 7em !important;
        width: 100% !important;
      }
      @media (max-width :991px) {
        //margin: 0em 7em !important;
        padding: 0em 5em 1em 5em  !important;
      }
      @media (min-width: 600px) and (max-width: 700px) {
        //margin: 0em 7em !important;
        padding: 0em 2em 1em 2rem !important;
      }
    }
    .btnList-div  .btn-primary{
      box-shadow: none;
    }
  
    .btnList-div .darkTheme-btn:last-of-type{
      //margin-left: auto;
    }
  
    @media (max-width : 500px) {
    
      .btnList-div{
        padding: 0rem 0.5rem 1rem 0.5rem !important;
      }
    }
  
    .samePriceRange-image-container {
      max-width: 100%;
      margin-top: 2rem;
    }
    .filter-btn{    
      width: 192px;
      height: 38px;
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: center;
      text-align: left;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20) !important;
      @media (max-width : 991px) {
        width: 100% !important;
        margin: 6px !important;
      }
  
      // @media (max-width : 600px) {
      //   width: 100% !important;
      //   margin: 6px !important;
      // }
  
      margin-left: 0%;
      background-color: #FFFFFF !important;
      // box-shadow: 0px 4px 4px 0px #000 !important;
      color: #24262D !important;
      // border: 0.15em solid #4A4C64 !important;
      //box-shadow: 0px 2px 4px #4e4e4e !important;
      &.--selected{
        width: 192px;
        box-shadow: none !important;
        color: rgba(214, 160, 85, 1) !important;
        box-shadow: 0px 4px 4px 0px #000 !important;
        //background-color: #b8bcc50a !important;
        //border: 0.15em solid #303135 !important;
  
        // @media (max-width : 991px) {
        //   width: max-content !important;
        //   margin: 6px !important;
        // }
      
        @media (max-width : 991px) {
          width: 100% !important;
          margin: 6px !important;
          .ellipsis-word {
            white-space: nowrap; 
            width: fit-content; 
            overflow: hidden;
            text-overflow: ellipsis; 
          }
        }
  
        @media (max-width : 500px) {
          width: 100% !important;
          margin: 6px !important;
        }
      }
      &.--normal{
        width: auto !important;
        padding: 0em 1em;
      }
      &.--disabled{
        // background: #1d1f24!important;
        // color: #494d59!important;
        // cursor: not-allowed;
        background: #191b25 !important;
        color: #5d6375 !important;
        cursor: not-allowed;
      }
      &.--sort{
        width: 103px;
        background: #FFFFFF !important;
        color: #0b0d13 !important;
      }
      &.--sort-icon{
        background: #d1d5db !important;
        color: #0b0d13 !important;
        margin: 8px !important;
        width: fit-content !important;
      }
    }
    .filter-btn:hover:not(.--sort) {
      // background-color: #dc6969d1 !important;
      font-family: "inter-medium";
    }
  
    .reset-btn{    
      cursor: pointer;
      width: auto;
      height: 38px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      background-color:#ffd439 !important;
      color: #000 !important;
      border-radius: 6px;
      text-transform: capitalize !important;
      font-size: 15px;
      border-radius: 6px;
      margin: 17px;
  
      svg{
        width: 20px;
        height: 20px;
        color: rgba(16, 20, 26, 1);
  
        @media (max-width : 991px) {
          width: 18px;
          height: 18px;
        }
      }
  
      @media (max-width : 991px) {
        font-size: 12px;
        margin: 9px 6px 6px 6px;
        // padding: 0em 0.5em !important;
      }
  
      @media (max-width : 600px) {
        width: 90% !important;
        font-size: 12px;
        margin: 9px 6px 6px 6px;
        // padding: 0em 0.5em !important;
      }
  
      &.--all{
        width: fit-content !important;
        height: 38px;
        justify-content: end;
        background: #ac4343 !important;
        color: white !important;
        margin: 0px;
        padding: 0em 1em !important;
  
        @media (max-width : 991px) {
          margin: 0.8em 2em;
          justify-content: center;
          width: 100% !important;
        }
      }
    }
  
    .brand-btn{
      width: 192px;
      height: 38px;
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: center;
      text-align: left;
  
      @media (max-width : 991px) {
        width: 135px !important;
        margin: 6px !important;
      }
  
      @media (max-width : 600px) {
        width: 100% !important;
        margin: 6px !important;
      }
  
      color: #D1D5DB !important;
      //border: 0.15em solid #303135 !important;
      //box-shadow: 0px 2px 4px #4e4e4e !important;
      &.--selected{
        width: 192px;
        background: #DC6969 !important;
        color: white !important;
        // box-shadow: 0px 4px 4px 0px #000 !important;
        // border: 0.15em solid #303135 !important;
  
        @media (max-width : 991px) {
          width: max-content !important;
          margin: 6px !important;
        }
      
        @media (max-width : 600px) {
          width: 100% !important;
          margin: 6px !important;
          .ellipsis-word {
            white-space: nowrap; 
            width: fit-content; 
            overflow: hidden;
            text-overflow: ellipsis; 
          }
        }
  
        @media (max-width : 500px) {
          width: 100% !important;
          margin: 6px !important;
        }
      }
    }
    .brand-btn:hover{
      background-color: #dc6969d1 !important;
    }
  
    .drop-icon{
      svg{
        width: 20px;
        height: 20px;
        color: rgba(209, 213, 219, 1);
        padding: 0em 0em 0em 0.5em;
  
        @media (max-width : 991px) {
          width: 16px;
          height: 16px;
        }
      }
  
      &.--sort{
        svg{
          color: #0B0D13;
        }
      }
    }
  
    .export-btn{
      margin-right: 0%;
    }
    // .samePriceRange-image-container img {
    //   width: 100%;
    //   max-height: 100%;
    //   border-radius: 3px;
    // }
  
    .samePriceRange-img {
      height: 20em;
    }
    .samePriceRange-img img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 3px;
    }
  
    .samePriceRange-swiper {
      cursor: pointer;
      width: fit-content;
      max-width: 300px;
      padding: 2rem 1rem;
    }
  
    .samePriceRange-swiper-container {
      // max-width: none !important;
  
    }
  
  
    /** Disable **/
    .btn-primary:disabled,
    .btn-primary.disabled {
      background-color: none !important;
      color:  none imr !important;
    }
  
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /*************/
  
  
    @media (min-width :991px) {
      .carInfo-header-container {
        display: block;
      }
  
      .secondary-header {
        margin-top: 11rem;
      }
  
      .carBasic-info {
        z-index: 101;
        height: 12em;
        max-width: none !important;
      }
  
      .carInfo-page {
        padding: 0% 10%;
      }
  
      .carInfo-details-container {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 1.5em;
      }
  
      .samePriceRange-image-container {
        max-width: none !important;
      }
  
      .carInfo-samePriceRange-title p {
        margin-left: 10%;
      }
  
      .samePriceRange-swiper-container {
        // padding: 0 10rem !important;
  
      }
  
    }
  
    //*** COMMON ***//
    .pointer{
      cursor: pointer;
    }
  
    .auction-guide-container{
      @media (min-width :991px) {
        padding-top: 8em;
      }
      // background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .auction-guide-image-row{
        gap: 0.5em;
        padding-bottom: 3rem;
        img{
          max-width: 20em;
        }
  
        .image-container-small {
          cursor: pointer;
          width: 100%;
          height: 0;
          padding-top: 70%;
          padding-bottom: 70%; /* Adjust this value based on your desired aspect ratio */
          @media (max-width :991px) {   
            padding-bottom: 55%;
            }
          position: relative;
          overflow: hidden;
  
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .image-container img {
          position: absolute;
          // top: 0;
          // left: 0;
          // width: 100%;
          // height: 10px;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover; /* Use 'contain' if you want to maintain the aspect ratio without cropping */
        }
      }
  
  
  
      ._label {
        &.--gold-text {
  
          width: auto;
          color: #FBB24B;
          font-size: 1.4rem;
        }
  
        &.--white-text {
          width: 100%;
          color: #D1D5DB;
          font-size: 1.4rem;
        }
      }
      ._text{
        line-height: 1em;
        color: #24262D;
        font-weight: 500;
        padding-top: 1rem;
        font-size: 1.6rem;
        text-align: center;
      }
  
      .main-title-row {
        padding-top: 5em;
        padding: 1em 0em;
        
      
        ._title-special-text {
          padding-top: 3rem;
          padding-bottom: 1rem;
          color: #ff0000;
          font-family: "homoarakhn";
          font-size: 2.4rem;
          //font-weight: bold;
          z-index: 1;
          text-align: center;
  
      
          ._title {
            color: #FBB24B;
            font-family: "inter-regular";
            font-size: 2rem;
          //   @media (max-width :991px) {   
          //     font-size: 1.7rem;
          // }
            font-style: italic;
            //font-weight: bold;
            letter-spacing: 0.5rem;
          }
  
          ._title--big {
            color: #FBB24B;
            font-family: 'Homoarakhn';
            font-size: 3rem;
            font-style: italic;
            //font-weight: bold;
            letter-spacing: 0.5rem; 
            text-align: center;
          
          }
        }
      }
  
      ._image {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        margin: 0 auto;
        display: block;
        // padding: 1rem;
        padding-top: 1rem;
      }
  
    }
  
    .after-login-container {
      position: relative;
      background: #24262D;
      border-radius: 0.8em;
      margin: 1em 0 1em 0;
      padding: 0.5em 1em;
      overflow: hidden;
      // height: 10em;
      // transition: height 1s;
  
      ._text{
        color: #9091A0;
        padding-top: 1rem;
        font-size: 1.6rem;
      }
  
      ._button{
        padding-bottom: 2rem;
      }
    }
  
    .order-account-container{
      .user-detail-container{
        align-items: center;
        margin: 1em 0 2em 0;
        .profile-pic{
          @media (max-width :991px) {   
            max-width: 6em;
          }
          max-width: 7em;
          text-align: center;
          img{
            width: 70px;
            height: 70px;
            @media (max-width :991px) {   
              border: 2px solid #ffd43972;
            }
            border: 3px solid #eb3238bd;
            border-radius: 50%;
            padding: 0.25em;
          }
          .button-action{
          }
        }
      }
      ._boder {
        width: 100%;
        height: 3em;
        padding: 0.5em;
        position: relative;
        border-radius: 8px;
        background: #ffffff;
        box-shadow: inset 0px 2px 15px rgba(126, 126, 126, 0.25);
      }
      ._boder::after {
        content:"";
        position: absolute;
        inset:0;
        padding: 0.05em; /* the border thickness */
        border-radius: 8px;
        background: #ffffff;
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
      }
      ._text--oac{
        color: #888DAA;
        padding: 1rem;
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin: 0;
        text-align: justify;
      }
      ._label{
        font-family: "inter-regular";
        &.--white-text{
          color: #F2F8F9;
          // z-index: 1; position: relative;
          width: auto;
          padding-top: 1rem;
        }
        &.--username{
          @media (max-width :991px) {   
            font-size: 1.6rem;
          }
          font-size: 2rem;
          cursor: pointer;
          color: #616469;
          padding-right: 1em;
        }
        &.--action:last-child{
          @media (max-width :991px) {   
            font-size: 1.6rem;
          }
          cursor: pointer;
          font-size: 2rem;
          padding-left: 1em;
          color: #616469;
          border-left: 0.15em solid #d1d5dba4;
        }
        &.--desc-detail{
          @media (max-width :991px) {   
            font-size: 1.2rem;
          }
          padding-top: 0.3em;
          font-size: 1.6rem;
          color: #9091A0;
        }
      }
    }
  
    .auction-guide-table-container{
      // background-color: #ffffff;
      padding-bottom: 5rem;
  
      ._table{
        &.--red{
          background-color: rgb(199, 0, 0);
          color: white;
          font-size: 3rem;
        }
        &.--black{
          background-color: #000000;
          color: #FBB24B;
          font-size: 1.5rem;
        }
  
      }
      ._tab{
        height: auto;
        // overflow-y: auto;
  
        &.--height{
          height: auto;
        //   @media (max-width :991px) {   
        //     height: 110rem;
        // }
      }
  
        // &.--second-height{
        //   height: 40rem;
        //   @media (max-width :991px) {   
        //     height: 78rem;
        // }
        // }
  
        // &.--item-height{
        //   height: 50rem;
        // }
      }
      ._padding{
        padding: 0.5rem;
      }
  
      ._text{
        &.--grey{
          color: #9E9E9E;
          // z-index: 1; position: relative;
          width: auto;
          font-size: 1.6rem;
          //font-weight: bold;
          height: 7rem;
          display: flex;
          justify-content: center;
        }
  
        &.--body{
          color: #050505;
          width: auto;
          font-size: 1.6rem;
        }
  
        &.--body--special{
          color: #050505;
          width: auto;
          font-size: 1.5rem;
          @media (max-width :991px) {   
            font-size: 1.3rem;
        }
        }
  
        &.--head{
          color: #F2F8F9;
          width: auto;
          font-size: 1.8rem;
          //font-weight: bold;
  
        }
      }
      .dark-theme-tabs {
        background-color: #333;
        padding: 1rem;
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;
      }
      
      .dark-theme-tabs ._tab {
        border-bottom: 0.1px solid #444;
  
      }
      
      .dark-theme-tabs .nav-link {
        color: #FBB24B;
        //font-weight: 500;
        border-radius: 5px;
        margin-right: 5px;
        font-size: 1.8rem;
        @media (max-width :991px) {
          font-size: 1.45rem;;
        }
      }
      
      .dark-theme-tabs .nav-link:hover {
        color: #000000;
        background-color: #ecd7b0;
  
      }
      
      .dark-theme-tabs .nav-link.active {
        color:#EC3237;
        background-color: #222;
        border-color: #FBB24B;
        //font-weight: 600;
        text-align: center;
  
  
      }
    }
  
    .about-us-container{
      @media (min-width :991px) {
        padding-top: 10em;
      }
    ._label{
      text-align: center;
      justify-items: center;
      justify-content: center;
      }
  
    ._text{
      &.--big{
        color: #F2F8F9;
        width: auto;
        font-size: 4rem;
        //font-weight: bold;
        }
  
      &.--white{
        font-family: 'inter-regular';
        color: #F2F8F9;
        width: auto;
        font-size: 1.4rem;
        line-height: 1.2em;
        text-align: center;
        padding: 1rem;
        @media (min-width :991px) {
          font-size: 2.4rem;
        }
        }
  
      &.--black{
        color: #000000;
        width: auto;
        font-size: 3rem;
        //font-weight: bold;
        }
  
        &.--gold{
          color: #FBB24B;
          // color: #24262D;
          width: auto;
          //font-size: 2em;
          font-style: italic;
          //font-weight: bold;
          font-family: 'Homoarakhn';
          letter-spacing: 0.5rem; 
          text-align: center;
          @media (min-width :991px) {
            font-size: 3rem;
          }
          }
  
        &.--white2{
          color: #222222;
          width: auto;
          font-size: 3rem;
          color: #e0cdaa;
          font-family: "inter-regular";
          text-shadow: 0px 0px 0 rgb(180,161,126),
                      -1px 1px 0 rgb(137,118,83),
                      -2px 2px 0 rgb(93,74,39),
                      -3px 3px 0 rgb(50,31,0),
                      -4px 4px 0 rgb(6,-13,0),
                      -5px 5px  0 rgb(-38,-57,0),
                      -6px 6px 5px rgba(66,61,61,0.6),
                      -6px 6px 1px rgba(66,61,61,0.5),
                      0px 0px 5px rgba(66,61,61,.2);
          text-align: center;
          @media (min-width :991px) {
            font-size: 3rem;
          }
        }
  
        &.--red{
          color: #D1D5DB;
          width: auto;
          font-size: 1.4rem;
          text-align: center;
          @media (min-width :991px) {
            font-size: 1.4rem;
          }
        }
  
        &.--subtitle{
          color: #9091AD;
          width: auto;
          font-size: 1.6rem;
          text-align: center;
          letter-spacing: 0.06em;
          @media (min-width :991px) {
            font-size: 1.6rem;
          }
        }
      }
  
    .div-line{
      width: 8em;
      border-top: 0.2em solid #FBB24B ;
      //border-top: 0.2em solid # ;
    }
  
    ._flex{
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
  
    ._ripple{
      position: relative;
    }
  
    ._padding{
      padding: 1rem;
      padding-bottom: 2rem;
      padding-top: 2rem;
      align-items: center;
    }
  
    ._about-card{
    
      &.--vision{
        color: #D1D5DB;
        background-color: #332D2D;
        border-bottom: 0.15em solid #474242;
        box-shadow: 0px 2px 4px #FBB24B;
        .grid-div{
          display: grid;
          grid-template-columns: 60% 40%;
          .gif-div {
            width: 8em;
          }
          h3 {
            text-transform: capitalize;
            letter-spacing: 0.06em;
            display: flex;
            align-items: end;
          }
        }
        .quote {
          font-size: 1.4rem;
          font-style: italic;
        }
        @media (max-width: 991px) {
          margin-bottom: 1.5em;
        }
      }
  
      &.--mission{
        color: #D1D5DB;
        background-color: #332D2D;
        border-bottom: 0.15em solid #474242;
        box-shadow: 0px 2px 4px #FBB24B;
        .grid-div{
          display: grid;
          grid-template-columns: 60% 40%;
          .gif-div {
            width: 8em;
          }
          h3 {
            text-transform: capitalize;
            letter-spacing: 0.06em;
            display: flex;
            align-items: end;
          }
        }
        .quote {
          font-size: 1.4rem;
          font-style: italic;
        }
        @media (max-width: 991px) {
          margin-bottom: 1.5em;
        }
      }
  
      &.--value{
        color: #D1D5DB;
        background-color: #332D2D;
        border-bottom: 0.15em solid #474242;
        box-shadow: 0px 2px 4px #FBB24B;
        .grid-div{
          display: grid;
          grid-template-columns: 60% 40%;
          .gif-div {
            width: 8em;
          }
          h3 {
            text-transform: capitalize;
            letter-spacing: 0.06em;
            display: flex;
            align-items: end;
          }
        }
        .quote {
          font-size: 1.4rem;
          font-style: italic;
        }
        @media (max-width: 991px) {
          margin-bottom: 1.5em;
        }
      }
    }
  
    // .image-container {
    //   position: relative;
    // }
  
    // .image-container::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(0, 0, 0, 0.4);
    //   pointer-events: none;
    // }
  
    // @keyframes slide-in-up {
    //   0% {
    //     opacity: 0;
    //     transform: translateY(100%);
    //   }
    //   100% {
    //     opacity: 1;
    //     transform: translateY(0);
    //   }
    // }
  
    .grid-container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      // grid-gap: 0.2rem;
      margin-bottom: 2em;
      
  
      @media (max-width: 767px) {
        grid-template-columns: repeat(6, 1fr);
      }
  
      @media (min-width: 991px) {
        grid-template-columns: repeat(12, 1fr);
      }
    }
  
    .grid-item {
      &.grid-item-1 {
        grid-column: span 3;
        padding: 1rem;
  
        @media (min-width: 789px) {
          grid-column: span 6;
          padding: 1rem;
        }
      }
  
      &.grid-item-2,
      &.grid-item-3 {
        grid-column: span 3;
        padding: 1rem;
  
        // @media (max-width: 767px) {
        //   grid-column: span 1;
        // }
      }
  
      &.grid-item-4,
      &.grid-item-5 {
        grid-column: span 3;
        padding: 1rem;
  
        // @media (max-width: 767px) {
        //   grid-column: span 1;
        // }
      }
  
      &.grid-item-6 {
        grid-column: span 3;
        padding: 1rem;
  
        @media (min-width: 789px) {
          grid-column: span 6;
          padding: 1rem;
        }
        // @media (max-width: 767px) {
        //   grid-column: span 1;
        // }
      }
    }
    .image-card {
      position: relative;
      width: 100%;
      height: 0;
      //padding-bottom: 30%;
      perspective: 1000px;
      cursor: pointer;
    }
  
    .image-card.flipped .front {
      transform: rotateY(180deg);
      z-index: 1;
    }
  
    .image-card.flipped .back {
      transform: rotateY(0deg);
      z-index: 2;
    }
  
    .image-card .front,
    .image-card .back {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      transition: transform 0.6s, backdrop-filter 0.6s;
    }
  
    .image-card .front {
      //background-color: transparent;
      transform: rotateY(0deg);
      z-index: 2;
    }
  
    // .overlay {
    //   backdrop-filter: blur(10px) !important;
  
    //   z-index: 4;
    // }
  
    .image-card .back {
      //background-color: rgba(255, 255, 255, 0.3);
      transform: rotateY(180deg);
      z-index: 1;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      //text-align: center;
      //color: #333;
      //font-size: 20px;
      //font-weight: bold;
      //height: 200px;
      //box-sizing: border-box;
    }
  
    // .image-card .back .text {
    //   padding: 10px;
    // }
  
    // .image-card .back .text::after {
    //   content: '';
    //   display: block;
    //   width: 80px;
    //   height: 3px;
    //   background-color: #333;
    //   margin: 10px auto;
    // }
  
    .about-us-image-container {
      img {
        object-fit: cover;
        width: 100%;
        // height: 100%;
        min-height: 200px;
        max-height: 400px;
      }
    }
  
    .about-us-image-container-v2 {
      img {
        cursor: pointer;
        object-fit: cover;
        width: 100%;
        // height: 100%;
        min-height: 200px;
        max-height: 400px;
      }
  
      &.--back {
        position: relative;
        img {
          object-fit: cover;
          width: 100%;
          // height: 100%;
          min-height: 200px;
          max-height: 400px;
          filter: blur(2px);
          overflow: hidden;
        }
        // .overlay {
        //   background: #ffffff83;
        //   z-index: 4;
        // }
        .text {
          // font-family: "inter-regular";
          // color: #0b0d13 !important;
          // font-style: normal;
          // align-items: center;
          // display: flex;
          // justify-content: center;
          // background: #dfdfdf83;
          // transform: translate(-50%, -50%);
          // position: absolute;
          // height: 90%;
          // width: 90%;
          // top: 50%;
          // left: 50%;
        }
      
      }
    }
    .about-img {
      height: auto;
    }
  
    @media (min-width: 991px) {
      .about-img {
        height: 200px;
      }
    }
      .t_c-row{
    position: fixed;
  }
    .tc-title{
      font-family: "inter-semi-bold";
      color : #24262D ;
      font-size: 2rem;
      text-decoration: underline;
    }
    .t_and_c-div{
      ._text{
        &.--t_c_title{
          color: #24262D;
          // color: #dc6969;
          font-family: "inter-medium";
          font-size: 1.8rem;
        }
        &.--t_c_content{
          margin: 1em 0em;
          color: #9091ad;
          font-family: "inter-medium";
          font-size: 1.6rem;
        }
        &.--t_c_detail{
          color: #9091ad;
          font-family: "inter-medium";
          font-size: 1.6rem;
        }
      }
    }

    .t_and_c-dropdown-div{
      position: sticky;
      top: 0px;
      .p-dropdown{
        width: 100%;
        border-color: #FBB24B;
        box-shadow: 0 0 0 0.2rem #ffd439 ;
        .p-inputtext{
          font-size: 1.9rem;
          font-family: "inter-semi-bold";
          // color: #FBB24B;
        }
      }
     
    }
    .navLink{
    color: #24262D !important;
    font-size: 1.6rem !important;
    font-family: "inter-medium" ;
    margin: 1em 0em !important; 
    cursor: pointer;
  }
  .navLink-active{
    color: #24262D !important;
    font-size: 2rem !important;
    font-family: "inter-semi-bold" !important;
    cursor: pointer; 
  }
  
    }
  
    .contact-us-container{
      background-color:#252B35 ;
      @media (min-width :991px) {
        padding-top: 10em;
      }
      padding-bottom: 5rem;
  
      @media only screen and (max-width: 991px) {
        iframe {
          width: 100%;
          height: 300px;
          padding: 1rem;
        }
      }
      
      @media only screen and (min-width: 991px) {
        iframe {
          width: 100%;
          height: 500px;
        }
      }
      .main-title-row {
        padding-top: 5em;
        padding: 1em 0em;
        padding-bottom: 5rem;
        
      
        ._title-special-text {
          padding-top: 3rem;
          padding-bottom: 1rem;
          color: #EC3237;
          font-family: "homoarakhn";
          font-size: 3.2rem;
          //font-weight: bold;
          z-index: 1;
          text-align: center;
          font-style: italic;
          letter-spacing: 0.2rem; 
  
  
      
          ._title {
            color: #FBB24B;
            font-family: "inter-regular";
            font-size: 2rem;
            font-style: italic;
            //font-weight: bold;
            letter-spacing: 0.5rem;
          }
  
          ._title--big {
            color: #FBB24B;
            font-family: 'Homoarakhn';
            font-size: 3rem;
            font-style: italic;
            //font-weight: bold;
            letter-spacing: 0.2rem; 
            text-align: center;
          
          }
        }
      }
  
      ._bg-color{
        background-color:#252B35 ;
      }
  
      ._img-align{
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      ._text{
        padding: 1.5rem;
        line-height: 2.2rem;
  
        ._yellow{
          color: #FBB24B;
          //font-weight: bold;
          padding-bottom: 1rem;
        }
  
        ._white{
          color: #F2F8F9;
          font-size: 1.7rem;
          @media (max-width :991px) {
            font-size: 1.4rem;
          }
        }
      }
  
      ._row-padding{
        padding-top: 1rem;
        padding-bottom: 1.5rem;
      }
  
    }
  
    .compare-model-container {
      .action-row {
        color: #9091a0;
        font-size: 1.2rem;
        line-height: 1.4em;
        padding: 2em 0em 0em 0em;
        overflow: scroll;
        flex-wrap: nowrap;
        ._label {
          padding-top: 0.5em;
          font-size: 1.2rem;
  
          &.--bold {
          //font-weight: bold;
          }
        }
      }
  
      @media (min-width: 991px) {
        .action-row {
          flex-wrap: wrap;
          overflow: hidden;
          img {
            margin-left: auto;
            margin-right: auto;
          }
  
          ._label {
            font-size: 1.6rem;
          }
        }
      }
      .action-row::-webkit-scrollbar {
        display: none !important;
      }
  
      ._text{
        // padding: 1.5rem;
        // line-height: 2.2rem;
        // padding-top: 5rem;
        display: flex;
        // align-items: center;
  
        &.--yellow{
          color: #FBB24B;
          //font-weight: 600;
          text-align: right;
          font-size: 2.4rem;
          padding-top: 4rem;
          justify-content: end;
          padding-right: 5rem;
          @media (max-width :991px) {
            padding-right: 2rem;
          }
        }
  
        &.--gold {
          color: #FBB24B;
          font-family: 'Homoarakhn';
          font-size: 1.6rem;
          //font-weight: 400;
          padding-top: 5rem;
          padding-left: 5rem;
          @media (max-width :991px) {
            padding-left: 2rem;
          }
        
        }
  
      }
  
      ._padding{
  
        &.--top{
          padding-top: 1rem;
        }
  
        &.--btm{
          padding-bottom: 4rem;
        }
  
        &.--all{
          padding: 1rem;
        }
      }
    }
  
    .compare-model-card-container{
      font-family: 'cabin-regular';
      background-color: #FAFAFA;
      border-radius: 2.4rem;
      padding: 1rem;
      .card{
        background-color: #24262D;
        box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.361), 0 10px 20px -2px rgba(0, 0, 0, 0%);
      }
      ._text{
        // display: flex;
        margin-bottom: 0.3rem;
        font-family: 'cabin-regular';
        &.--first{
          font-style: normal;
          //font-weight: 700;
          font-size: 19px;
          line-height: 19px;
          text-align: center;
          font-family: 'cabin-regular';
          padding-top: 1rem;
          padding-bottom: 2rem;
          color: #24262D;
          font-family: "inter-bold";
          // text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.40)
          }
  
        &.--sec{
          font-style: normal;
          //font-weight: 600;
          font-size: 2rem;
          line-height: 24px;
          text-align: left;
          font-family: 'cabin-regular';
          padding-top: 2rem;
          padding-bottom: 1rem;
          color: #24262D;
        }
  
        &.--third{
          font-style: normal;
          //font-weight: 600;
          font-size: 1.4rem;
          line-height: 17px;
          font-family: 'inter-medium';
          color: #24262D;
          padding-bottom: 2rem;
        }
  
        &.--forth-big{
          font-style: normal;
          //font-weight: 500;
          font-size: 1.2rem;
          line-height: 15px;
          font-family: 'cabin-regular';
          color: #9091A0;
        }
  
        &.--forth-small{
          font-style: normal;
          //font-weight: 400;
          font-size: 1.2rem;
          line-height: 15px;
          font-family: 'cabin-regular';
          color: #616469;
          padding-bottom: 2rem;
        }
  
        &.--card-name{
          font-style: normal;
          //font-weight: 400;
          font-size: 1.6rem;
          @media (max-width :980px) {
            font-size: 1.4rem;
            padding: 1rem 1.5rem;
          }
          line-height: 19px;
          text-align: center;
          font-family: 'cabin-regular';
          //padding-left: 3em;
          color: #F8F8F8;
          }
  
        &.--card-small{
          font-style: normal;
          //font-weight: 600;
          font-size: 1.4rem;
          line-height: 17px;
          text-align: center;
          font-family: 'cabin-regular';
          color: #24262D;
          background-color: #FFFFFF;
          margin-bottom: 0rem;
          border-radius: 15px 15px 0px 0px;
          margin: 0rem 5rem;
          padding: 0.5rem;
          }     
  
      }
        ._padding{
      
          &.--all{
            padding: 1rem;
          }
  
          &.--top{
            padding-top: 3rem;
          }
  
          &.--none{
            padding: 0rem;
            color: white;
          }
        }
  
        ._boder{
          background: #FFFFFF;
          text-align: center;
          border:1px solid #ddd;
          border-width: 0.2rem;
          border-radius: 24px;
          padding: 1rem;
          margin-bottom: 1rem;
        }
  
        ._card_boder{
          border: solid;
          // border-radius: 24px;
  
        }
  
        ._specification-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }
  
        ._ripple {
          background-color: #0b0d13ad ;
          &.--grey{
            background-color: #FAFAFA !important;
            width: auto;
            height: inherit;
  
            // height: 320px;
            // @media (max-width :980px) {
            //   width: auto;
  
            //   height: 210px;
            // }
            
            
          }
  
          &.--normal{
            cursor: pointer;
            background: #FFFFFF !important;
            width: auto;
            height: 250px;
            @media (max-width :980px) {
              width: auto;
              height: 220px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #9091A0;
          }
        }
  
        ._radius {
          border-radius: 1rem;
        }
  
        ._card {
          background-color: #24262D;
          &.--size{
            // width: 335px;
            width: auto;
            height: 251px;
            @media (max-width :980px) {
              height: 220px;
            }
          }
  
          &.--img-size{
            width: 100%;
            height: 180px;
            // height: 100%;
  
            object-fit: cover;
            @media (max-width :980px) {
              height: 110px;
              object-fit: cover;
            }
          }
  
          ._height {
            height: inherit;
          }
      }
    }
  
    .ticker {
      // margin-top: 0.2em;
      background: transparent;
      .ticker__element {
        display: block;
        height: 2em;
        //z-index: 9999;
        width: auto;
        svg {
          margin-top: -0.25em;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          font-size: 1.4rem;
          padding: 0.25em 1.5em 1em 1em;
          color: #888DAA;
          word-break: break-word;
          letter-spacing: 0.02em;
        }
      }
    }
  
    .p-skeleton {
      opacity: 0.3;
      background: #9091A0 !important; 
    }
  
    .p-image{
      img{
        cursor: pointer;
        // height: 100px;
        object-fit: cover;
        border-radius: 0.5em;
      }
    }
    .first-load-page {
      background: #FFFFFF;
      height: 100%;
      width: 100%;
    }
  
    .first-load-overlay{
      backdrop-filter: blur(0px);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: fixed;
      z-index: 999999;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      // transition: backdrop-filter 0.6s !important;
  
      &.--end{
        animation-name: hide-up;
        animation-duration: .8s;
        animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
        animation-fill-mode: both;
      }
    }
  
    .animated-logo{
      opacity: 0;
      position: relative;
      width: auto;
      height: auto;
      animation-name: fade-in;
      animation-duration: 1.5s;
      animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      animation-fill-mode: both;
      width: 250px;
    }
  
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  
    @keyframes hide-up {
      0% {
        transform: translateY(-0%);
        opacity: 1
      }
      100% {
        transform: translateY(-100%);
        opacity: 0
      }
    }
  
    .modal-backdrop.show{
      opacity: 1 !important;
    }
  
    .modal-backdrop.fade{
      opacity: 1 !important;
    }
  
    .modal-backdrop{
      backdrop-filter: blur(2px) !important;
      background: #00000042 !important;
    }
  
    .full-screen-mobile{
      display: flex;
      align-items: center;
      width: 100%;
      height: 100vh;
    }
  
    .agent-card-container {
      font-family: "inter-regular";
      //font-style: italic;
      //font-weight: bold;
      display: flex;
      margin: auto;
      //aspect-ratio: 16/9;
      align-items: center;
      justify-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 1em;
      overflow: hidden;
      height: 22em;
    }
  
    .agent-card {
      //filter: drop-shadow(46px 36px 24px #4090b5) drop-shadow(-55px -40px 25px #9e30a9);
      //animation: blinkShadowsFilter 8s ease-in infinite;
    }
  
    .agent-card-content {
      transform: rotate(90deg);
      display: grid;
      align-content: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      padding: 1.5em;
      grid-template-rows: 0.1fr 0.7fr 0.25fr;
      //background-color: hsl(296, 59%, 10%);
      width: 10em;
      height: 20em;
      -webkit-clip-path: polygon(0 0, 85% 0, 100% 14%, 100% 60%, 92% 65%, 93% 77%, 99% 80%, 99% 90%, 89% 100%, 0 100%);
      clip-path: polygon(0 0, 85% 0, 100% 14%, 100% 60%, 92% 65%, 93% 77%, 99% 80%, 99% 90%, 89% 100%, 0 100%);
      @media(min-width: 770px) {
        width: 16em;
        height: 36em;
      }
    }
  
    .agent-card-content::before {
      content: "";
      position: absolute;
      width: 300%;
      aspect-ratio: 1/1;
      transform-origin: center; //f4c98d
      //background: linear-gradient(to bottom, transparent, transparent, #f4c98d, #f4c98d, #f4c98d, #f4c98d, transparent, transparent);
      background: linear-gradient(to bottom, transparent, transparent, #575f7a, #575f7a, #575f7a, #575f7a, transparent, transparent);
      @media(min-width: 770px) {
        width: 325%;
      }
    }
  
    .agent-card-content::after {
      content: "";
      position: absolute;
      top: 1%;
      left: 1%;
      width: 98%;
      height: 98%;
      //background: repeating-linear-gradient(to bottom, transparent 0%, rgba(236, 50, 55, 1) 1px, rgb(0, 0, 0) 3px, rgba(251, 178, 75, 1) 5px, #153544 4px, transparent 0.5%), repeating-linear-gradient(to left, hsl(295, 60%, 12%) 100%, hsla(295, 60%, 12%, 0.99) 100%);
      //box-shadow: inset 0px 0px 30px 40px hsl(296, 59%, 10%);
      box-shadow: inset 20px 20px 50px 100px #0b0a0ac4;
      -webkit-clip-path: polygon(0 0, 85% 0, 100% 14%, 100% 60%, 92% 65%, 93% 77%, 99% 80%, 99% 90%, 89% 100%, 0 100%);
      clip-path: polygon(0 0, 85% 0, 100% 14%, 100% 60%, 92% 65%, 93% 77%, 99% 80%, 99% 90%, 89% 100%, 0 100%);
    }
  
    .agent-card-title-div {
      transform: rotate(-90deg);
      margin-left: -10em;
      margin-top: 4em;
      z-index: 4;
      //-webkit-clip-path: polygon(90% 0, 100% 100%, 0% 100%, 0% 0%);
      //clip-path: polygon(90% 0, 100% 100%, 0% 100%, 0% 0%);
      //background: linear-gradient(90deg, rgba(255, 254, 250, 0) 0%, rgba(102, 224, 255, 0.3) 27%, rgba(102, 224, 255, 0.3) 63%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(102, 224, 255, 0.3) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 96%, rgba(102, 224, 255, 0.3) 100%);
      background: linear-gradient(90deg, rgba(255, 254, 250, 0) 0%, rgba(255, 212, 57, 0.6) 27%, rgba(255, 212, 57, 0.6) 63%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(255, 212, 57, 0.8) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 96%, rgba(255, 212, 57, 0.8) 100%);
      //linear-gradient(90deg, rgba(255, 254, 250, 0) 0%, #ffd439, #ffd439, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgb(137 7 7 / 22%) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 96%, rgb(137 7 7 / 22%) 0%);
      //
      width: 12em;
      font-size: 1em;
      @media (max-width: 770px) {   
        margin-left: -8em;
        margin-top: 3em;
        width: 7em;
      }
  
    }
  
    .agent-card-title {
      width: 100%;
      height: 100%;
      //text-align: right;
      position: relative;
      z-index: 2;
      color: hsl(192, 100%, 88%);
      font-size: 1.1em;
      padding: 1em;
      transition: all ease-in-out 2s linear;
      color: #14161E;
      @media (max-width: 770px) {   
        font-size: 0.8em;
      }
  
    }
  
    .agent-card-subtitle {
      // position: absolute;
      // top: 70%;
      width: auto;
      // width: 100%;
      // height: 100%;
      text-align: left;
      position: relative;
      z-index: 2;
      color: hsl(192, 100%, 88%);
      transition: all ease-in-out 2s linear;
  
      &.--name {
        color: #D1D5DB;
        white-space: nowrap;
        font-size: 1.5em;
        text-transform: uppercase;
        @media (max-width: 770px) {   
          font-size: 1em;
        }
      }
      &.--ic {
        color: #D1D5DB;
        font-size: 1em;
        @media (max-width: 770px) {   
          font-size: 0.7em;
        }
      }
    }
  
    .agent-card-body {
      height: 6em;
      width: 8em;
      transform: rotate(-90deg);
      z-index: 80;
      display: flex;
      gap: 0.3em;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      padding-left: 0.5em;
      @media (min-width: 770px) { 
        margin-left: 1.5em;  
        margin-bottom: -1.5em;
        height: 16em;
        width: 10em;
      }
    }
  
    .svg-card {
      text-decoration: none;
      color: hsl(192, 100%, 88%);
      background: linear-gradient(90deg, transparent 0%, rgba(236, 50, 55, 1) 27%, rgba(251, 178, 75, 1) 63%, transparent 100%);
      fill: currentColor;
      width: 2em;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
    }
  
    .agent-profile-pic{
      margin-bottom: 1em;
      margin-left: -1em;
      height: 10em;
      width: 9em;
      transform: rotate(-90deg);
      z-index: 3;
      @media (max-width: 770px) {   
        margin-left: -1.5em;
        height: 7em;
        width: 6em;
      }
      //max-width: 7em;
      img{
        object-fit: cover;
        @media (max-width: 770px) {   
          border: 2px solid #575f7a
          // #ffd43960;
        }
        border: 3px solid #575f7a;
        border-radius: 0;
        padding: 0.25em;
      }
      .button-action{
      }
    }
  
    .pagination-div{
      display: flex;
      justify-content: center;
      padding-top: 3em;
  
      @media (max-width: 400px) {   
        width: 100%;
      }
    }
  
    .pagination {
      margin: 0;
      padding: 0em 6em !important;
      justify-content: center;
  
      @media (max-width: 600px) {   
        padding: 0em 2em !important;
      }
  
      @media (min-width: 991px) {   
        padding: 0em 10em !important;
      }
  
      @media (min-width: 1200px) {   
        padding: 0em 15em !important;
      }
  
  
  
      .page-item {
        display: flex;
        gap: 1.5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0.1em 0.5em;
        outline: 0 !important;
  
        @media (max-width: 500px) {   
          padding: 0.1em 0.1em;
        }
        
        .page-link {
          color: #9091a0;
          font-size: 15px;
        }
        .page-link:hover, .page-link:focus{
          background-color: transparent;
        }
      }
      .page-item.active{
        background-color: transparent;
        border-radius: 0.2em;
        outline: 0 !important;
  
        .page-link {
          color: white;
          background: #AC3434;
          padding: 0.4em 0.8em;
          border: none;
          box-shadow: none;
  
          @media (max-width: 500px) {   
            padding: 0.38em 0.6em;
          }
        }
      }
  
      .page-item.disabled{
        background-color: transparent;
  
        .page-link {
          background-color: transparent;
          border: none;
        }
      }
    }
  
    .previous-div, .next-div {
      cursor: pointer;
      background-color: #DC6969;
      border-radius: 0.2em;
      height: 38px;
      padding: 0.35em 0.2em;
  
      &.--disable{
        cursor: default;
        background-color: #F8f8f8
      }
  
      @media (min-width: 500px) {   
        padding: 0.35em 0.5em;
      }
    }
  
    .page-div {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0.2em 0.8em;
      color: #9091a0;
  
      &.--active{
        background-color: #ffd439;
        border-radius: 0.2em;
        color: #000;
      }
    }
  
  
    .language-dropdown-container {
      position: absolute;
      top: 1em;
      right: 1em;
      z-index: 10;
      text-align: center;
  
      // Increase the dropdown container width and font size
      .dropdown-toggle {
        width: 120px; // Adjust as needed
        font-size: 1.2rem;
        cursor: pointer; // Change cursor to pointer
        color: whitegrey; // Change the color for the selected text
      }
  
      .dropdown-menu {
        background-color: black;
        width: 120px; // Adjust as needed to match the dropdown container width
        color: white;
        cursor: pointer;
        font-size: 1.2rem;
        text-align: center;
        padding-top: 0.5rem;
      }
  
      .dropdown-item {
        color: white;
        font-size: 1.6rem;
        cursor: pointer;
  
        &:hover {
          background-color: #ec3237;
        }
      }
  
      .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }
  
    .language-buttons-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
  
      .language-button {
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 10;
        width: 48px;
        height: 30px;
        border: none;
        background-color: #393744;
        color: orange;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10%;
  
        @media (max-width: 991px) {
          width: 35px;
          height: 24px;
          font-size: small;
          // background-color: #F2F8F9;
          // color: black;
          font-weight: 600;
        }
      }
  
      .language-button img {
        width: 100%; 
        height: 100%;
        object-fit: contain;
      }
  
      .language-button:focus {
        outline: none; 
      }
  
      .language-button.selected {
        box-shadow: 0 0 0 1.5px;
        border-radius: 10%;
        color: orange;
      }
  
    }
  
    .event-modal-container{
      height: 800px;
  
      .modal-dialog{
        z-index: 9999 !important;
        max-width: 580px ;
        @media (max-width: 991px) {
          max-width: 100% ;
          margin-left: 0.6rem;
          
        }
        @media (min-width: 700px) and (max-width: 800px) {
          max-width: 50% ;
          margin-left: auto;
          margin-right: auto;
        }
        @media (min-width: 700px) and (min-height: 1000px) {
          max-width: 70% ;
          margin-left: auto;
          margin-right: auto;
        }
        @media (min-width: 1024px) and (max-width: 1024px) and (min-height: 600px) and (max-height: 600px) {
          max-width: 40%;
          margin-left: auto;
          margin-right: auto;
        }
        @media (orientation: landscape) and (min-width: 600px){
          max-width: 30%;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .icon-close-btn{
        color: #707070;
        cursor: pointer;
        position: absolute;
        top: 2em;
        right: 2em;
        z-index: 2;
        transition: color 0.3s ease-in-out;
        @media (max-width: 991px) {
          // position: absolute;
          right: 1rem;
          top: 1rem;
          // transform: translateY(-10%);
        }
        &:hover {
          // color: #ffd439;
          color: red;
        }
      }
  
      img {
        width: 100%;
        border-radius: 0.8em;
      }
  
      .event-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 1.6rem;
        color: white;
        .form-check{
          align-items: center;
          display: flex;
          .form-check-input{
            width: 1.3rem;
            height: 1.3rem;
          }
          .form-check-label{
            margin-top: 4.5px;
            // line-height: 1.3rem
          }
        }
      }
    }
  
    .theme-switch-container{
      text-align: end;
      // margin-right: 10rem;
  
      @media (max-width: 991px) {
        // margin-right: 2rem; 
        margin-top: 1.1rem; 
      }
    }
  
    .mui-slider-div{
      padding: 0em 1em;
      .MuiSlider-root {
        .MuiSlider-rail {
          background-color: #ffd349;
          opacity: 0.38;
        }
        .MuiSlider-track {
          background-color: #ffd349;
          border: 1px solid #ffd349;
        }
        .MuiSlider-thumb {
          background-color: #ffffff !important;
          border: 3.5px solid #fbb24b;
          width: 18px !important;
          height: 18px !important;
        }
        .MuiSlider-thumb:hover {
          box-shadow: 0px 0px 0px 8px rgba(255, 212, 57, 0.16);
        }
        .MuiSlider-valueLabel {
          font-family: "inter-regular";
          background: #525359;
          height: 2.5em;
          width: fit-content;
          font-size: 12px;
          border: none !important;
        }
      }
    }
  
    //#fff49794
    .model-tooltip {
      font-family: "inter-regular" !important;
      background: #525359 !important; 
      height: 3.2em;
      width: fit-content;
      font-size: 11px !important; 
      border: none !important;
    }
  
    .range-slider-input{
      label {
        align-items: center;
        display: flex;
      }
      input {
        height: 3em;
        text-align: center;
        border: 0.5px solid #d1d5db;
        border-radius: 8px;
        background-color: #f8f8f8;
        // color: #ffffff;
        width: 8em;
        border-radius: 5px;
        font-size: 14px;
        line-height: 22px;
        
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      input:focus-visible, input:focus {
        border: none !important;
        outline: 0.15em solid transparent !important;
        box-shadow: 0 0 0.2em 0.05em #ffd439, 0 0 0.1em 0.05em #fbb24b;
      }
    }
    
    .video-loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      .heading {
        color: #f6f6f6;
        letter-spacing: 0.2em;
        margin-bottom: 1em;
        font-family: "inter-medium";
      }
      
      .loading {
        display: flex;
        width: 5em;
        align-items: center;
        justify-content: center;
      }
      
      .load {
        width: 23px;
        height: 3px;
        background-color: limegreen;
        animation: 1s move_5011 infinite;
        border-radius: 5px;
        margin: 0.1em;
      }
      
      .load:nth-child(1) {
        animation-delay: 0.2s;
      }
      
      .load:nth-child(2) {
        animation-delay: 0.4s;
      }
      
      .load:nth-child(3) {
        animation-delay: 0.6s;
      }
      
      @keyframes move_5011 {
        0% {
          width: 0.2em;
        }
      
        25% {
          width: 0.7em;
        }
      
        50% {
          width: 1.5em;
        }
      
        100% {
          width: 0.2em;
        }
    }
    }
  
    .toggleSwitch{
      background-color: #F8F8F8;
      box-shadow: none;
    }
    .optionOne-white{
      color: #9E9E9E;
      font-family: "inter-medium";
    }
    .optionTwo-white{
      color: #9E9E9E;
      font-family: "inter-medium";
    }
    @keyframes slideIn {
      from {
           background-color: #FFFFFF !important;
  
        transform: translateX(100%);
        opacity: 1;
      }
      to {
           background-color: #FFFFFF !important;
  
        transform: translateX(0);
        opacity: 1;
      }
    }
    
    @keyframes slideOut {
      from {
           background-color: #FFFFFF !important;
  
        transform: translateX(0);
        opacity: 1;
      }
      to {
           background-color: #FFFFFF !important;
  
        transform: translateX(100%);
        opacity: 1;
      }
    }
    @keyframes slideBackgroundIn {
      from {
        background-color: unset !important;
        box-shadow: unset !important;
        opacity: 0.5;
      }
      to {
        background-color: #FFFFFF !important;
        box-shadow: 0px 2px 4px #000;
        opacity: 1;
      }
    }
  .optionOne,
  .optionTwo,
    .switch{
      color: #dc6969;
      font-family: "inter-medium";
      background-color: #FFFFFF !important;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20) !important;
        animation: slideBackgroundIn 1s ease-in-out forwards !important;
    }
  
    #active-toggled {
      animation: slideOut 0.5s ease-in-out forwards !important; 
      /*, slideBackgroundIn 0.5s ease-in-out forwards;*/
    }
    
    #active-left-toggled {
      animation: slideIn 0.5s ease-in-out forwards !important;
      /* slideBackgroundIn 0.5s ease-in-out forwards;*/
    }
  }


  .search-half-modal-light {
    // background-color: #0b0d13;
    [data-rsbs-scroll]::-webkit-scrollbar {
      display: none !important;
      background-color: #0b0d13;
    }
    [data-rsbs-scroll] {
      background-color: #FFFFFF;
    }
    [data-rsbs-header] {
      background-color: #FFFFFF;
    }
  
    .modal-dialog {
      top: 0 !important;
      transform: none !important;
      max-width: 100% !important;
      padding: 0em 2em;
    }
    .modal-dialog::-webkit-scrollbar{
      display: none !important;
    }
  
    .modal-content {
      background-color: unset !important;
      box-shadow: none !important;
      overflow: hidden;
  
      .modal-body {
        // padding: 1em;
        // height: 30em;
        // background-color: #0b0d13;
  
        svg {
          width: auto;
        }
  
        ._title {
          color: #24262D;
          font-size: 1.6rem;
        }
  
        .search-bar-row {
          cursor: pointer;
          font-family: "cabin-regular";
          background: #f9f6f2;
          color: #9091a0;
          border-radius: 0.5em;
          padding: 0.2em 0.5em 0.2em 1em;
          margin: 1em 0em;
          width: 99%;
          font-size: 1.6rem;
  
          input {
            background-color: transparent;
            border: none;
            outline: none;
          }
          ::placeholder {
            color: rgb(144, 145, 160);
          }
        }
  
        .search-tab-box {
          font-size: 1.4rem;
          background: #F8F8F8;
          border: 1px solid #9091A0;
          color: #9091A0;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          width: auto;
          text-align: center;
          white-space: nowrap;
          padding: 0.5em 1em;
          margin: 0.5em;
  
          &.--active {
            background: #FAFAFA;
            color: #000;
            border: 1px solid orange;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            font-weight: 600;
          }
        }
  
  
      }
    }
  
    .car-item-box {
      padding: 1em;
  
      &.--search{
        border-bottom: 0.1em solid #ababab;
        padding-bottom: 2em;
        padding-top: 2em;
      }
  
      ._label {
        &.--title {
          //font-weight: bold;
          width: 100%;
          font-size: 1.6rem;
          margin-top: 0.5em;
          color: #24252D;
          line-height: 1em;
        }
    
        &.--detail {
          width: 100%;
          font-size: 1.4rem;
          margin-top: 0.5em;
          color: #9091A0;
        }
    
        &.--tag {
          padding: 0.3em 0.7em;
          margin-top: 1em;
          margin-right: 0.5em;
          font-size: 1.2rem;
          border: 1px solid #9091A0;
          border-radius: 0.8em;
          color: #9091A0;
    
          @media (min-width: 991px) {
            padding: 0.3em 0.8em;
          }
        }
      }
    }
  }


  .brandlist-modal-container-light{

    z-index: 99999 !important;
  
    .brandlist-modal-header {
      border: none !important;
      padding: 2rem !important;
      border-top-left-radius: 1.4rem !important;
      border-top-right-radius: 1.4em !important;
      h2{
        font-size: 20px;
      }
    
      &.--filter{
        display: block;
        padding: 2rem !important;
    
        ._label {
          &.--clear {
            font-family: "inter-medium";
            text-decoration: underline;
            color: #AC3434;
            font-size: 14px;
          }
          &.--filter {
            font-size: 16px;
          }
        }
      }
    }
    
    .brandlist-ul{
      padding: 0 !important;
      border-bottom: 1px solid rgba(255, 212, 57, 0.2);
    }
    
    .brandlist-ul:last-child{
      padding: 0 !important;
      border-bottom: none;
    }
    
    .brandlist-div-container {
      position: absolute;
      // top: 0;
      // left: 0;
      width: 20em !important;
      //background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
      // display: flex;
      // align-items: center;
      // justify-content: center;
      z-index: 2100 !important;
      padding: 0 !important;
    
      &.--fixed {
        position: fixed;
        // top: 52%;
        // transform: translate(0px, -50%);
      }
    }
    
    .brandlist-div-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      backdrop-filter: blur(2px) !important;
      background: #00000042 !important;
      z-index: 2001 !important;
      //transition ?
    }
    
    .brandlist-div-body {
      position: relative;
      color: #d1d5db;
      // background-color: #14161E;
      background-color: #F6F6F6;
      padding: 1rem !important;
      height: 30em;
      width: 22em;
      border-radius: 1.4rem;
    
      @media(max-width: 991px) {
        width: 12em;
        height: 22em;
      }
    
      &.--slider{
        height: 15em;
      }
    
      &.--sort {
        height: 20em;
      }
    
      h2{
        font-style: italic;
        font-size: 14px;
      }
    }
    
    .brandlist-div-footer {
      position: absolute;
      width: 100%;
      display: flex;
      bottom: 0;
      left: 0;
      padding: 1.3em 2em;
      justify-content: space-between !important;
      border-top: none !important;
      background-color: #14161E;
      border-bottom-left-radius: 1.4rem;
      border-bottom-right-radius: 1.4rem;
    }
    
    .brandlist-div-content {
      overflow-y: auto;
      height: 100%;
      padding-bottom: 5em;
    }
    
    .brandlist-div-content::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #c2c2c2;
    }
    
    .brandlist-div-content::-webkit-scrollbar
    {
      width: 5px;
      border-radius: 10px;
      background-color: #F5F5F5;
    }
    
    .brandlist-div-content::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
    
    
    .brandlist-modal-body {
      // background-color: #14161E;
      padding: 0rem 2rem !important;
      height: auto;
      overflow-y: auto;
      border-bottom-left-radius: 1.4rem !important;
      border-bottom-right-radius: 1.4rem !important;
    
      &.--footer{
        padding: 0rem !important;
        border-radius: 0 !important;
        // border-top-left-radius: 1.4rem !important;
        // border-top-right-radius: 1.4rem !important;
        .two-column-grid{
          cursor: pointer;
          display: grid;
          height: 100%;
          grid-template-columns: 30% 70%;
          padding: 0em 1em 0.5em 0em;
        } 
    
        .filter-menu{
          border-right: 1px solid rgba(255, 212, 57, 0.2);
        }
    
        .filter-block{
            // background: rgb(255, 242, 235);
            display: flex;
            align-items: center;  
            padding: 0em 2em;
            height: 70px;
            margin: 0px;
            font-size: 12px;
    
            &.--active {
              backdrop-filter: blur(4px);
              background: #DC6969 ;
              color: white ;
              border-left: 3px solid #AC3434;;
            }
        }
    
        .filter-content{
          padding: 0em 1em;
        }
      }
    
      .year-picker-container {
        margin: 3em;
        display: flex;
        justify-content: center;
        .react-datepicker {
          width: 20em;
          border: none;
          font-size: 16px;
          border-radius: 1.5rem !important;
          background: #14161E;
          color: #d1d5db;
    
          @media(max-width: 400px) {
            width: 16em;
          }
          .react-datepicker__navigation {
            top: 9px;
          }
          .react-datepicker__year--container {
            border: none !important;
            font-size: 16px;
            .react-datepicker__header  {
              background: transparent;
              //#485063;
              /* height: 4em; */
              border-top-left-radius: 0.8em;
              border-top-right-radius: 0.8em;
              border-bottom: none;
              font-size: 16px;
              color: #D1D5DB;
              font-weight: unset;
              font-family: 'inter-regular';
            }
            .react-datepicker__year  {
              background: transparent;
              .react-datepicker__year-wrapper {
                justify-content: center;
                display: flex;
                width: auto;
                margin: 1.5em;
                max-width: 260px;
                .react-datepicker__year-text {
                  display: inline-block;
                  width: 3em;
                  margin: 1em;
                  font-weight: unset;
                  font-family: 'inter-regular';
                }
                .react-datepicker__year-text:hover {
                  background-color: rgb(51, 51, 51);
                }
                .react-datepicker__year-text--selected {
                  background-color: #dbdbdb;
                  color: #000;
                  width: 3em;
                }
              }
            }
          }
        }
      }
    
      h2{
        font-size: 14px;
      }
    
      @media (min-width : 520px ) {
        min-height: auto;
      }
    }
    
    .brandlist-modal-body::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #c2c2c2;
    }
    
    .brandlist-modal-body::-webkit-scrollbar
    {
      width: 5px;
      border-radius: 10px;
      background-color: #F5F5F5;
    }
    
    .brandlist-modal-body::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
    
    .brandlist-modal-footer {
      justify-content: space-between !important;
      border-top: none !important;
      // background-color: #14161E !important;
      border-bottom-left-radius: 1.4rem !important;
      border-bottom-right-radius: 1.4em !important;
    
      font-size: 12px;
      @media (max-width : 600px) {
        padding: 1.5em !important;
      }
    }
    
    .brandlist-header-closeBtn {
      cursor: pointer;
      //margin-left: auto;
      font-size: 1.8rem;
      padding: 0 1rem;
    
      &.--filter {
        padding: 0rem !important;
      }
    }
    
    .brandlist-listing-grid {
      display: grid;
      grid-template-columns: 20% 80%;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 1rem;
    }
    
    
    .no-available-listing{
      font-size: 18px;
      color: #7a7e86;
      font-style: italic;
      text-transform: none;
      text-align: center;
    
      @media (max-width : 991px ) {
        font-size: 14px;
      }
    
    }
    
    .brandlist-listing {
      display: flex;
      justify-content: start;
      align-items: center;
      position: relative;
      border-radius: 0rem;
    
        &.--grid{
          cursor: pointer;
          display: grid;
          height: fit-content;
          grid-template-columns: 20% 70% 10%;
          border-bottom: none;
          padding: 0em 1em 0.5em 1em;
    
          @media(max-width: 991px) {
            grid-template-columns: 20% 70% 10%;
            height: fit-content;
            padding: 0em 0.2em;
    
            p {
              font-size: 12px !important;
            }
          }
    
          @media(max-width: 600px) {
            grid-template-columns: 20% 70% 10%;
            height: 5em;
    
            p {
              font-size: 12px !important;
            }
          }
        }
    
        &.--brand-grid{
          cursor: pointer;
          display: grid;
          height: fit-content;
          grid-template-columns: 10% 90%;
          // border-bottom: 1px solid rgba(255, 212, 57, 0.2);
          border-bottom: 1px solid #F8F8F8;
          padding: 0.5em;
          
          @media(max-width: 991px) {
            grid-template-columns: 10% 90%;
            height: fit-content;
            padding: 0.5em;
            border-bottom: 1px solid rgba(255, 212, 57, 0.2);
    
            p {
              font-size: 12px !important;
            }
          }
          
          // @media(max-width: 600px) {
          //   grid-template-columns: 30% 70%;
          //   height: 5em;
      
          //   p {
          //     font-size: 12px !important;
          //   }
          // }
        }
    
        &.--sort-grid{
          display: grid;
          height: fit-content;
          grid-template-columns: 40% 60%;
          border-bottom: 1px solid rgba(255, 212, 57, 0.2);
          padding: 0.5em;
          
          @media(max-width: 991px) {
            grid-template-columns: 50% 50%;
            height: fit;
            padding: 0em 0.2em;
            font-size: 14px !important;
    
            p {
              font-size: 14px !important;
            }
          }
        }
    
        &.--sort-grid:last-child{
          border-bottom: none !important;
        }
        
        &.--choose-brand-grid{
          cursor: pointer;
          display: grid;
          grid-template-columns: 20% 80%;
          padding: 0em 1em;
        }
    
      .mobile-icon-div{
        width: auto;
      }
      // background-color: #577DE5;
    
      &.--search{
        border-radius: 0rem;
        height: 3em;
        width: 99%;
        border-bottom: 0.1em solid #B8BBC4;
    
        .icon-div{
          width: 3.5em;
          height: 3.5em;
          //background-color: #81818191;
          //border-radius: 100%;
          //padding-bottom: 1em; 
          margin-top: -0.3em;
          margin-left: -1em;
        }
    
        .icon-div img{
          width: 100%;
          height: 100%;
        }
      }
    }
    
    .brandlist-listing .brandlist-img-div{
      width: 100%;
      height: 100%;
      // background-color: white;
    }
    
    .brandlist-listing p {
      display: flex;
      align-items: center;
      padding: 0.5rem 2rem 0rem 2rem;
      height: 3em;
      width: 100%;
      font-family: "inter-regular";
      font-size: 1.6rem;
      //font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      text-overflow: ellipsis;
      margin-bottom: 0em !important;
    }
    .brandlist-listing img {
      padding: 0rem;
    }
    
    .brandlist-modal-body h2 {
      font-style: italic;
    }
    .brandlist-modal-body ul{
      padding: 0%;
    }
    
    .selectedIcon{
      position: absolute;
      top: 0;
      right: 0;
      font-size: 2rem;
      padding: 1.5rem 1rem;
      color: #d1d5db;
    
      @media(max-width: 991px) {
        padding: 1rem;
      }
    }
    .model-row { 
      display: grid;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      min-width: 300px;
      grid-template-columns: 1fr 1fr 1fr;
    
      @media(max-width: 991px) {
        padding: 1em 0em; 
        min-width: 320px;
      }
    
      @media(max-width: 500px) {
        padding: 1em 0em; 
        min-width: 0px;
        grid-template-columns: 1fr 1fr;
      }
    }
    .model-div {
      width: fit-content;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      backdrop-filter: blur(4px);
      background: #F8F8F8;
      color: #24252D;
      border-radius: 8px;
      font-size: 12px;
      padding: 1em;
      max-width: 7.5em;
      min-width: 7.5em;
      text-align: center;
      cursor: pointer;
    
      &.--active {
        font-family: "inter-medium";
        backdrop-filter: blur(4px);
        // background: hsl(46.97deg, 100%, 94.33%)!important;
        background: #dc6969;
        color: white;
      }
    
      @media(max-width: 991px) {
        max-width: 9.2em;
        min-width: 9.2em;
      }
    }
    .model-div:hover {
      // background: rgba(224, 224, 224, 0.3) ;
      @media(max-width: 991px) {
          // background:rgba(224, 224, 224, 0.1);
      }
      
    }
    .tag-row {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;
      min-width: 275px;
      height: 4em;
      align-items: center;
      padding: 0.98em 0.3em;
      height: fit-content !important;
    
      label {
        color: #9091a0;
        text-decoration: underline;
        font-size: 14px;
        font-family: "inter-medium";
      }
    }
    .tag-row-scroll {
      width: 87%;
      overflow-x: scroll;
    
      @media(max-width: 991px) {
        width: 96%;
      }
    }
    
    .tag-row-scroll::-webkit-scrollbar {
      display: none !important;
    }
    
    .tag-div {
      display: flex;
      align-items: center;
      width: fit-content;
      height: 38px;
      white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      backdrop-filter: blur(4px);
      background: #DC6969 !important;
      color: white; //#ffd439
      border-radius: 8px;
      font-size: 14px;
      padding: 0.5em 1em;
    
      @media (max-width: 991px ) {
        font-size: 12px;
      }
    }
  
    .range-slider-input{
      label {
        align-items: center;
        display: flex;
      }
      input {
        height: 3em;
        text-align: center;
        border: 0.5px solid #d1d5db;
        border-radius: 8px;
        // background-color: #14161E;
        // color: #ffffff;
        width: 8em;
        border-radius: 5px;
        font-size: 14px;
        line-height: 22px;
        
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      input:focus-visible, input:focus {
        border: none !important;
        outline: 0.15em solid transparent !important;
        box-shadow: 0 0 0.2em 0.05em #ffd439, 0 0 0.1em 0.05em #fbb24b;
      }
    }
    .mui-slider-div{
      padding: 0em 1em;
      .MuiSlider-root {
        .MuiSlider-rail {
          background-color: #ffd349;
          opacity: 0.38;
        }
        .MuiSlider-track {
          background-color: #ffd349;
          border: 1px solid #ffd349;
        }
        .MuiSlider-thumb {
          background-color: #ffffff !important;
          border: 3.5px solid #fbb24b;
          width: 18px !important;
          height: 18px !important;
        }
        .MuiSlider-thumb:hover {
          box-shadow: 0px 0px 0px 8px rgba(255, 212, 57, 0.16);
        }
        .MuiSlider-valueLabel {
          font-family: "inter-regular";
          background: #525359;
          height: 2.5em;
          width: fit-content;
          font-size: 12px;
          border: none !important;
        }
      }
    }
  
    .reset-btn{    
      cursor: pointer;
      width: auto;
      height: 38px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      background-color:#ffd439 !important;
      color: #000 !important;
      border-radius: 6px;
      text-transform: capitalize !important;
      font-size: 15px;
      border-radius: 6px;
      margin: 17px;
  
      svg{
        width: 20px;
        height: 20px;
        color: rgba(16, 20, 26, 1);
  
        @media (max-width : 991px) {
          width: 18px;
          height: 18px;
        }
      }
  
      @media (max-width : 991px) {
        font-size: 12px;
        margin: 9px 6px 6px 6px;
        // padding: 0em 0.5em !important;
      }
  
      @media (max-width : 600px) {
        width: 90% !important;
        font-size: 12px;
        margin: 9px 6px 6px 6px;
        // padding: 0em 0.5em !important;
      }
  
      &.--all{
        width: fit-content !important;
        height: 38px;
        justify-content: end;
        background-color: #ac3434 !important;
        margin: 0px;
        padding: 0em 1em !important;
        color: white !important;
  
        @media (max-width : 991px) {
          margin: 0.8em 2em;
          justify-content: center;
          width: 100% !important;
        }
      }
    }
    }



    .contact-agent-container-light{
      z-index: 9999 !important;
      position: relative;
      color: #24252D;
      height: 600px;
      [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;
      }
      [data-rsbs-header], [data-rsbs-content] {
        background-color: #FFFFFF;
      }
      [data-rsbs-overlay] {
        height: 600px;
      }
      .content-desktop{
        padding: 0em 3em 2em !important;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.5rem;
        background-color: transparent !important;
        @media (min-width: 991px) {
          width: 35em !important;
          margin-top: 1.5rem;
          background-color: #FFFFFF !important;
        }
      }
      .content-desktop::-webkit-scrollbar {
        display: none !important;
      }
      .modal-dialog {
        border-radius: 1em;
        height: auto;
        width: 100%;
        max-width: 100% !important;
        .modal-content{
          box-shadow: none !important;
          background-color: #fff !important;
          @media (min-width: 991px) {
            width: 35em !important;
            margin-left: auto;
            margin-right: auto;
            background-color: #fff !important;
          }
        }
      }
      .modal-dialog::-webkit-scrollbar {
        display: none !important;
      }
      .icon-close-btn{
        color: #707070;
        cursor: pointer;
        @media (min-width: 991px) {
          // position: absolute;
          left: 2em;
          top: 50%;
          transform: translateY(-10%);
        }
      }
      .icon-close-btn:hover{
        color: #FFD439;
        cursor: pointer;
      }
      .form-group{
        &._text{
          ._input{
            font-size: 1.8rem;
            margin-top: 0.3em;
            font-family: 'cabin-regular';
            background: #F8F8F8;
            border: 1px solid #646464;
            box-shadow: 0px 0px 4px rgba(189, 189, 189, 0.25);
            border-radius: 8px;
            padding: 0.8em;
            color: #616469;
            outline: none !important;
          }
          ._input::placeholder{
            color: #9091A0;
          }
        }
        &.--errors{
          ._input{
            margin-top: 1em;
            border: 0.1em solid rgba(236, 50, 55, 0.6);
            box-shadow: 0px 0px 8px #AC4343;
          }
          ._input::placeholder{
            color: #EC3237;
          }
        }
      }
      .form-input{
        position: relative;
        .error-message{
          position: absolute;
          bottom: -1.5em; 
          padding: 0;
          font-size: 1.2rem;
          color: #EC3237;
          letter-spacing: 0.01em;
        }
      }
      ._label{
        font-family: 'cabin-regular';
        padding: 0;
        &.--title{
          white-space: nowrap; 
          width: 13em;
          overflow: hidden;
          text-overflow: ellipsis; 
          font-size: 2.4rem;
          color: #FEFEFE;
          @media (min-width: 991px) {
            padding-top: 1.5em;
          }
        }
        &.--name{
          margin-left: 1em;
          font-size: 1.8rem;
          color: #D1D5DB;
        }
        &.--text-social{
          width: auto;
          font-size: 1.8rem;
          color: #9E9E9E;
          .pi {
            font-size: 1.6rem;
            margin-right: 0.5rem;
          }
        }
        &.--sub-title{
          font-size: 1.8rem;
          color: #24252D;
        }
        &.--details{
          margin-top: 2em;
          font-size: 1.3rem;
          line-height: 1.3em;
          span{
            color: #D6A055;
          }
        }
      }
      .button-group{
        &.--contact-agent{
          margin-top: 2em;
          padding: 0.65em 0em;
        }
        &.--send-code{
          padding: 0.65em 0em;
        }
      }
      .modal-footer,
      .modal-header{
        position: relative;
        border: unset;
        text-align: center;
        display: flex;
        justify-content: end;
        .button-group{
          width: 100%;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
     color: $color-gold !important;
     user-select: none;
    }
   
    .swiper-button-next:hover,
    .swiper-button-prev:hover {
      color: rgb(255, 145, 0) !important;
      font-weight: 600;
    }
   
   .swiper-button-disabled {
     color: #808080 !important;
     cursor: not-allowed; 
   }
  

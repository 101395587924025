@import "./font";

*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html{
    background-color: #14161E;
    font-size: 62.5%; // 1rem == 10px ( rem depend on root font size )
}

body{
	//background-color: #1E1F21 !important;
	//background-color: #000000 !important;
	// overflow: auto !important;
	overflow: auto;
	background-color: #14161E !important;
    font-family: "inter-regular", sans-serif !important;
}


body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #c2c2c2;
}

body::-webkit-scrollbar
{
	width: 5px;
  	border-radius: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
.toggleSwitchContainer{
    margin: 2rem;
}
.toggleSwitch{
  border-radius: 25px;
  background-color: #28303c;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.50) inset;
  padding: 1px 0px;
  max-width: 30em;
  margin-left: auto;
  margin-right: auto;
 
}
@media (min-width : 991px) {
  .toggleSwitch{
    min-width: 705px;

  }
}
.toggle {
  margin: 0.5em;
  position: relative;
  width: auto;
  display: flex;
  /* background-color: #10141A
  ; */
  border-radius: 18px;
  /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25)); */
  text-align: center;
  }
  .toggle-optionTwo {
    margin: 0.5em;
    /* margin-bottom: 2.2em; */
    position: relative;
    width: auto;
    display: flex;
    /* background-color: #10141A
    ; */
    border-radius: 18px;
    /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25)); */
    text-align: center;
  }
  
  .optionOne-white {
    color: #D1D5DB;
    width: 50%;
    margin: 0;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 18px;
    cursor: pointer;
  }
  .optionTwo-white {
    width: 50%;
    margin: 0;
    font-weight: 500;
    color: #D1D5DB;
    padding: 8px 12px;
    border-radius: 18px;
    cursor: pointer;
  }
  .switch {
    width: 50%;
    margin: 0;
    font-weight: 600;
    color: #6261be;
    padding: 8px 12px;
    border-radius: 18px;
    cursor: pointer;
  }
  .switch {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: #24262D
    ;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    z-index: -1;
    transition: all 0.4s ease-in-out;
    transform: translate(0, 0);
  }


/* Add this in your CSS file */
@keyframes slideBackgroundIn {
  from {
    background-color: unset !important;
    box-shadow: unset !important;
    opacity: 0.5;
  }
  to {
    background-color: #0B0D13;
    box-shadow: 0px 2px 4px #000;
    opacity: 1;
  }
}

/*
@keyframes slideBackgroundOut {
  from {
    background-color: #10141A;
    opacity: 0.5;
  }
  to {
    background-color: #0B0D13;
    opacity: 1;
  }
} */

/* Add this in your CSS file */
@keyframes slideIn {
  from {
    background-color: #0B0D13;
    transform: translateX(100%);
    opacity: 1;
  }
  to {
    background-color: #0B0D13;
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    background-color: #0B0D13;
    transform: translateX(0);
    opacity: 1;
  }
  to {
    background-color: #0B0D13;
    transform: translateX(100%);
    opacity: 1;
  }
}

/* Then update the existing styles */
.optionOne,
.optionTwo,
.switch {
  width: 50%;
  margin: 0;
  color: #FFD439;
  padding: 8px 12px;
  cursor: pointer;
  /* background-color: #0B0D13;
  box-shadow: 0px 2px 4px #000; */
  border-radius: 18px;
  animation: slideBackgroundIn 1s ease-in-out forwards;
  transition: none !important;
}

.optionOne-white {
  color: #D1D5DB;
}

.optionTwo-white {
  color: #D1D5DB;
}

.switch {
  font-weight: 600;
  color: #6261be;
}

/* #active-toggled,
#active-left-toggled {
  opacity: 0.2;
  transition: all 0.5s ease-in-out !important;
} */

#active-toggled {
  animation: slideOut 0.5s ease-in-out forwards; 
  /*, slideBackgroundIn 0.5s ease-in-out forwards;*/
}

#active-left-toggled {
  animation: slideIn 0.5s ease-in-out forwards;
  /* slideBackgroundIn 0.5s ease-in-out forwards;*/
}

.optionTwo {
  color: #FFD439;
}
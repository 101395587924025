@font-face {
    font-family: "password";
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/password.ttf);
}

@font-face {
    font-family: "cabin-regular";
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/Cabin-Regular.woff);
}

@font-face {
    font-family: "inter-regular";
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/Inter-Regular.woff);
    font-display: swap;
}

@font-face {
    font-family: "inter-medium";
    font-style: normal;
    font-weight: 500;
    src: url(../../assets/fonts/Inter-Medium.ttf);
    font-display: swap;
}

@font-face {
    font-family: "inter-semi-bold";
    font-style: normal;
    font-weight: 600;
    src: url(../../assets/fonts/Inter-Semi-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "inter-bold";
    font-style: normal;
    font-weight: 700;
    src: url(../../assets/fonts/Inter-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: 'homoarakhn';
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/Homoarakhn-Regular.woff);
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: 'montserrat-medium';
    font-style: normal;
    font-weight: 500;
    src: url(../../assets/fonts/Montserrat-Medium.woff);
}

@font-face {
    font-family: 'montserrat-semi-bold';
    font-style: normal;
    font-weight: 600;
    src: url(../../assets/fonts/Montserrat-Semi-Bold.ttf);
}
@font-face {
    font-family: 'geom_graphic';
    font-style: normal;
    font-weight: 500;
    src: url(../../assets/fonts/Geom-Graphic-Bold.ttf);
}